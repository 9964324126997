import { Form, Modal } from 'antd'
import { useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'

const STATUS_SUCCESS = 200

export function ModalCreateCoverageScale({ isOpen, onClose, reloadCoverageScales }) {
    const formRef = useRef()

    const [loading, setLoading] = useState(false)

    const onSubmit = async coverage_scale => {
        setLoading(true)
        try {
            const response = await api.coverageScales.create(coverage_scale)

            if (response.status === STATUS_SUCCESS) {
                onClose(false)
                await reloadCoverageScales()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            title={displayMessage('COVERAGE_SCALE_CREATION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            okText={displayMessage('FINISH')}
            maskClosable={false}
        >
            <Form onFinish={onSubmit} className="max-w-[700px] w-full" layout="vertical" requiredMark={false} ref={formRef}>
                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('MODULE_DESCRIPTION')}</div>

                    <Form.Item name="description" rules={[{ required: true, message: displayMessage('DISPLAY_DESCRIPTION') }]} className="mb-4">
                        <SL_Input placeholder={displayMessage('MODULE_DESCRIPTION')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('GRADUATION')}</div>

                    <Form.Item name="graduation" rules={[{ required: true, message: displayMessage('DISPLAY_GRADUATION') }]} className="mb-4">
                        <SL_Input placeholder={displayMessage('GRADUATION')} className={'w-full'} />
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    )
}
