import { useContext, useEffect, useState } from 'react'
import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import { DrawerMenu } from '../menus/DrawerMenu'
import { SideMenu } from '../menus/SideMenu'
import { Loader } from '../Loader'
import { TopMenu } from '../menus/TopMenu'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import api from '../../../services/api'
import NotificationDrawer from '../notifications/NotificationDrawer'
import { AuthContext } from '../../../context/AuthContext/AuthContext'

const { Header, Content } = Layout

export const InternalLayout = () => {
    const { mobile_mode } = useContext(GeneralContext)
    const { loggedUser } = useContext(AuthContext)

    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
    const [notificationsCount, setNotificationsCount] = useState(0)

    useEffect(() => {
        loadNotifications()
        const interval = setInterval(loadNotifications, 3600000)
        return () => clearInterval(interval)
    }, [])

    const loadNotifications = async () => {
        try {
            const { data } = await api.notifications.listAll({ user_id: loggedUser?.id, not_readed: true })
            setNotificationsCount(data.rows.length)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Layout className="bg-white flex flex-row w-full">
            <Loader />

            {!mobile_mode && <SideMenu />}

            <Layout className="site-layout bg-white flex flex-col h-screen w-10/12">
                <Header className="bg-white p-0 shadow-lg shadow-sl-gray-300 flex justify-end items-center px-4">
                    {mobile_mode && <DrawerMenu />}
                    <TopMenu countNotifications={notificationsCount} onNotificationButtonClick={() => setIsNotificationsOpen(true)} />
                </Header>

                <div className={`flex-1 overflow-scroll w-screen h-screen my-4 mx-0 smb:w-unset smb:h-unset smb:p-0 smb:m-0`}>
                    <Content className={`site-layout-background flex flex-col flex-1 h-full p-2 w-full smb:p-4 smb:w-unset`}>
                        <Outlet />
                    </Content>
                </div>
                {isNotificationsOpen && (
                    <NotificationDrawer onClose={() => setIsNotificationsOpen(false)} handleWithChanges={() => loadNotifications()} />
                )}
            </Layout>
        </Layout>
    )
}
