import { Button, Modal } from 'antd'
import { useRef, useState } from 'react'
import api from '../../../../../services/api'
import { displayMessage } from '../../../../../helpers/messages'
import { useNavigate, useParams } from 'react-router-dom'

export function ModalConfirmationFinalizateFollowup({ isOpen, onClose, followUpId }) {
    const { reportId } = useParams()
    const navigate = useNavigate()

    const formRef = useRef()

    const [loading, setLoading] = useState(false)

    const handleOnFinalizateFollowup = async () => {
        setLoading(true)
        try {
            await api.followUps.finalizate({ followUpId })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            onClose(false)
            navigate(`/reports/${reportId}`)
        }
    }

    const handleOnFinalizateOldCreateNewFollowup = async () => {
        setLoading(true)
        try {
            await api.followUps.finalizateOldCreateNew({ reportId })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            onClose(false)
            navigate(`/reports/${reportId}`)
        }
    }

    return (
        <Modal
            title={displayMessage('ATENTION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            footer={
                <div className="flex w-full pt-5">
                    <div className="flex justify-start w-full">
                        <Button danger type="primary" onClick={() => onClose(false)}>
                            {displayMessage('CANCEL')}
                        </Button>
                    </div>
                    <div className="flex justify-end w-full">
                        <Button type="default" onClick={handleOnFinalizateFollowup}>
                            {displayMessage('NO')}
                        </Button>
                        <Button type="primary" onClick={handleOnFinalizateOldCreateNewFollowup}>
                            {displayMessage('YES')}
                        </Button>
                    </div>
                </div>
            }
            maskClosable={false}
        >
            <div className="w-full text-center">{displayMessage('ON_FINALIZATE_FOLLOWUP_MESSAGE')}</div>
        </Modal>
    )
}
