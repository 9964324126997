import { Tabs } from 'antd'
import { useContext, useEffect, useState } from 'react'
import api from '../../services/api'
import { displayMessage } from '../../helpers/messages'
import { SL_Grid } from '../../components/grid/SL_Grid'
import CustomToolbar from '../../components/grid/CustomToolbar'
import { SL_Button } from '../../components/buttons/Button'
import { useNavigate } from 'react-router-dom'
import { report_config_grid_columns } from './grid_columns/report_config_grid_columns'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'

const REPORT_CONFIG_KEY = 1

export function Settings({}) {
    const { mobile_mode } = useContext(GeneralContext)

    const navigate = useNavigate()

    const [reportConfigs, setReportConfigs] = useState([])

    const [rowOnFocus, setRowOnFocus] = useState(null)

    const [gridLoading, setGridLoading] = useState(false)

    useEffect(() => {
        loadReportConfigs()
    }, [])

    const loadReportConfigs = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.reportConfigs.list(params)

            console.log(data)

            setReportConfigs(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const handleOnTabChange = async key => {}

    return (
        <>
            <Tabs
                defaultActiveKey={REPORT_CONFIG_KEY}
                onChange={handleOnTabChange}
                items={[
                    {
                        key: REPORT_CONFIG_KEY,
                        label: displayMessage('REPORT_CONFIGS'),
                        children: (
                            <>
                                <div>
                                    <SL_Grid
                                        rows={reportConfigs ?? []}
                                        sortingMode={'server'}
                                        components={{
                                            Toolbar: () => (
                                                <>
                                                    <div className="flex justify-between flex-col ss:flex-row">
                                                        <CustomToolbar />
                                                        <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                            <SL_Button
                                                                className="my-1 mr-1 ml-1"
                                                                disabled={!rowOnFocus}
                                                                onClick={() => navigate(`report-config/${rowOnFocus.id}`)}
                                                            >
                                                                {displayMessage('REPORT_CONFIG_DETAILS')}
                                                            </SL_Button>
                                                            <SL_Button
                                                                className="my-1 mr-1 ml-1"
                                                                type="primary"
                                                                onClick={() => navigate('report-config/create')}
                                                            >
                                                                {displayMessage('CREATE_REPORT_CONFIG')}
                                                            </SL_Button>
                                                        </div>
                                                    </div>
                                                </>
                                            ),
                                        }}
                                        disableFilter={false}
                                        onRowClick={params => {
                                            setRowOnFocus(params.row)
                                        }}
                                        columns={report_config_grid_columns}
                                        className="flex-1 min-h-[61vh] max-h-[100vh]"
                                        loading={gridLoading}
                                    />
                                </div>
                            </>
                        ),
                    },
                ]}
            />
        </>
    )
}
