import { useContext, useEffect, useRef, useState } from 'react'
import api from '../../services/api'
import { SL_Grid } from '../../components/grid/SL_Grid'
import CustomToolbar from '../../components/grid/CustomToolbar'
import { SL_Button } from '../../components/buttons/Button'
import { displayMessage } from '../../helpers/messages'
import { useNavigate } from 'react-router-dom'
// import ClientFilters from './components/ClientFilters'
// import { ModalCreateClient } from './components/ModalCreateClient'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import exportToExcel from '../../services/export'
import { DateTime } from 'luxon'
import { findUserTypeByCode, formatCpf } from '../../helpers/helper'
import { client_users_grid_columns } from './grid_columns/client_users_grid_columns'
import { ModalCreateClientUser } from './components/ModalCreateClientUser'
import ClientUserFilters from './components/ClientUserFilters'

export default function ListClientUsers({}) {
    const formRef = useRef()
    const { mobile_mode } = useContext(GeneralContext)

    const [clientUsers, setClientUsers] = useState(null)

    const [gridLoading, setGridLoading] = useState(false)

    const [rowOnFocus, setRowOnFocus] = useState(false)

    const [modalCreateClientIsOpen, setModalCreateClientIsOpen] = useState(false)

    const [modalCreateClientUserIsOpen, setModalCreateClientUserIsOpen] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        loadClientUsers()
    }, [])

    const loadClientUsers = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.clientUsers.list(params)

            setClientUsers(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    return (
        <>
            <ClientUserFilters formRef={formRef} loadClientsUsers={loadClientUsers} setGridLoading={setGridLoading} setClientUsers={setClientUsers} />

            {modalCreateClientUserIsOpen && (
                <ModalCreateClientUser
                    isOpen={modalCreateClientUserIsOpen}
                    onClose={setModalCreateClientUserIsOpen}
                    reloadClientUsers={loadClientUsers}
                />
            )}

            <SL_Grid
                rows={clientUsers ?? []}
                sortingMode={'server'}
                components={{
                    Toolbar: () => (
                        <>
                            <div className="flex justify-between flex-row ss:flex-row">
                                <CustomToolbar />
                                <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                    <SL_Button
                                        className="my-1 mr-1"
                                        disabled={!rowOnFocus}
                                        onClick={() => navigate(`/client-users/${rowOnFocus.id}`)}
                                    >
                                        {displayMessage('USER_DETAILS')}
                                    </SL_Button>
                                    <SL_Button className="my-1 mr-1" onClick={() => setModalCreateClientUserIsOpen(true)}>
                                        {displayMessage('USER_CREATION')}
                                    </SL_Button>
                                </div>
                            </div>
                        </>
                    ),
                }}
                disableFilter={false}
                onRowClick={params => {
                    setRowOnFocus(params.row)
                }}
                columns={client_users_grid_columns}
                className="flex-1 min-h-[61vh] max-h-[100vh]"
                loading={gridLoading}
            />
        </>
    )
}
