import { HomeOutlined, TeamOutlined } from '@ant-design/icons'
import { displayMessage } from '../../../../helpers/messages'

export const sideMenuItensIconsStyle = {
    fontSize: 20,
}

export const topMenuItensIconsStyle = {
    fontSize: 20,
}

export default {
    //Somente para visualização do admin do sistema - Menu Lateral
    adminSideMenuItens: [
        {
            key: '/users',
            icon: <TeamOutlined style={sideMenuItensIconsStyle} />,
            title: displayMessage('USER_LIST'),
        },
    ],

    //Visualização para todos os usuários - Menu Lateral
    userSideMenuItens: [],

    //Visualização para todos os usuários - Menu Superior
    topMenuItens: [
        // {
        //   key: "/profile",
        //   icon: <UserOutlined style={sideMenuItensIconsStyle} />,
        //   title: displayMessage('MY_PROFILE'),
        // },
    ],
}
