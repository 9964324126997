import { DateTime } from 'luxon'
import { displayMessage } from '../../../helpers/messages'

export const strengths_grid_columns = [
    {
        field: 'title',
        headerName: displayMessage('TITLE'),
        type: 'string',
        width: 500,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.title) return row.title
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'is_active',
        headerName: displayMessage('ACTIVATION_STATUS'),
        type: 'string',
        width: 150,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row) return row.is_active ? displayMessage('ACTIVE') : displayMessage('INACTIVE')
        },
        type: 'singleSelect',
        valueOptions: [
            { value: true, label: 'Ativo' },
            { value: false, label: 'Inativo' },
        ],
    },
    {
        field: 'inactivation_date',
        headerName: displayMessage('INACTIVATION_DATE'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row && row.inactivation_date) return DateTime.fromISO(row.inactivation_date).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'createdAt',
        headerName: displayMessage('CREATED_AT'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.createdAt) return DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
]
