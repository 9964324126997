import React, { useContext, useState } from 'react'
import { Form, Tooltip } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { SL_Button } from '../../../components/buttons/Button'
import { SL_Input } from '../../../components/input/Input'
import { Eraser } from '@phosphor-icons/react'
import { SL_Date } from '../../../components/date/Date'
import { CepMaskedInput } from '../../../helpers/iMask'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'

export default function ClientAddressFilters(props) {
    const { loadClientAddress, setGridLoading, setClientAddresses } = props

    const { mobile_mode } = useContext(GeneralContext)

    const [cep, setCep] = useState(null)
    const [addressState, setAddressState] = useState(null)
    const [city, setCity] = useState(null)
    const [neighbourhood, setNeighbourhood] = useState(null)
    const [street, setStreet] = useState(null)
    const [number, setNumber] = useState(null)
    const [complement, setComplement] = useState(null)
    // const [createdAt, setCreatedAt] = useState(null)
    const [form] = Form.useForm()

    const clearFilters = async () => {
        setCep(null)
        setAddressState(null)
        setCity(null)
        setNeighbourhood(null)
        setStreet(null)
        setNumber(null)
        setComplement(null)
        // setCreatedAt(null)
        form.resetFields()
        await loadClientAddress()
    }

    // const handleCreatedAtChange = value => {
    //     setCreatedAt(value)
    // }

    const handleOnFilter = async () => {
        setGridLoading(true)
        try {
            const params = {
                cep,
                state: addressState,
                city,
                neighbourhood,
                street,
                number,
                complement,
                // created_at: createdAt,
            }

            const { data } = await loadClientAddress(params)

            setClientAddresses(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    return (
        <>
            <div className="flex flex-col rounded-md p-2 border border-solid border-[#d9d9d9]">
                <span className="self-center font-bold text-lg flex-1 text-center">{displayMessage('SEARCH_FILTERS')}</span>
                <div className="flex flex-col ss:flex-row gap-y-2 gap-x-2 justify-between ">
                    <div>
                        <Form
                            onFinish={props.onFinish}
                            layout="vertical"
                            form={form}
                            className="flex flex-col lg:flex-row gap-x-2 gap-y-2 md:flex-wrap"
                            ref={props.formRef}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleOnFilter()
                                }
                            }}
                        >
                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('POSTAL_CODE')}
                                    requiredMark=""
                                    name="cep"
                                    className={`m-0 ${mobile_mode && 'w-full'}`}
                                >
                                    {CepMaskedInput({
                                        placeholder: displayMessage('POSTAL_CODE'),
                                        onChange: e => {
                                            setCep(e.unmaskedValue)
                                        },
                                    })}
                                </Form.Item>
                            </div>

                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item label={displayMessage('STATE')} requiredMark="" name="state" className={`m-0 ${mobile_mode && 'w-full'}`}>
                                    <SL_Input placeholder={displayMessage('STATE')} onChange={e => setAddressState(e.target.value)} />
                                </Form.Item>
                            </div>

                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item label={displayMessage('CITY')} requiredMark="" name="city" className={`m-0 ${mobile_mode && 'w-full'}`}>
                                    <SL_Input placeholder={displayMessage('CITY')} onChange={e => setCity(e.target.value)} />
                                </Form.Item>
                            </div>

                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('NEIGHBOURHOOD')}
                                    requiredMark=""
                                    name="neighbourhood"
                                    className={`m-0 ${mobile_mode && 'w-full'}`}
                                >
                                    <SL_Input placeholder={displayMessage('NEIGHBOURHOOD')} onChange={e => setNeighbourhood(e.target.value)} />
                                </Form.Item>
                            </div>

                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('STREET')}
                                    requiredMark=""
                                    name="street"
                                    className={`m-0 ${mobile_mode && 'w-full'}`}
                                >
                                    <SL_Input placeholder={displayMessage('STREET')} onChange={e => setStreet(e.target.value)} />
                                </Form.Item>
                            </div>

                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('NUMBER')}
                                    requiredMark=""
                                    name="number"
                                    className={`m-0 ${mobile_mode && 'w-full'}`}
                                >
                                    <SL_Input placeholder={displayMessage('NUMBER')} onChange={e => setNumber(e.target.value)} />
                                </Form.Item>
                            </div>

                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('COMPLEMENT')}
                                    requiredMark=""
                                    name="complement"
                                    className={`m-0 ${mobile_mode && 'w-full'}`}
                                >
                                    <SL_Input placeholder={displayMessage('COMPLEMENT')} onChange={e => setComplement(e.target.value)} />
                                </Form.Item>
                            </div>

                            {/* <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('CREATED_AT')}
                                    requiredMark=""
                                    name="created_at"
                                    className={`m-0 ${mobile_mode && 'w-full'}`}
                                >
                                    <SL_Date style={{ width: mobile_mode ? '100%' : 'auto' }} value={createdAt} format={'DD/MM/YYYY'} onChange={handleCreatedAtChange} />
                                </Form.Item>
                            </div> */}
                        </Form>
                    </div>
                    <div className="flex justify-self-end">
                        <div className={`flex ${mobile_mode ? 'w-full justify-between' : ''} items-end pb-1`}>
                            <Tooltip placement="topLeft" title={displayMessage('CLEAN_FILTERS')}>
                                <SL_Button
                                    className="md-mx-0 rounded-r-none bg-white border-solid border-1 border-primary-600 text-primary-600"
                                    onClick={() => clearFilters()}
                                >
                                    <Eraser size={20} color="black" />
                                </SL_Button>
                            </Tooltip>

                            <SL_Button className={`md-mx-0 rounded ${mobile_mode ? 'w-full ml-2' : ''}`} onClick={handleOnFilter}>
                                {displayMessage('TO_FILTER')}
                            </SL_Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
