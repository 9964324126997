import { Form, Switch, Tabs } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { useEffect, useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import api from '../../../services/api'
import { useParams, useNavigate } from 'react-router-dom'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import { SL_Button } from '../../../components/buttons/Button'
import EActivationStatus from '../../../enums/EActivationStatus'
import styles from '../../../services/styles'

export function WorkSectorDetails({}) {
    const { workSectorId, clientId } = useParams()
    const navigate = useNavigate()

    const formRef = useRef()
    const [workSector, setWorkSector] = useState(null)
    const [isActive, setIsActive] = useState(null)

    const [loading, setLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        loadWorkSector()
    }, [])

    const loadWorkSector = async () => {
        setLoading(true)
        try {
            const { data } = await api.workSectors.find(workSectorId)

            setWorkSector(data)
            setIsActive(data.is_active)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const onSubmit = async workSector => {
        setLoading(true)
        try {
            const auxWorkSector = { ...workSector, is_active: isActive }

            const workSectorUpated = await api.workSectors.update(workSectorId, auxWorkSector)
            setWorkSector(workSectorUpated)
            const WORK_SECTORS_KEY = 2
            navigate(`/clients/${clientId}?tab=${WORK_SECTORS_KEY}`)
        } catch (error) {
            console.log(error)
        } finally {
            setIsEditing(false)
            setLoading(false)
        }
    }

    return (
        <>
            <div>
                {loading ? (
                    <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                        <SL_ClipLoader loading={true} />
                    </div>
                ) : (
                    <>
                        <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    key: 1,
                                    label: displayMessage('WORK_SECTOR_DETAILS'),
                                    children: (
                                        <div>
                                            <div className="w-full flex flex-row justify-end">
                                                <div className="mr-2">{displayMessage('ENABLE_EDIT')}</div>
                                                <Switch onChange={e => setIsEditing(e)} />
                                            </div>
                                            <div className="w-full flex justify-center">
                                                <Form
                                                    onFinish={onSubmit}
                                                    className="max-w-[500px] w-full"
                                                    layout="vertical"
                                                    requiredMark={false}
                                                    ref={formRef}
                                                >
                                                    <div className="flex flex-col gap-1">
                                                        <div className="">{displayMessage('FIRST_NAME')}</div>

                                                        <Form.Item
                                                            name="name"
                                                            initialValue={workSector?.name}
                                                            rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]}
                                                            className="mb-4"
                                                        >
                                                            <SL_Input
                                                                placeholder={displayMessage('FIRST_NAME')}
                                                                className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                disabled={!isEditing}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="flex flex-col gap-1 mt-1">
                                                        <div className="">{displayMessage('ACTIVATION_STATUS')}</div>
                                                        <SL_Input
                                                            placeholder={
                                                                isActive ? EActivationStatus.ATIVO.description : EActivationStatus.INATIVO.description
                                                            }
                                                            className={'w-full'}
                                                            disabled={true}
                                                        />
                                                    </div>

                                                    <div className="flex flex-col gap-1 mt-5 mb-5">
                                                        <SL_Button
                                                            className="md-mx-0 rounder-l-none"
                                                            style={{
                                                                backgroundColor: !isEditing
                                                                    ? 'gray'
                                                                    : isActive
                                                                    ? styles.colors['sl-red']['400']
                                                                    : '#0e730d',
                                                                color: 'white',
                                                            }}
                                                            disabled={!isEditing}
                                                            onClick={() => setIsActive(prev => !prev)}
                                                        >
                                                            {isActive ? 'Desativar' : 'Ativar'}
                                                        </SL_Button>
                                                    </div>
                                                    <Form.Item hasFeedback className="mb-3 mt-2">
                                                        <SL_Button
                                                            disabled={!isEditing}
                                                            type="primary"
                                                            className={'w-full font-bold shadow-md'}
                                                            htmlType="submit"
                                                        >
                                                            {displayMessage('EDIT')}
                                                        </SL_Button>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </>
                )}
            </div>
        </>
    )
}
