import { useContext, useEffect, useRef, useState } from 'react'
import api from '../../services/api'
import { SL_Grid } from '../../components/grid/SL_Grid'
import { users_grid_columns } from './grid_columns/users_grid_columns'
import Filters from './components/Filters'
import CustomToolbar from '../../components/grid/CustomToolbar'
import { SL_Button } from '../../components/buttons/Button'
import { displayMessage } from '../../helpers/messages'
import { useNavigate } from 'react-router-dom'
import { ModalCreateUser } from './components/ModalCreateUser'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import exportToExcel from '../../services/export'
import { DateTime } from 'luxon'
import { findUserTypeByCode, formatCpf } from '../../helpers/helper'

export default function ListUsers(props) {
    const { mobile_mode } = useContext(GeneralContext)

    const [users, setUsers] = useState([])
    const [gridLoading, setGridLoading] = useState(false)

    const [rowOnFocus, setRowOnFocus] = useState(false)

    const [modalCreateUserIsOpen, setModalCreateUserIsOpen] = useState(false)
    const formRef = useRef()

    const navigate = useNavigate()

    useEffect(() => {
        loadAllUsers()
    }, [])

    const loadAllUsers = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.user.list(params ?? {})
            setUsers(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    return (
        <>
            <Filters formRef={formRef} loadUsers={loadAllUsers} setGridLoading={setGridLoading} setUsers={setUsers} />

            {modalCreateUserIsOpen && (
                <ModalCreateUser isOpen={modalCreateUserIsOpen} onClose={setModalCreateUserIsOpen} reloadUsers={loadAllUsers} />
            )}

            <SL_Grid
                rows={users ?? []}
                sortingMode={'server'}
                components={{
                    Toolbar: () => (
                        <>
                            <div className="flex justify-between flex-row ss:flex-row">
                                <CustomToolbar />
                                <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                    <SL_Button className="my-1 mr-1" disabled={!rowOnFocus} onClick={() => navigate(`/users/${rowOnFocus.id}`)}>
                                        {displayMessage('USER_DETAILS')}
                                    </SL_Button>
                                    <SL_Button className="my-1 mr-1" onClick={() => setModalCreateUserIsOpen(true)}>
                                        {displayMessage('CREATE_USER')}
                                    </SL_Button>
                                </div>
                            </div>
                        </>
                    ),
                }}
                disableFilter={false}
                onRowClick={params => {
                    setRowOnFocus(params.row)
                }}
                columns={users_grid_columns}
                className="flex-1 min-h-[61vh] max-h-[100vh]"
                loading={gridLoading}
            />
        </>
    )
}
