import { Form, Modal } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import { SL_Select } from '../../../components/selects/Select'
import { useNavigate, useParams } from 'react-router-dom'

const STATUS_SUCCESS = 200

export function ModalVinculeNormative({ isOpen, onClose, reloadNormatives }) {
    const { riskId } = useParams()

    const formRef = useRef()

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [normatives, setNormatives] = useState([])
    const [selectedNormatives, setSelectedNormatives] = useState([])

    useEffect(() => {
        loadNormatives()
    }, [])

    const loadNormatives = async () => {
        setLoading(true)
        try {
            const { data } = await api.normatives.list()

            setNormatives(data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const onSubmit = async () => {
        setLoading(true)
        try {
            const response = await api.risks.vinculeNormatives(riskId, selectedNormatives)

            if (response.status === STATUS_SUCCESS) {
                onClose(false)
                await reloadNormatives()
            }
            navigate(-1)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleNormativeChange = value => {
        setSelectedNormatives(value)
    }

    return (
        <Modal
            title={displayMessage('NORMATIVE_VINCULATION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            maskClosable={false}
        >
            {loading ? (
                <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                    <SL_ClipLoader loading={true} />
                </div>
            ) : (
                <Form onFinish={onSubmit} className="max-w-[700px] w-full" layout="vertical" requiredMark={false} ref={formRef}>
                    <div className="flex flex-col gap-1">
                        <div className="">{displayMessage('MODULE_DESCRIPTION')}</div>

                        <SL_Select
                            maxTagCount="responsive"
                            className="mb-1 h-[28px] rounded-sm"
                            mode="multiple"
                            listItemHeight={100}
                            title={displayMessage('NORMATIVES')}
                            placeholder={displayMessage('NORMATIVES')}
                            onChange={handleNormativeChange}
                            value={selectedNormatives ?? []}
                            options={normatives.map(e => ({ label: e.title, value: e.id }))}
                            allowClear
                        />
                    </div>
                </Form>
            )}
        </Modal>
    )
}
