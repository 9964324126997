import IMask from 'imask'
import { MaskedInput } from 'antd-mask-input'

//MaskInput functions ...
export function CpfMaskedInput(props) {
    return <MaskedInput {...props} mask={'000.000.000-00'} />
}

export function PhoneMaskedInput(props) {
    return <MaskedInput {...props} mask={'(00) 00000-0000'} />
}

export function CnpjMaskedInput(props) {
    return <MaskedInput {...props} mask={'00.000.000/0000-00'} />
}

export function CepMaskedInput(props) {
    return <MaskedInput {...props} mask={'00000-000'} />
}

export function CnaeMaskedInput(props) {
    return <MaskedInput {...props} mask={'00.00-0-00'} />
}

//Imask Functions ...
const maskOptions = {
    phone: {
        mask: '+00 (00) 00000-0000',
    },
    cpf: {
        mask: '000.000.000-00',
    },
}
export function phoneMask(elementId) {
    var element = document.getElementById(elementId)
    var mask = IMask(element, maskOptions.phone)
    // var mask = IMask(element, '+00 (00) 00000-0000')
    return mask
}
export function cpfMask(elementId) {
    var element = document.getElementById(elementId)
    var mask = IMask(element, maskOptions.cpf)
    return mask
}
export function phoneUnmask(phoneMaskedValue) {
    try {
        if (phoneMaskedValue != null && phoneMaskedValue.length > 0) {
            let phonePunctuation = ['+', '(', ')', '-', ' ']
            for (let i = 0; i < phonePunctuation.length; i++) {
                const punctuation = phonePunctuation[i]
                phoneMaskedValue = phoneMaskedValue.split(punctuation).join('')
            }
        }
        return phoneMaskedValue
    } catch (error) {
        console.log(error)
        return '-'
    }
}
export function cpfUnmask(cpfMaskedValue) {
    try {
        if (cpfMaskedValue != null && cpfMaskedValue.length > 0) {
            let phonePunctuation = ['.', '-', ' ']
            for (let i = 0; i < phonePunctuation.length; i++) {
                const punctuation = phonePunctuation[i]
                cpfMaskedValue = cpfMaskedValue.split(punctuation).join('')
            }
        }
        return cpfMaskedValue
    } catch (error) {
        console.log(error)
        return '-'
    }
}
