import { FileExcelOutlined } from '@ant-design/icons'
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import { Tooltip } from 'antd'
import { SL_Button } from '../buttons/Button'
import { displayMessage } from '../../helpers/messages'

export default function CustomToolbar(props) {
    const isDataValid = props?.data && props?.data.length > 0
    const canExportExcel = isDataValid && props?.gridColumns && props?.data && props?.fileName

    return (
        <GridToolbarContainer>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ delimiter: ';' }} />

                <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>{props.children}</div>
                {canExportExcel && (
                    <SL_Button
                        // style={{
                        //     display: 'flex',
                        //     alignItems: 'center',
                        //     justifyContent: 'center',
                        // }}
                        type="default"
                        // shape="circle"
                        onClick={() => props?.handleOnExportExcel && props?.handleOnExportExcel(props.gridColumns, props.data, props.fileName)}
                        icon={<FileExcelOutlined />}
                    >
                        {displayMessage('EXPORT_EXCEL')}
                    </SL_Button>
                )}
            </div>
        </GridToolbarContainer>
    )
}
