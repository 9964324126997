import { useEffect, useState } from 'react'
import { axiosInstance } from '../../services/axiosInstances'
import { AuthContext } from './AuthContext'
import EUserTypes from '../../enums/EUserTypes'

export function AuthContextProvider({ children }) {
    const [loggedUser, setLoggedUser] = useState(null)
    const [userPermissions, setUserPermissions] = useState([])
    const [isAdmin, setIsAdmin] = useState(true)

    useEffect(() => {
        if (loggedUser?.type == EUserTypes.ADMIN.code) {
            setIsAdmin(true)
            return
        }

        setIsAdmin(false)
    }, [loggedUser])

    function onChangeLoggedUser(user) {
        if (!user) {
            removeAuthorizationToken()

            // Tratativa de redirect feita dentro do GeneralRoute
            // const refirectRoute = window.location.origin + '/login'
            // window.location.href = refirectRoute

            setTimeout(() => {
                setLoggedUser(null)
            }, 2000)

            return
        }

        setLoggedUser(user)
    }

    function setAuthorizationToken(token, mustFormatToken = false) {
        let formatedToken = token

        if (mustFormatToken) {
            formatedToken = 'Bearer ' + token
        }

        axiosInstance.defaults.headers.common.Authorization = formatedToken
        localStorage.setItem('token', formatedToken)
    }

    function getAuthorizationToken() {
        return localStorage.getItem('token')
    }

    function removeAuthorizationToken() {
        axiosInstance.defaults.headers.common.Authorization = null

        localStorage.removeItem('user')
        localStorage.removeItem('token')
    }

    return (
        <AuthContext.Provider
            value={{
                loggedUser,
                setLoggedUser: onChangeLoggedUser,
                userPermissions,
                setUserPermissions,
                isAdmin,
                setAuthorizationToken,
                getAuthorizationToken,
                removeAuthorizationToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
