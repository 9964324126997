import { displayMessage } from '../../../helpers/messages'

export const report_config_grid_columns = [
    {
        field: 'title',
        headerName: displayMessage('TITLE'),
        type: 'string',
        width: 300,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.title) return row.title
            return displayMessage('EMPTY_01')
        },
    },
]
