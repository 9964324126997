import { Form, Modal, ColorPicker, Space } from 'antd'
import { useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'

const STATUS_SUCCESS = 200

export function ModalCreatePriorityLevel({ isOpen, onClose, reloadPriorityLevels }) {
    const formRef = useRef()

    const [loading, setLoading] = useState(false)

    const [color, setColor] = useState('#1677ff')

    const onSubmit = async priority_level => {
        setLoading(true)
        try {
            const auxPriorityLevel = { ...priority_level, color }

            const response = await api.priorityLevels.create(auxPriorityLevel)

            if (response.status === STATUS_SUCCESS) {
                onClose(false)
                await reloadPriorityLevels()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleOnChangeColor = value => {
        setColor(value.toHexString())
    }

    return (
        <Modal
            title={displayMessage('PRIORITY_LEVEL_CREATION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            okText={displayMessage('FINISH')}
            maskClosable={false}
        >
            <Form onFinish={onSubmit} className="max-w-[700px] w-full" layout="vertical" requiredMark={false} ref={formRef}>
                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('FIRST_NAME')}</div>

                    <Form.Item name="name" rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]} className="mb-4">
                        <SL_Input placeholder={displayMessage('FIRST_NAME')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('MODULE_DESCRIPTION')}</div>

                    <Form.Item name="description" rules={[{ required: true, message: displayMessage('DISPLAY_DESCRIPTION') }]} className="mb-4">
                        <SL_Input placeholder={displayMessage('MODULE_DESCRIPTION')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('BOTTOM_RANGE')}</div>

                    <Form.Item
                        name="bottom_range"
                        className="mb-4"
                        rules={[
                            { required: true, message: displayMessage('BOTTOM_RANGE') },
                            {
                                validator: (rule, value) => {
                                    if (value < 0) {
                                        return Promise.reject(displayMessage('RANGE_ERROR'))
                                    }
                                    return Promise.resolve()
                                },
                            },
                        ]}
                    >
                        <SL_Input placeholder={displayMessage('BOTTOM_RANGE')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('TOP_RANGE')}</div>

                    <Form.Item
                        name="top_range"
                        className="mb-4"
                        rules={[
                            { required: true, message: displayMessage('BOTTOM_RANGE') },
                            {
                                validator: (rule, value) => {
                                    if (value < 0) {
                                        return Promise.reject(displayMessage('RANGE_ERROR'))
                                    }
                                    return Promise.resolve()
                                },
                            },
                        ]}
                    >
                        <SL_Input placeholder={displayMessage('TOP_RANGE')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('COLOR')}</div>
                    <Space direction="vertical">
                        <ColorPicker defaultValue={color} onChange={handleOnChangeColor} />
                    </Space>
                </div>
            </Form>
        </Modal>
    )
}
