import { Form, Modal } from 'antd'
import { useContext, useEffect, useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import { SL_Select } from '../../../components/selects/Select'
import EUserTypes from '../../../enums/EUserTypes'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../../context/AuthContext/AuthContext'

const STATUS_SUCCESS = 200

export function ModalCreateClientUser({ isOpen, onClose, reloadClientUsers }) {
    const formRef = useRef()
    const { clientId } = useParams()
    const { loggedUser } = useContext(AuthContext)

    const [loading, setLoading] = useState(false)
    const [allWorkSectors, setAllWorkSectors] = useState(null)
    const [userType, setUserType] = useState(null)

    const [workSectorId, setWorkSectorId] = useState(null)

    useEffect(() => {
        loadWorkSectors()
    }, [])

    const loadWorkSectors = async () => {
        setLoading(true)
        try {
            const { data } = await api.workSectors.list({ client_id: clientId })

            setAllWorkSectors(data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleUserTypeChange = value => {
        setUserType(value)
    }

    const handleWorkSectorChange = value => {
        setWorkSectorId(value)
    }

    const onSubmit = async user => {
        setLoading(true)
        try {
            const auxUser = { ...user, created_by_user_id: loggedUser.id }

            const response = await api.clientUsers.create(auxUser)

            if (response.status === STATUS_SUCCESS) {
                onClose(false)
                await reloadClientUsers()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            title={displayMessage('USER_CREATION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            okText={displayMessage('FINISH')}
            maskClosable={false}
        >
            <Form onFinish={onSubmit} className="max-w-[500px] w-full" layout="vertical" requiredMark={false} ref={formRef}>
                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('FIRST_NAME')}</div>

                    <Form.Item name="first_name" rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]} className="mb-4">
                        <SL_Input placeholder={displayMessage('FIRST_NAME')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('LAST_NAME')}</div>

                    <Form.Item name="last_name" rules={[{ required: true, message: displayMessage('DISPLAY_LAST_NAME') }]} className="mb-6">
                        <SL_Input placeholder={displayMessage('LAST_NAME')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('EMAIL')}</div>

                    <Form.Item name="email" rules={[{ required: true, message: displayMessage('DISPLAY_EMAIL') }]} className="mb-6">
                        <SL_Input placeholder={displayMessage('EMAIL')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('USER_TYPE')}</div>

                    <Form.Item name="type" rules={[{ required: true, message: displayMessage('DISPLAY_USER_TYPE') }]} className="mb-6">
                        <SL_Select
                            maxTagCount="responsive"
                            className="mb-1 h-[28px] rounded-sm"
                            listItemHeight={100}
                            title={displayMessage('USER_TYPE')}
                            placeholder={displayMessage('USER_TYPE')}
                            onChange={handleUserTypeChange}
                            value={userType}
                            options={Object.values(EUserTypes).map(e => ({ label: e.description, value: e.code }))}
                            allowClear
                        />
                    </Form.Item>
                </div>

                {/* <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('WORK_SECTOR')}</div>

                    <Form.Item
                        name="work_sector_id"
                        rules={[{ required: true, message: displayMessage('DISPLAY_WORK_SECTOR') }]}
                        className="mb-6"
                    >
                        <SL_Select
                            maxTagCount="responsive"
                            className="mb-1 h-[28px] rounded-sm"
                            listItemHeight={100}
                            title={displayMessage('WORK_SECTOR')}
                            placeholder={displayMessage('WORK_SECTOR')}
                            onChange={handleWorkSectorChange}
                            value={workSectorId}
                            options={allWorkSectors
                                ?.filter(e => e.inactivation_date === null)
                                .map(e => ({ label: e.name, value: e.id }))} 
                            allowClear
                        />
                    </Form.Item>
                </div> */}
            </Form>
        </Modal>
    )
}
