import { DateTime } from 'luxon'
import { displayMessage } from '../../../helpers/messages'

export const priority_levels_grid_columns = [
    {
        field: 'name',
        headerName: displayMessage('FIRST_NAME'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.name) return row.name
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'description',
        headerName: displayMessage('MODULE_DESCRIPTION'),
        type: 'string',
        width: 600,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.description) return row.description
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'bottom_range',
        headerName: displayMessage('BOTTOM_RANGE'),
        type: 'integer',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.bottom_range != null || row.bottom_range != '') return row.bottom_range
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'top_range',
        headerName: displayMessage('TOP_RANGE'),
        type: 'integer',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.top_range != null) return row.top_range
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'color',
        headerName: displayMessage('COLOR'),
        type: 'string',
        width: 10,
        filterable: false,
        renderCell: params => {
            if (params.row.color) {
                return (
                    <div
                        style={{
                            width: 20,
                            height: 20,
                            borderRadius: 10,
                            backgroundColor: params.row.color,
                            justifySelf: 'center',
                        }}
                    ></div>
                )
            }

            return displayMessage('EMPTY_01')
        },

        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row && row.color) return row.color
            return displayMessage('EMPTY_01')
        },
    },

    {
        field: 'is_active',
        headerName: displayMessage('ACTIVATION_STATUS'),
        type: 'string',
        width: 150,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row) return row.is_active ? displayMessage('ACTIVE') : displayMessage('INACTIVE')
        },
        type: 'singleSelect',
        valueOptions: [
            { value: true, label: 'Ativo' },
            { value: false, label: 'Inativo' },
        ],
    },
    {
        field: 'inactivation_date',
        headerName: displayMessage('INACTIVATION_DATE'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.inactivation_date) return DateTime.fromISO(row.inactivation_date).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'createdAt',
        headerName: displayMessage('CREATED_AT'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.createdAt) return DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
]
