import { Form, Modal, Upload } from 'antd'
import { useContext, useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import { CpfMaskedInput, PhoneMaskedInput } from '../../../helpers/iMask'
import { SL_Select } from '../../../components/selects/Select'
import EUserTypes from '../../../enums/EUserTypes'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'

const STATUS_SUCCESS = 200

export function ModalCreateUser({ isOpen, onClose, reloadUsers }) {
    const { mobile_mode } = useContext(GeneralContext)

    const formRef = useRef()

    const [loading, setLoading] = useState(false)
    const [userType, setUserType] = useState(null)
    const [signatureImage, setSignatureImage] = useState([])

    const handleUserTypeChange = value => {
        setUserType(value)
    }

    const onSubmit = async user => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append('user_data', JSON.stringify(user))

            if (signatureImage.length != 0) {
                const file = new File([signatureImage[0]?.originFileObj], signatureImage[0]?.name, { type: signatureImage[0]?.type })

                formData.append('attachments', file)
            }

            const response = await api.user.create(formData)

            if (response.status === STATUS_SUCCESS) {
                onClose(false)
                await reloadUsers()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleFileOnChange = ({ fileList: newFileList }) => {
        setSignatureImage(newFileList)
    }

    const handleOnFilePreview = async file => {
        let src = file.url

        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()

                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }

        const image = new Image()
        image.src = src

        const imgWindow = window.open(src)
        imgWindow?.document.write(image.outerHTML)
    }

    return (
        <Modal
            title={displayMessage('USER_CREATION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            okText={displayMessage('FINISH')}
            maskClosable={false}
        >
            <Form onFinish={onSubmit} className="max-w-[700px] w-full" layout="vertical" requiredMark={false} ref={formRef}>
                {mobile_mode ? (
                    <>
                        <div className="flex flex-col gap-1">
                            <div className="">{displayMessage('FIRST_NAME')}</div>

                            <Form.Item name="first_name" rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]} className="mb-4">
                                <SL_Input placeholder={displayMessage('FIRST_NAME')} className={'w-full'} />
                            </Form.Item>
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className="">{displayMessage('LAST_NAME')}</div>

                            <Form.Item name="last_name" rules={[{ required: true, message: displayMessage('DISPLAY_LAST_NAME') }]} className="mb-6">
                                <SL_Input className={'w-full'} placeholder={displayMessage('LAST_NAME')} />
                            </Form.Item>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col gap-1">
                                <div className="">{displayMessage('FIRST_NAME')}</div>

                                <Form.Item name="first_name" rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]} className="mb-4">
                                    <SL_Input placeholder={displayMessage('FIRST_NAME')} className={'w-full'} />
                                </Form.Item>
                            </div>

                            <div className="flex flex-col gap-1">
                                <div className="">{displayMessage('LAST_NAME')}</div>

                                <Form.Item
                                    name="last_name"
                                    rules={[{ required: true, message: displayMessage('DISPLAY_LAST_NAME') }]}
                                    className="mb-6"
                                >
                                    <SL_Input className={'w-full'} placeholder={displayMessage('LAST_NAME')} />
                                </Form.Item>
                            </div>
                        </div>
                    </>
                )}
                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('EMAIL')}</div>

                    <Form.Item name="email" rules={[{ required: true, message: displayMessage('DISPLAY_EMAIL') }]} className="mb-6">
                        <SL_Input className={'w-full'} placeholder={displayMessage('EMAIL')} />
                    </Form.Item>
                </div>

                {/* <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('PHONE')}</div>

                    <Form.Item
                        name="phone"
                        className="mb-6"
                    >
                        {PhoneMaskedInput({
                            placeholder: displayMessage('PHONE'),
                        })}
                    </Form.Item>
                </div> */}

                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('CREA')}</div>

                    <Form.Item name="crea" className="mb-6">
                        <SL_Input className={'w-full'} placeholder={displayMessage('CREA')} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('ASSIGNMENT')}</div>

                    <Form.Item name="assignments" className="mb-6">
                        <SL_Input className={'w-full'} placeholder={displayMessage('ASSIGNMENT')} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('USER_TYPE')}</div>

                    <Form.Item name="type" rules={[{ required: true, message: displayMessage('DISPLAY_USER_TYPE') }]} className="mb-6">
                        <SL_Select
                            maxTagCount="responsive"
                            className="mb-1 h-[28px] rounded-sm"
                            listItemHeight={100}
                            title={displayMessage('USER_TYPE')}
                            placeholder={displayMessage('USER_TYPE')}
                            onChange={handleUserTypeChange}
                            value={userType}
                            options={Object.values(EUserTypes).map(e => ({ label: e.description, value: e.code }))}
                            allowClear
                        />
                    </Form.Item>
                </div>
                <div className="w-full flex flex-col gap-1 justify-center ml-8 mt-5">
                    <div className="ml-4">{displayMessage('SIGNATURE')}</div>
                    <Upload
                        beforeUpload={() => false}
                        listType="picture-card"
                        fileList={signatureImage}
                        onChange={handleFileOnChange}
                        onPreview={handleOnFilePreview}
                    >
                        {signatureImage.length < 1 && '+'}
                    </Upload>
                </div>
                <div className="text-xs mt-2 text-center w-40">
                    <p>{displayMessage('RECOMMENDATION_DIMENSIONS')}</p>
                    <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                </div>
            </Form>
        </Modal>
    )
}
