import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../../services/api'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import { Form, Input, Switch, Tabs } from 'antd'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import EActivationStatus from '../../../enums/EActivationStatus'
import { SL_Button } from '../../../components/buttons/Button'
import styles from '../../../services/styles'
import NormativesFilters from './NormativesFilters'
import CustomToolbar from '../../../components/grid/CustomToolbar'
import { normatives_grid_columns } from '../grid_columns/normatives_grid_columns'
import { SL_Grid } from '../../../components/grid/SL_Grid'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { ModalVinculeNormative } from './ModalVinculeNormative'
import { SL_Select } from '../../../components/selects/Select'
import { MultiSelect } from '../../../components/selects/MultiSelect'
import { AuthContext } from '../../../context/AuthContext/AuthContext'

const GERAL_RISK_DATA_KEY = 1
const RISK_NORMATIVES_KEY = 2
const maxRequesterForFilter = 5

export function RiskDetails({}) {
    const { riskId } = useParams()

    const { mobile_mode } = useContext(GeneralContext)
    const { isAdmin } = useContext(AuthContext)

    const navigate = useNavigate()

    const formRef = useRef()

    const [risk, setRisk] = useState(null)
    const [selectedRecommendation, setSelectedRecommendation] = useState(null)

    const [riskTypes, setRiskTypes] = useState([])
    const [selectedRiskType, setSelectedRiskType] = useState(null)

    const [riskActivities, setRiskActivities] = useState([])
    const [selectedRiskActivity, setSelectedRiskActivity] = useState(null)

    const [riskKinds, setRiskKinds] = useState([])
    const [selectedRiskKind, setSelectedRiskKind] = useState(null)

    const [normatives, setNormatives] = useState([])
    const [loading, setLoading] = useState(false)

    const [gridLoading, setGridLoading] = useState(false)
    const [rowOnFocus, setRowOnFocus] = useState(null)

    const [isEditing, setIsEditing] = useState(false)

    const [isActive, setIsActive] = useState(null)

    const [modalVinculeNormativeIsOpen, setModalVinculeNormativeIsOpen] = useState(false)
    const [isRecommendationSelected, setIsRecommendationSelected] = useState(true)

    useEffect(() => {
        loadAllData()
    }, [])

    useEffect(() => {
        if (selectedRiskType) loadRiskActivities(selectedRiskType)
    }, [selectedRiskType])

    useEffect(() => {
        if (selectedRiskActivity) loadRiskKinds(selectedRiskActivity)
    }, [selectedRiskActivity])

    const loadAllData = async () => {
        setLoading(true)

        await Promise.all([loadRisk(), loadRiskTypes()])

        setLoading(false)
    }

    const loadRisk = async (id = riskId) => {
        setLoading(true)
        try {
            const { data } = await api.risks.find(id)

            console.log(data)

            setRisk(data)
            setIsActive(data.is_active)
            setSelectedRecommendation(data?.Recommendation)
            setNormatives(data?.Normatives)
            setSelectedRiskType(data?.RiskKind?.RiskActivity?.RiskType?.id)
            setSelectedRiskActivity(data?.RiskKind?.RiskActivity?.id)
            setSelectedRiskKind(data?.RiskKind?.id)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const loadRiskTypes = async () => {
        try {
            const { data } = await api.riskTypes.list()

            setRiskTypes(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadRiskActivities = async risk_type_id => {
        try {
            const { data } = await api.riskActivities.list({ risk_type_id })

            setRiskActivities(data)
            setRiskKinds([])
        } catch (error) {
            console.log(error)
        }
    }

    const loadRiskKinds = async risk_activity_id => {
        try {
            const { data } = await api.riskKinds.list({ risk_activity_id })

            setRiskKinds(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadNormativesForThisRisk = async params => {
        setGridLoading(true)
        try {
            const auxParams = { ...params, risk_id: riskId }

            const { data } = await api.normatives.list(auxParams)

            setNormatives(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const onSubmit = async e => {
        setLoading(true)
        try {
            const auxRisk = {
                ...e,
                recommendation_id: e.recommendation?.value,
                risk_kind_id: selectedRiskKind,
                risk_activity_id: selectedRiskActivity,
                risk_type_id: selectedRiskType,
                is_active: isActive,
            }

            const { data } = await api.risks.update(riskId, auxRisk)
            navigate(`/risks/risk/${data.id}`)
            await loadRisk(data.id)
        } catch (error) {
            console.log(error)
        } finally {
            setIsEditing(false)
            setLoading(false)
        }
    }

    const handleOnRecommendationChange = recommendation_id => {
        setSelectedRecommendation(recommendation_id)
    }

    const handleOnRiskTypeChange = risk_type_id => {
        setSelectedRiskType(risk_type_id)

        setRiskActivities([])
        setRiskKinds([])
    }

    const handleOnRiskActivityChange = risk_activity_id => {
        setSelectedRiskActivity(risk_activity_id)

        setRiskKinds([])
    }

    const handleRiskKindChange = risk_kind_id => {
        setSelectedRiskKind(risk_kind_id)
    }

    const mountRecommendations = async e => {
        try {
            const { data } = await api.recommendations.listForSelectOptions({
                title: e,
                limit: maxRequesterForFilter,
                statusActivation: EActivationStatus.ATIVO,
            })

            return data
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnSelectRecommendation = async e => {
        try {
            setIsRecommendationSelected(true)
            if (e == undefined) {
                setIsRecommendationSelected(false)
                formRef.current.setFieldValue('recommendation', null)
                return
            }
            formRef.current.setFieldsValue({
                recommendation_description: e.label,
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div>
                {loading ? (
                    <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                        <SL_ClipLoader loading={true} />
                    </div>
                ) : (
                    <>
                        <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    key: GERAL_RISK_DATA_KEY,
                                    label: displayMessage('RISK'),
                                    children: (
                                        <>
                                            {isAdmin ? (
                                                <div className="w-full flex flex-row justify-end">
                                                    <div className="mr-2">{displayMessage('ENABLE_EDIT')}</div>
                                                    <Switch onChange={e => setIsEditing(e)} />
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            <div className="w-full flex justify-center">
                                                <Form
                                                    onFinish={onSubmit}
                                                    className="max-w-[700px] w-full"
                                                    layout="vertical"
                                                    requiredMark={false}
                                                    ref={formRef}
                                                >
                                                    <div className="flex flex-col gap-1">
                                                        <div className="">{displayMessage('TITLE')}</div>

                                                        <Form.Item name="title" initialValue={risk?.title} className="mb-4">
                                                            <Input.TextArea
                                                                autoSize={{ minRows: 2 }}
                                                                placeholder={displayMessage('TITLE')}
                                                                className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                disabled={!isEditing}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <div className="">{displayMessage('MODULE_DESCRIPTION')}</div>

                                                        <Form.Item
                                                            name="description"
                                                            initialValue={risk?.description}
                                                            rules={[{ required: true, message: displayMessage('DISPLAY_DESCRIPTION') }]}
                                                            className="mb-4"
                                                        >
                                                            <Input.TextArea
                                                                autoSize={{ minRows: 2 }}
                                                                placeholder={displayMessage('MODULE_DESCRIPTION')}
                                                                className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                disabled={!isEditing}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="flex flex-col gap-1 mb-4">
                                                        <div>{displayMessage('RECOMMENDATION')}</div>
                                                        <Form.Item
                                                            name="recommendation"
                                                            className="mb-4"
                                                            rules={[{ required: true, message: displayMessage('RECOMMENDATION') }]}
                                                            initialValue={[
                                                                { label: selectedRecommendation?.title, value: selectedRecommendation?.id },
                                                            ]}
                                                        >
                                                            <MultiSelect
                                                                mode="single"
                                                                allowClear
                                                                disabled={!isEditing}
                                                                fetchOptions={async e => mountRecommendations(e)}
                                                                placeholder={displayMessage('RECOMMENDATIONS')}
                                                                onChange={handleOnSelectRecommendation}
                                                            />
                                                        </Form.Item>

                                                        {isRecommendationSelected && (
                                                            <Form.Item
                                                                name="recommendation_description"
                                                                rules={[{ required: true, message: displayMessage('RECOMMENDATION') }]}
                                                                className="mb-4"
                                                                initialValue={selectedRecommendation?.description}
                                                            >
                                                                <Input.TextArea
                                                                    disabled
                                                                    autoSize={{ minRows: 1 }}
                                                                    placeholder={displayMessage('RECOMMENDATION')}
                                                                    className={'w-full'}
                                                                />
                                                            </Form.Item>
                                                        )}
                                                    </div>

                                                    <div className="flex flex-col gap-1 mb-4">
                                                        <div>{displayMessage('RISK_TYPE')}</div>
                                                        <SL_Select
                                                            maxTagCount="responsive"
                                                            className="mb-1 h-[28px] rounded-sm"
                                                            listItemHeight={100}
                                                            disabled={!isEditing}
                                                            title={displayMessage('RISK_TYPES')}
                                                            placeholder={displayMessage('RISK_TYPES')}
                                                            onChange={handleOnRiskTypeChange}
                                                            value={riskTypes.find(e => selectedRiskType == e.id)?.name}
                                                            options={riskTypes.map(e => ({ label: e.name, value: e.id }))}
                                                            allowClear
                                                        />
                                                    </div>

                                                    <div className="flex flex-col gap-1 mb-4">
                                                        <div>{displayMessage('RISK_ACTIVITY')}</div>
                                                        <SL_Select
                                                            maxTagCount="responsive"
                                                            className="mb-1 h-[28px] rounded-sm"
                                                            listItemHeight={100}
                                                            disabled={!isEditing}
                                                            title={displayMessage('RISK_ACTIVITIES')}
                                                            placeholder={displayMessage('RISK_ACTIVITIES')}
                                                            onChange={handleOnRiskActivityChange}
                                                            value={riskActivities.find(e => selectedRiskActivity == e.id)?.name}
                                                            options={
                                                                riskActivities.length > 0
                                                                    ? riskActivities?.map(e => ({ label: e?.name, value: e?.id }))
                                                                    : []
                                                            }
                                                            allowClear
                                                        />
                                                    </div>

                                                    <div className="flex flex-col gap-1 mb-4">
                                                        <div>{displayMessage('RISK_KIND')}</div>
                                                        <SL_Select
                                                            maxTagCount="responsive"
                                                            className="mb-1 h-[28px] rounded-sm"
                                                            listItemHeight={100}
                                                            disabled={!isEditing}
                                                            title={displayMessage('RISK_KINDS')}
                                                            placeholder={displayMessage('RISK_KINDS')}
                                                            onChange={handleRiskKindChange}
                                                            value={riskKinds?.find(e => selectedRiskKind == e.id)?.name}
                                                            options={riskKinds.map(e => ({ label: e.name, value: e.id })) ?? []}
                                                            allowClear
                                                        />
                                                    </div>

                                                    <div className="flex flex-col gap-1 mt-1">
                                                        <div className="">{displayMessage('ACTIVATION_STATUS')}</div>
                                                        <SL_Input
                                                            placeholder={
                                                                isActive ? EActivationStatus.ATIVO.description : EActivationStatus.INATIVO.description
                                                            }
                                                            className={'w-full'}
                                                            disabled={true}
                                                        />
                                                    </div>

                                                    <div className="flex flex-col gap-1 mt-5 mb-5">
                                                        <SL_Button
                                                            className="md-mx-0 rounder-l-none"
                                                            style={{
                                                                backgroundColor: !isEditing
                                                                    ? 'gray'
                                                                    : isActive
                                                                    ? styles.colors['sl-red']['400']
                                                                    : '#0e730d',
                                                                color: 'white',
                                                            }}
                                                            disabled={!isEditing}
                                                            onClick={() => setIsActive(prev => !prev)}
                                                        >
                                                            {isActive ? 'Desativar' : 'Ativar'}
                                                        </SL_Button>
                                                    </div>

                                                    <Form.Item hasFeedback className="mb-3 mt-2">
                                                        <SL_Button
                                                            disabled={!isEditing}
                                                            type="primary"
                                                            className={'w-full font-bold shadow-md'}
                                                            htmlType="submit"
                                                        >
                                                            {displayMessage('EDIT')}
                                                        </SL_Button>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </>
                                    ),
                                },
                                {
                                    key: RISK_NORMATIVES_KEY,
                                    label: displayMessage('RISK_NORMATIVES'),
                                    children: (
                                        <>
                                            <div>
                                                <NormativesFilters
                                                    loadNormatives={loadNormativesForThisRisk}
                                                    setGridLoading={setGridLoading}
                                                    setNormatives={setNormatives}
                                                />
                                                {modalVinculeNormativeIsOpen && (
                                                    <ModalVinculeNormative
                                                        isOpen={modalVinculeNormativeIsOpen}
                                                        onClose={setModalVinculeNormativeIsOpen}
                                                        reloadNormatives={loadNormativesForThisRisk}
                                                    />
                                                )}

                                                <SL_Grid
                                                    rows={normatives ?? []}
                                                    sortingMode={'server'}
                                                    components={{
                                                        Toolbar: () => (
                                                            <>
                                                                <div className="flex justify-between flex-row ss:flex-row">
                                                                    <CustomToolbar />
                                                                    <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                                        <SL_Button
                                                                            className="my-1 mr-1"
                                                                            onClick={() => setModalVinculeNormativeIsOpen(true)}
                                                                        >
                                                                            {displayMessage('VINCULE_NORMATIVE_TO_RISK')}
                                                                        </SL_Button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ),
                                                    }}
                                                    disableFilter={false}
                                                    onRowClick={params => {
                                                        setRowOnFocus(params.row)
                                                    }}
                                                    columns={normatives_grid_columns}
                                                    className="flex-1 min-h-[61vh] max-h-[100vh]"
                                                    loading={gridLoading}
                                                />
                                            </div>
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </>
                )}
            </div>
        </>
    )
}
