import { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext/AuthContext'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { UserOutlined } from '@ant-design/icons'
import { displayMessage } from '../../helpers/messages'
import styles from '../../services/styles'
import { SL_Input } from '../../components/input/Input'
import { SL_Button } from '../../components/buttons/Button'
import { EAlertTypes } from '../../enums/EAlertTypes'
import api from '../../services/api'

export default function Profile({}) {
    const { showAlert, setShowLoading, mobile_mode } = useContext(GeneralContext)
    const { loggedUser, setLoggedUser } = useContext(AuthContext)

    const [oldPassword, setOldPassword] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [confirmedNewPassword, setConfirmedNewPassword] = useState(null)
    const [isValidPassword, setIsValidPassword] = useState(true)

    const [name, setName] = useState(loggedUser?.first_name ?? null)
    const [lastName, setLastName] = useState(loggedUser?.last_name ?? null)

    const validatePassword = value => {
        const lowercaseRegex = /[a-z]/
        const uppercaseRegex = /[A-Z]/
        const numberRegex = /[0-9]/
        const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/

        const isLowercaseValid = lowercaseRegex.test(value)
        const isUppercaseValid = uppercaseRegex.test(value)
        const isNumberValid = numberRegex.test(value)
        const isSpecialCharValid = specialCharRegex.test(value)

        const validPassword = isLowercaseValid && isUppercaseValid && isNumberValid && isSpecialCharValid

        setIsValidPassword(validPassword)
    }

    const handleChangePassword = async () => {
        setShowLoading(true)
        try {
            if (newPassword != confirmedNewPassword) {
                showAlert('PASSWORD_CONFIRMATION_FAILED', EAlertTypes.ERROR)
                throw new Error()
            }
            if (!newPassword || !confirmedNewPassword || !oldPassword) {
                showAlert('FILL_ALL_FIELDS_CHANGE_PASSWORD', EAlertTypes.ERROR)
                throw new Error()
            }

            await api.auth.changePassword(oldPassword, newPassword)

            resetFieldsUpdatePassword()
            showAlert('PASSWORD_CHANGE_SUCCESS', EAlertTypes.SUCCESS)
            setLoggedUser(false)
        } catch (error) {
            console.log(error)
            resetFieldsUpdatePassword()
        } finally {
            setShowLoading(false)
        }
    }

    const handleChangeName = async () => {
        setShowLoading(true)
        try {
            const formData = new FormData()

            const user_data = {}

            if (name != null) user_data.first_name = name
            if (lastName != null) user_data.last_name = lastName

            console.log(user_data)

            formData.append('user_data', JSON.stringify(user_data))
            const { data } = await api.user.update(loggedUser.id, formData)
            setLoggedUser(data)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
            resetFieldsUpdateName()
        }
    }

    const resetFieldsUpdatePassword = () => {
        setOldPassword('')
        setNewPassword('')
        setConfirmedNewPassword('')
    }

    const resetFieldsUpdateName = () => {
        setName(null)
        setLastName(null)
    }

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-1 flex-row">
                <div style={{ color: 'white', background: styles.colors.primary['900'], padding: 20 }}>
                    <UserOutlined style={{ fontSize: 80 }} />
                </div>
                <div className="flex flex-1 flex-col justify-end ml-3 text-base font-bold">
                    <div className="text-primary-900">
                        {loggedUser?.first_name} {loggedUser?.last_name}
                    </div>
                    <div style={{ color: styles.colors.primary['900'] }}>{loggedUser?.email}</div>
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: mobile_mode ? 'column' : 'row',
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginTop: 30,
                    color: styles.colors.primary['900'],
                    width: '100%',
                    borderTop: '1px solid lightgray',
                }}
            >
                <div className="pb-25 flex flex-col w-full">
                    <div className="mt-5 text-center font-bold text-lg">{displayMessage('CHANGE_PASSWORD')}</div>
                    <div className="flex flex-col mt-10">
                        <div
                            style={{
                                display: 'flex',
                                gap: 15,
                                alignItems: 'center',
                                flexDirection: mobile_mode ? 'column' : 'row',
                            }}
                        >
                            <div className="flex flex-col text-sm flex-1 w-full">
                                <div style={{ fontWeight: 'bold' }}>{displayMessage('OLD_PASSWORD')}:</div>
                                <SL_Input
                                    type="password"
                                    value={oldPassword}
                                    onChange={e => {
                                        setOldPassword(e.target.value)
                                    }}
                                    style={{ border: `solid 1px ${styles.colors.primary['900']}`, height: 40, width: mobile_mode ? '100%' : 'auto' }}
                                />
                            </div>

                            <div className="flex flex-col text-sm flex-1 w-full">
                                <div className="font-bold">{displayMessage('NEW_PASSWORD')}:</div>
                                <SL_Input
                                    type="password"
                                    value={newPassword}
                                    onChange={e => {
                                        setNewPassword(e.target.value)
                                        validatePassword(e.target.value)
                                    }}
                                    style={{ border: `solid 1px ${styles.colors.primary['900']}`, height: 40, width: mobile_mode ? '100%' : 'auto' }}
                                />
                            </div>

                            <div className="flex flex-col text-sm flex-1 w-full">
                                <div className="font-bold">{displayMessage('CONFIRM_NEW_PASSWORD')}:</div>
                                <SL_Input
                                    type="password"
                                    value={confirmedNewPassword}
                                    onChange={e => {
                                        setConfirmedNewPassword(e.target.value)
                                        validatePassword(e.target.value)
                                    }}
                                    style={{ border: `solid 1px ${styles.colors.primary[900]}`, height: 40, width: mobile_mode ? '100%' : 'auto' }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col text-red-600 text-sm">{!isValidPassword && displayMessage('PASSWORD_VALIDATION')}</div>
                    </div>
                    <div className="flex w-full justify-end gap-3">
                        <SL_Button onClick={handleChangePassword} style={{ width: mobile_mode ? '100%' : '150px', marginTop: 15 }}>
                            {displayMessage('CONFIRM_CHANGE')}
                        </SL_Button>
                    </div>

                    <div className="mt-5 text-center font-bold text-lg">{displayMessage('CHANGE_USER_FULL_NAME')}</div>

                    <div className="flex flex-col mt-10">
                        <div
                            style={{
                                display: 'flex',
                                gap: 15,
                                alignItems: 'center',
                                flexDirection: mobile_mode ? 'column' : 'row',
                            }}
                        >
                            <div className="flex flex-col text-sm flex-1 w-full">
                                <div style={{ fontWeight: 'bold' }}>{displayMessage('NAME')}:</div>
                                <SL_Input
                                    value={name}
                                    onChange={e => {
                                        e.target.value == '' ? setName(null) : setName(e.target.value)
                                    }}
                                    style={{ border: `solid 1px ${styles.colors.primary['900']}`, height: 40, width: mobile_mode ? '100%' : 'auto' }}
                                />
                            </div>

                            <div className="flex flex-col text-sm flex-1 w-full">
                                <div className="font-bold">{displayMessage('LAST_NAME')}:</div>
                                <SL_Input
                                    value={lastName}
                                    onChange={e => {
                                        e.target.value == '' ? setLastName(null) : setLastName(e.target.value)
                                    }}
                                    style={{ border: `solid 1px ${styles.colors.primary['900']}`, height: 40, width: mobile_mode ? '100%' : 'auto' }}
                                />
                            </div>
                        </div>
                        <div className="flex w-full justify-end gap-3">
                            <SL_Button onClick={handleChangeName} style={{ width: mobile_mode ? '100%' : '150px', marginTop: 15 }}>
                                {displayMessage('CONFIRM_CHANGE')}
                            </SL_Button>
                        </div>
                        <div className="flex flex-col text-red-600 text-sm">{!isValidPassword && displayMessage('PASSWORD_VALIDATION')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
