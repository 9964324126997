import { Button, Modal } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useEffect, useState, useContext } from 'react'
import api from '../../../services/api'
import { RisksDetailPanel } from './RisksDetailPanel'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'

export function ModalResolveRiskConflicts({ isOpen, onClose, onResolve, conflict_id, reloadRisks }) {
    const { windowHeight } = useContext(GeneralContext)
    const [loading, setLoading] = useState(false)
    const [conflictReportRisks, setConflictReportRisks] = useState([])
    const [reportRiskIdToResolve, setReportRiskIdToResolve] = useState(null)

    useEffect(() => {
        loadConflictReportRisks()
    }, [])

    const loadConflictReportRisks = async () => {
        setLoading(true)
        try {
            const { data } = await api.conflicts.find(conflict_id)
            setConflictReportRisks(data.ConflictReportRisks)
        } catch (error) {
            console.log('erro aqui \n', error)
        } finally {
            setLoading(false)
        }
    }

    const resolveConflictForReportRisks = async () => {
        setLoading(true)
        try {
            await api.conflicts.resolveConflict({ conflict_id, report_risk_id: reportRiskIdToResolve })
            await reloadRisks()
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            onClose(false)
        }
    }

    return (
        <Modal
            title={
                <div className="flex flex-row w-full gap-3">
                    <ExclamationCircleOutlined style={{ fontSize: 20 }} />
                    {displayMessage('MODAL_TITLE_CONFLICT_REPORT_RISKS')}
                </div>
            }
            centered
            style={{ padding: 20 }}
            open={isOpen}
            onCancel={() => onClose(false)}
            footer={[
                <Button onClick={() => onClose(false)}>{displayMessage('CANCEL')}</Button>,
                <Button type="primary" onClick={resolveConflictForReportRisks} disabled={!reportRiskIdToResolve}>
                    {displayMessage('CONFIRM')}
                </Button>,
            ]}
            maskClosable={false}
            width={1500}
            bodyStyle={{
                height: 0.73 * windowHeight,
                overflowY: 'auto',
            }}
            loading={loading}
        >
            {/* <div className="flex w-full justify-center">
            <ExclamationCircleOutlined style={{ fontSize: 30 }} />
        </div> */}
            {loading ? (
                <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                    <SL_ClipLoader loading={true} />
                </div>
            ) : (
                <div className="flex flex-row w-full gap-3 overflow-x-auto">
                    {conflictReportRisks?.length > 0 &&
                        conflictReportRisks.map(cr => {
                            return (
                                <RisksDetailPanel
                                    risk={cr.ReportRisk}
                                    isConflictFromApp={conflictReportRisks.find(crr => crr.report_risk_id == cr?.ReportRisk?.id)?.is_from_app}
                                    setReportRiskIdToResolve={setReportRiskIdToResolve}
                                    reportRiskIdToResolve={reportRiskIdToResolve}
                                />
                            )
                        })}
                </div>
            )}
        </Modal>
    )
}
