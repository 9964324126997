import { AutoComplete, Form, Input, Modal } from 'antd'
import { useContext, useEffect, useRef, useState } from 'react'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import { SL_Select } from '../../../components/selects/Select'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { EAlertTypes } from '../../../enums/EAlertTypes'
import { MultiSelect } from '../../../components/selects/MultiSelect'
import EActivationStatus from '../../../enums/EActivationStatus'

const STATUS_SUCCESS = 200
const maxRequesterForFilter = 5

export function ModalCreateRisk({ isOpen, onClose, reloadRisks }) {
    const { showAlert, windowHeight } = useContext(GeneralContext)

    const formRef = useRef()

    const [loading, setLoading] = useState(false)

    const [normatives, setNormatives] = useState([])
    const [selectedNormatives, setSelectedNormatives] = useState([])

    const [riskTypes, setRiskTypes] = useState([])
    const [selectedRiskType, setSelectedRiskType] = useState(null)

    const [riskActivities, setRiskActivities] = useState([])
    const [selectedRiskActivity, setSelectedRiskActivity] = useState(null)

    const [riskKinds, setRiskKinds] = useState([])
    const [selectedRiskKind, setSelectedRiskKind] = useState(null)

    const [isRecommendationSelected, setIsRecommendationSelected] = useState(false)

    useEffect(() => {
        loadAllData()
    }, [])

    useEffect(() => {
        if (selectedRiskType) {
            loadRiskActivities(selectedRiskType)
        } else {
            setRiskActivities([])
            setSelectedRiskActivity(null)
        }
    }, [selectedRiskType])

    useEffect(() => {
        if (selectedRiskActivity) {
            loadRiskKinds(selectedRiskActivity)
        } else {
            setRiskKinds([])
            setSelectedRiskKind(null)
        }
    }, [selectedRiskActivity])

    const loadAllData = async () => {
        setLoading(true)

        await Promise.all([loadNormatives(), loadRiskTypes()])

        setLoading(false)
    }

    const loadNormatives = async () => {
        try {
            const { data } = await api.normatives.list()

            setNormatives(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadRiskTypes = async () => {
        try {
            const { data } = await api.riskTypes.list()

            setRiskTypes(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadRiskActivities = async risk_type_id => {
        try {
            const { data } = await api.riskActivities.list({ risk_type_id })

            setRiskActivities(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadRiskKinds = async risk_activity_id => {
        try {
            const { data } = await api.riskKinds.list({ risk_activity_id })

            setRiskKinds(data)
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = async risk => {
        if (!selectedRiskKind) {
            showAlert(displayMessage('ERROR_RISK_KIND_IS_MISSING'), EAlertTypes.ERROR)
            return
        }

        setLoading(true)
        try {
            const auxRisk = {
                ...risk,
                normatives_ids: selectedNormatives,
                recommendation_id: risk.recommendation.value,
                risk_kind_id: selectedRiskKind,
            }

            const response = await api.risks.create(auxRisk)

            if (response.status === STATUS_SUCCESS) {
                onClose(false)
                await reloadRisks()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleNormativeChange = value => {
        setSelectedNormatives(value)
    }

    const handleRiskTypeChange = risk_type_id => {
        setSelectedRiskType(risk_type_id)
    }

    const handleRiskActivityChange = risk_activity_id => {
        setSelectedRiskActivity(risk_activity_id)
    }

    const handleRiskKindChange = risk_kind_id => {
        setSelectedRiskKind(risk_kind_id)
    }

    const mountRecommendations = async e => {
        try {
            const { data } = await api.recommendations.listForSelectOptions({
                title: e,
                limit: maxRequesterForFilter,
                statusActivation: EActivationStatus.ATIVO,
            })

            return data
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnSelectRecommendation = async e => {
        try {
            setIsRecommendationSelected(true)
            if (e == undefined) {
                setIsRecommendationSelected(false)
                formRef.current.setFieldValue('recommendation', null)
                return
            }
            formRef.current.setFieldsValue({
                recommendation_description: e.label,
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Modal
            title={displayMessage('RISK_CREATION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            okText={displayMessage('FINISH')}
            maskClosable={false}
            bodyStyle={{
                maxHeight: 0.8 * windowHeight,
                overflowY: 'auto',
            }}
        >
            {loading ? (
                <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                    <SL_ClipLoader loading={true} />
                </div>
            ) : (
                <Form onFinish={onSubmit} className="max-w-[700px] w-full" layout="vertical" ref={formRef}>
                    <div className="flex flex-col gap-1">
                        <div className="">{displayMessage('TITLE')}</div>

                        <Form.Item name="title" rules={[{ required: true, message: displayMessage('DISPLAY_TITLE') }]} className="mb-4">
                            <Input.TextArea autoSize={{ minRows: 2 }} placeholder={displayMessage('TITLE')} className={'w-full'} />
                        </Form.Item>
                    </div>

                    <div className="flex flex-col gap-1">
                        <div className="">{displayMessage('MODULE_DESCRIPTION')}</div>

                        <Form.Item name="description" rules={[{ required: true, message: displayMessage('DISPLAY_DESCRIPTION') }]} className="mb-4">
                            <Input.TextArea autoSize={{ minRows: 2 }} placeholder={displayMessage('MODULE_DESCRIPTION')} className={'w-full'} />
                        </Form.Item>
                    </div>

                    <div className="flex flex-col gap-1 mb-4">
                        <div className="">{displayMessage('NORMATIVES')}</div>
                        <SL_Select
                            maxTagCount="responsive"
                            className="mb-1 h-[28px] rounded-sm"
                            mode="multiple"
                            listItemHeight={100}
                            title={displayMessage('NORMATIVES')}
                            placeholder={displayMessage('NORMATIVES')}
                            onChange={handleNormativeChange}
                            value={selectedNormatives ?? []}
                            options={normatives.map(e => ({ label: e.title, value: e.id }))}
                            allowClear
                            showSearch
                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                        />
                    </div>

                    <div className="flex flex-col gap-1">
                        <div className="">{displayMessage('RECOMMENDATION')}</div>
                        <Form.Item name="recommendation" rules={[{ required: true, message: displayMessage('RECOMMENDATION') }]} className="mb-4">
                            <MultiSelect
                                mode="single"
                                allowClear
                                fetchOptions={async e => mountRecommendations(e)}
                                placeholder={displayMessage('RECOMMENDATIONS')}
                                onChange={handleOnSelectRecommendation}
                            />
                        </Form.Item>
                        {isRecommendationSelected && (
                            <Form.Item
                                name="recommendation_description"
                                rules={[{ required: true, message: displayMessage('RECOMMENDATION') }]}
                                className="mb-4"
                            >
                                <Input.TextArea
                                    disabled
                                    autoSize={{ minRows: 1 }}
                                    placeholder={displayMessage('RECOMMENDATION')}
                                    className={'w-full'}
                                />
                            </Form.Item>
                        )}
                    </div>

                    <div className="flex flex-col gap-1 mb-4">
                        <div>{displayMessage('RISK_TYPE')}</div>
                        <SL_Select
                            maxTagCount="responsive"
                            className="mb-1 h-[28px] rounded-sm"
                            listItemHeight={100}
                            title={displayMessage('RISK_TYPES')}
                            placeholder={displayMessage('RISK_TYPES')}
                            onChange={handleRiskTypeChange}
                            value={riskTypes.find(e => selectedRiskType == e.id)?.name}
                            options={riskTypes.map(e => ({ label: e.name, value: e.id }))}
                            allowClear
                        />
                    </div>

                    <div className="flex flex-col gap-1 mb-4">
                        <div>{displayMessage('RISK_ACTIVITY')}</div>
                        <SL_Select
                            maxTagCount="responsive"
                            className="mb-1 h-[28px] rounded-sm"
                            listItemHeight={100}
                            disabled={selectedRiskType == null}
                            title={displayMessage('RISK_ACTIVITIES')}
                            placeholder={displayMessage('RISK_ACTIVITIES')}
                            onChange={handleRiskActivityChange}
                            value={riskActivities.find(e => selectedRiskActivity == e.id)?.name}
                            options={riskActivities.length > 0 ? riskActivities?.map(e => ({ label: e?.name, value: e?.id })) : []}
                            allowClear
                        />
                    </div>

                    <div className="flex flex-col gap-1">
                        <div>{displayMessage('RISK_KIND')}</div>
                        <SL_Select
                            maxTagCount="responsive"
                            className="mb-1 h-[28px] rounded-sm"
                            listItemHeight={100}
                            disabled={selectedRiskActivity == null}
                            title={displayMessage('RISK_KINDS')}
                            placeholder={displayMessage('RISK_KINDS')}
                            onChange={handleRiskKindChange}
                            value={riskKinds?.find(e => selectedRiskKind == e.id)?.name}
                            options={riskKinds.map(e => ({ label: e.name, value: e.id })) ?? []}
                            allowClear
                        />
                    </div>
                </Form>
            )}
        </Modal>
    )
}
