import { axiosInstance } from './axiosInstances'

const path = {
    enums: '/api/user/enums',
    auth: '/api/user/auth',
    users: '/api/user/users',
    client: '/api/user/clients',
    attachments: '/api/user/attachments',
    notifications: '/api/user/notifications',
    clientUsers: '/api/user/client-users',
    workSectors: '/api/user/work-sectors',
    addresses: '/api/user/addresses',
    controlMesures: '/api/user/control-mesures',
    frequencies: '/api/user/frequencies',
    detections: '/api/user/detections',
    severities: '/api/user/severities',
    coverageScales: '/api/user/coverage-scales',
    priorityLevels: '/api/user/priority-levels',
    risks: '/api/user/risks',
    normatives: '/api/user/normatives',
    recommendations: '/api/user/recommendations',
    strengths: '/api/user/strengths',
    affectedPillars: '/api/user/affected-pillars',
    reportRisk: '/api/user/report-risks',
    reports: '/api/user/reports',
    riskTypes: '/api/user/risk-types',
    riskActivities: '/api/user/risk-activities',
    riskKinds: '/api/user/risk-kinds',
    followUps: '/api/user/followups',
    reportConfigs: '/api/user/report-configs',
    followupReportRisk: '/api/user/followup-report-risks',
    followupApproval: '/api/user/followup-approvals',
    conflicts: '/api/user/conflicts',
    clientUsersAssocition: '/api/user/client-users-association',
    clientuseraddress: '/api/user/client-users-address',
    clientuserworksector: '/api/user/client-users-worksector',
}

export default {
    enums: {
        list: () => {
            return axiosInstance.get(`${path.enums}/`)
        },
        find: name => {
            return axiosInstance.get(`${path.enums}/${name}`)
        },
    },
    auth: {
        authenticate: body => {
            return axiosInstance.post(`${path.auth}/authenticate`, body)
        },
        resetPasswordSolicitation: email => {
            return axiosInstance.put(`${path.auth}/reset-password-solicitation`, { email })
        },
        resetPasswordForced: (body, token) => {
            return axiosInstance.put(`${path.auth}/reset-password-forced`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
        },
        changePassword: (currentPassword, newPassword) => {
            return axiosInstance.put(`${path.auth}/change-password`, { currentPassword, newPassword })
        },
        currentUser: () => {
            return axiosInstance.get(`${path.auth}/current-user`)
        },
    },

    user: {
        find: id => {
            return axiosInstance.get(`${path.users}/${id}`)
        },
        list: params => {
            return axiosInstance.get(`${path.users}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.users}`, body, { headers: { 'Content-Type': 'multipart/form-data' } })
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.users}/${id}`, body, { headers: { 'Content-Type': 'multipart/form-data' } })
        },
        delete: id => {
            return axiosInstance.put(`${path.users}/${id}`)
        },
        export: body => {
            return axiosInstance.post(`${path.users}/export`, body)
        },
    },

    client: {
        update: (id, body) => {
            return axiosInstance.put(`${path.client}/${id}`, body, { headers: { 'Content-Type': 'multipart/form-data' } })
        },
        create: body => {
            return axiosInstance.post(`${path.client}/`, body, { headers: { 'Content-Type': 'multipart/form-data' } })
        },
        listClients: params => {
            return axiosInstance.get(`${path.client}/list/${params}`)
        },
        get: userId => {
            return axiosInstance.get(`${path.client}/${userId}`)
        },
        changeActive: id => {
            return axiosInstance.put(`${path.client}/change-active/${id}`)
        },
        list: params => {
            return axiosInstance.get(`${path.client}/`, { params })
        },
        find: (id, params) => {
            return axiosInstance.get(`${path.client}/${id}`, { params })
        },
        unlinkCampaign: (id, body) => {
            return axiosInstance.post(`${path.client}/unlink-campaign/${id}`, body)
        },
        associateQuiz: body => {
            return axiosInstance.post(`${path.client}/associate-quiz`, body)
        },
        export: body => {
            return axiosInstance.post(`${path.client}/export`, body)
        },
        listForSelectOptions: params => {
            return axiosInstance.get(`${path.client}/list-select-options`, { params })
        },
    },

    attachments: {
        createForContent: formData => {
            return axiosInstance.post(`${path.attachments}/content`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
        },
        createForPDF: formData => {
            return axiosInstance.post(`${path.attachments}/pdf`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
        },
        list: params => {
            return axiosInstance.get(`${path.attachments}/list`, { params })
        },
        delete: id => {
            return axiosInstance.delete(`${path.attachments}/${id}`)
        },
        imageDownload: id => {
            return `${process.env.REACT_APP_BACKEND_IP}${path.attachments}/image/download/${id}`
        },
        openAttachment: (id, config) => {
            return axiosInstance.get(`${path.attachments}/attachment-open/${id}`, { ...config })
        },
        find: id => {
            return axiosInstance.get(`${path.attachments}/${id}`)
        },
    },

    notifications: {
        listAll: params => {
            return axiosInstance.get(`${path.notifications}/`, { params })
        },
        find: id => {
            return axiosInstance.get(`${path.notifications}/${id}`)
        },
        create: body => {
            return axiosInstance.post(`${path.notifications}/`, body)
        },
        readAll: userId => {
            return axiosInstance.post(`${path.notifications}/read-all/${userId}`)
        },
        markOneAsRead: id => {
            return axiosInstance.put(`${path.notifications}/read/${id}`)
        },
    },
    clientUsers: {
        list: params => {
            return axiosInstance.get(`${path.clientUsers}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.clientUsers}/`, body)
        },
        find: (id, params) => {
            return axiosInstance.get(`${path.clientUsers}/${id}`, { params })
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.clientUsers}/${id}`, body)
        },
        export: body => {
            return axiosInstance.post(`${path.clientUsers}/export`, body)
        },
        listForSelectOptions: params => {
            return axiosInstance.get(`${path.clientUsers}/list-select-options`, { params })
        },
        desvinculate: body => {
            return axiosInstance.put(`${path.clientUsers}/desvinculate-client-user`, body)
        },
    },
    clientUsersAssocition: {
        list: params => {
            return axiosInstance.get(`${path.clientUsersAssocition}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.clientUsersAssocition}/`, body)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.clientUsersAssocition}/${id}`, body)
        },
    },
    clientuseraddress: {
        list: params => {
            return axiosInstance.get(`${path.clientuseraddress}/`, { params })
        },
    },
    clientuserworksector: {
        list: params => {
            return axiosInstance.get(`${path.clientuserworksector}/`, { params })
        },
    },

    workSectors: {
        list: params => {
            return axiosInstance.get(`${path.workSectors}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.workSectors}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.workSectors}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.workSectors}/${id}`, body)
        },
        export: body => {
            return axiosInstance.post(`${path.workSectors}/export`, body)
        },
    },
    addresses: {
        list: params => {
            return axiosInstance.get(`${path.addresses}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.addresses}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.addresses}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.addresses}/${id}`, body)
        },
    },
    controlMesures: {
        list: params => {
            return axiosInstance.get(`${path.controlMesures}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.controlMesures}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.controlMesures}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.controlMesures}/${id}`, body)
        },
    },
    frequencies: {
        list: params => {
            return axiosInstance.get(`${path.frequencies}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.frequencies}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.frequencies}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.frequencies}/${id}`, body)
        },
    },
    detections: {
        list: params => {
            return axiosInstance.get(`${path.detections}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.detections}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.detections}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.detections}/${id}`, body)
        },
    },
    severities: {
        list: params => {
            return axiosInstance.get(`${path.severities}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.severities}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.severities}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.severities}/${id}`, body)
        },
    },
    coverageScales: {
        list: params => {
            return axiosInstance.get(`${path.coverageScales}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.coverageScales}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.coverageScales}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.coverageScales}/${id}`, body)
        },
    },
    priorityLevels: {
        list: params => {
            return axiosInstance.get(`${path.priorityLevels}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.priorityLevels}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.priorityLevels}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.priorityLevels}/${id}`, body)
        },
        calculate: params => {
            return axiosInstance.get(`${path.priorityLevels}/calculate-and-find`, { params })
        },
    },
    risks: {
        list: params => {
            return axiosInstance.get(`${path.risks}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.risks}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.risks}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.risks}/${id}`, body)
        },
        vinculeNormatives: (id, body) => {
            return axiosInstance.post(`${path.risks}/vinculate-normative/${id}`, body)
        },
    },
    normatives: {
        list: params => {
            return axiosInstance.get(`${path.normatives}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.normatives}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.normatives}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.normatives}/${id}`, body)
        },
    },
    recommendations: {
        list: params => {
            return axiosInstance.get(`${path.recommendations}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.recommendations}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.recommendations}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.recommendations}/${id}`, body)
        },
        listForSelectOptions: params => {
            return axiosInstance.get(`${path.recommendations}/list-select-options`, { params })
        },
    },
    strengths: {
        list: params => {
            return axiosInstance.get(`${path.strengths}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.strengths}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.strengths}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.strengths}/${id}`, body)
        },
    },
    affectedPillars: {
        list: params => {
            return axiosInstance.get(`${path.affectedPillars}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.affectedPillars}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.affectedPillars}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.affectedPillars}/${id}`, body)
        },
    },
    reportRisks: {
        list: params => {
            return axiosInstance.get(`${path.reportRisk}/`, { params })
        },
        find: id => {
            return axiosInstance.get(`${path.reportRisk}/${id}`)
        },
        create: body => {
            return axiosInstance.post(`${path.reportRisk}/`, body, { headers: { 'Content-Type': 'multipart/form-data' } })
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.reportRisk}/${id}`, body, { headers: { 'Content-Type': 'multipart/form-data' } })
        },
        updateGeneralData: (id, body) => {
            return axiosInstance.put(`${path.reportRisk}/update-general-data/${id}`, body)
        },
        updateImage: (id, body) => {
            return axiosInstance.put(`${path.reportRisk}/update-image/${id}`, body, { headers: { 'Content-Type': 'multipart/form-data' } })
        },
        delete: id => {
            return axiosInstance.delete(`${path.reportRisk}/${id}`)
        },
        createFromFollowUp: body => {
            return axiosInstance.post(`${path.reportRisk}/from-followup`, body, { headers: { 'Content-Type': 'multipart/form-data' } })
        },
    },
    reports: {
        list: params => {
            return axiosInstance.get(`${path.reports}/`, { params })
        },
        find: id => {
            return axiosInstance.get(`${path.reports}/${id}`)
        },
        finalizate: id => {
            return axiosInstance.post(`${path.reports}/finalization/${id}`)
        },
        open_pdf: (pdfName, config) => {
            return axiosInstance.get(`${path.reports}/pdf-open/${pdfName}`, { ...config })
        },
        generate_pdf: params => {
            return axiosInstance.post(`${path.reports}/pdf/${params.id}/${params.follow_up}`)
        },
    },
    riskTypes: {
        list: () => {
            return axiosInstance.get(`${path.riskTypes}/`)
        },
    },
    riskActivities: {
        list: params => {
            return axiosInstance.get(`${path.riskActivities}/`, { params })
        },
    },
    riskKinds: {
        list: params => {
            return axiosInstance.get(`${path.riskKinds}/`, { params })
        },
    },
    conflicts: {
        find: conflict_id => {
            return axiosInstance.get(`${path.conflicts}/${conflict_id}`)
        },
        resolveConflict: body => {
            return axiosInstance.post(`${path.conflicts}/resolve-conflict`, body)
        },
    },
    followUps: {
        list: params => {
            return axiosInstance.get(`${path.followUps}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.followUps}/`, body)
        },
        find: (id, params) => {
            return axiosInstance.get(`${path.followUps}/${id}`, { params })
        },
        finalizate: body => {
            return axiosInstance.post(`${path.followUps}/finalizate`, body)
        },
        finalizateOldCreateNew: body => {
            return axiosInstance.post(`${path.followUps}/finalizate-old-create-new`, body)
        },
    },
    reportConfigs: {
        list: params => {
            return axiosInstance.get(`${path.reportConfigs}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.reportConfigs}/`, body, { headers: { 'Content-Type': 'multipart/form-data' } })
        },
        find: id => {
            return axiosInstance.get(`${path.reportConfigs}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.reportConfigs}/${id}`, body, { headers: { 'Content-Type': 'multipart/form-data' } })
        },
    },
    followupReportRisk: {
        find: id => {
            return axiosInstance.get(`${path.followupReportRisk}/${id}`)
        },
        changeStatus: body => {
            return axiosInstance.post(`${path.followupReportRisk}/change-status`, body)
        },
        changeClientUserResponsible: body => {
            return axiosInstance.post(`${path.followupReportRisk}/change-client-user-responsible`, body)
        },
        updateAttachmentRelation: (id, body) => {
            return axiosInstance.post(`${path.followupReportRisk}/update-attachment-relation/${id}`, body)
        },
        list: params => {
            return axiosInstance.get(`${path.followupReportRisk}/`, { params })
        },
    },
    followupApprovals: {
        list: params => {
            return axiosInstance.get(`${path.followupApproval}/`, { params })
        },
        find: id => {
            return axiosInstance.get(`${path.followupApproval}/${id}`)
        },
        sendMessage: body => {
            return axiosInstance.post(`${path.followupApproval}/`, body)
        },
    },
}
