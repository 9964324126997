import { DateTime } from 'luxon'
import { displayMessage } from '../../../helpers/messages'
import EUserTypes from '../../../enums/EUserTypes'
import { findClientTypeByCode, formatCnpj } from '../../../helpers/helper'

export const clients_grid_columns = [
    {
        field: 'name',
        headerName: displayMessage('CLIENT_NAME'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.client?.name) return row.client?.name
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.client?.name) return row.client?.name
            return displayMessage('EMPTY_01')
        },
    },

    {
        field: 'cnpj',
        headerName: displayMessage('COMPANY_DOCUMENT'),
        type: 'string',
        width: 150,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.client?.cnpj) return formatCnpj(row.client?.cnpj)
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.client?.cnpj) return formatCnpj(row.client?.cnpj)
            return displayMessage('EMPTY_01')
        },
    },
]
