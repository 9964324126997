import { useContext, useEffect, useState } from 'react'
import api from '../../services/api'
import { displayMessage } from '../../helpers/messages'
import { Tabs } from 'antd'
import { SL_Grid } from '../../components/grid/SL_Grid'
import CustomToolbar from '../../components/grid/CustomToolbar'
import { SL_Button } from '../../components/buttons/Button'
import { useNavigate } from 'react-router-dom'
import { control_mesures_grid_columns } from './grid_columns/control_mesures_grid_columns'
import ControlMesuresFilters from './components/ControlMesuresFilters'
import { ModalCreateControlMesure } from './components/ModalCreateControlMesure'
import { frequencies_grid_columns } from './grid_columns/frequencies_grid_columns'
import { ModalCreateFrequency } from './components/ModalCreateFrequency'
import FrequenciesFilters from './components/FrequenciesFilters'
import { detections_grid_columns } from './grid_columns/detections_grid_columns'
import { ModalCreateDetection } from './components/ModalCreateDetection'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import DetectionsFilters from './components/DetectionsFilters'
import SeveritiesFilters from './components/SeveritiesFilters'
import { ModalCreateSeverity } from './components/ModalCreateSeverity'
import { coverage_scales_grid_columns } from './grid_columns/coverage_scales_grid_columns'
import { ModalCreateCoverageScale } from './components/ModalCreateCoverageScale'
import CoverageScalesFilters from './components/CoverageScalesFilters'
import { priority_levels_grid_columns } from './grid_columns/priority_levels_grid_columns'
import { ModalCreatePriorityLevel } from './components/ModalCreatePriorityLevel'
import PriorityLevelsFilters from './components/PriorityLevelsFilters'
import { severities_grid_columns } from './grid_columns/severities_grid_columns'

const CONTROL_MENSURES_KEY = 1
const FREQUENCIES_KEY = 2
const DETECTIONS_KEY = 3
const SEVERITIES_KEY = 4
const COVERAGE_SCALE_KEY = 5
const PRIORITY_LEVEL_KEY = 6

export function ProbabilityXGravity({}) {
    const navigate = useNavigate()

    const { mobile_mode } = useContext(GeneralContext)

    const [controlMesures, setControlMesures] = useState([])
    const [frequencies, setFrequencies] = useState([])
    const [detections, setDetections] = useState([])
    const [severities, setSeverities] = useState([])
    const [coverageScales, setCoverageScales] = useState([])
    const [priorityLevels, setPriorityLevels] = useState([])

    const [rowOnFocus, setRowOnFocus] = useState(null)
    const [gridLoading, setGridLoading] = useState(false)

    const [modalCreateControlMesureIsOpen, setModalCreateControlMesureIsOpen] = useState(false)
    const [modalCreateFrequencyIsOpen, setModalCreateFrequencyIsOpen] = useState(false)
    const [modalCreateDetectionIsOpen, setModalCreateDetectionIsOpen] = useState(false)
    const [modalCreateSeverityIsOpen, setModalCreateSeverityIsOpen] = useState(false)
    const [modalCreateCoverageScaleIsOpen, setModalCreateCoverageScaleIsOpen] = useState(false)
    const [modalCreatePriorityLevelIsOpen, setModalCreatePriorityLevelIsOpen] = useState(false)

    useEffect(() => {
        loadControlMesures()
    }, [])

    const loadControlMesures = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.controlMesures.list(params)

            setControlMesures(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const loadFrequencies = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.frequencies.list(params)

            setFrequencies(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const loadDetections = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.detections.list(params)

            setDetections(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const loadSeverities = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.severities.list(params)

            setSeverities(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const loadCoverageScales = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.coverageScales.list(params)

            setCoverageScales(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const loadPriorityLevels = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.priorityLevels.list(params)

            setPriorityLevels(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const tabKeysWithYourListFunction = [
        { key_code: CONTROL_MENSURES_KEY, list_function: loadControlMesures },
        { key_code: FREQUENCIES_KEY, list_function: loadFrequencies },
        { key_code: DETECTIONS_KEY, list_function: loadDetections },
        { key_code: SEVERITIES_KEY, list_function: loadSeverities },
        { key_code: COVERAGE_SCALE_KEY, list_function: loadCoverageScales },
        { key_code: PRIORITY_LEVEL_KEY, list_function: loadPriorityLevels },
    ]

    const handleOnTabChange = async key => {
        let listFunctionToCall = null
        tabKeysWithYourListFunction.forEach(e => {
            if (key === e.key_code) listFunctionToCall = e.list_function
            return
        })

        if (listFunctionToCall) {
            await listFunctionToCall()
        }
    }

    return (
        <>
            <div>
                <Tabs
                    defaultActiveKey="1"
                    onChange={handleOnTabChange}
                    items={[
                        {
                            key: CONTROL_MENSURES_KEY,
                            label: displayMessage('CONTROL_MESURES'),
                            children: (
                                <div>
                                    {modalCreateControlMesureIsOpen && (
                                        <ModalCreateControlMesure
                                            isOpen={modalCreateControlMesureIsOpen}
                                            onClose={setModalCreateControlMesureIsOpen}
                                            reloadControlMesures={loadControlMesures}
                                        />
                                    )}

                                    <ControlMesuresFilters
                                        loadControlMesures={loadControlMesures}
                                        setGridLoading={setGridLoading}
                                        setControlMesure={setControlMesures}
                                    />
                                    <SL_Grid
                                        rows={controlMesures ?? []}
                                        sortingMode={'server'}
                                        components={{
                                            Toolbar: () => (
                                                <>
                                                    <div className="flex justify-between flex-col ss:flex-row">
                                                        <CustomToolbar />
                                                        <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                            <SL_Button
                                                                className="my-1 mr-1"
                                                                disabled={!rowOnFocus}
                                                                onClick={() => navigate(`/probability-x-gravity/control-mesure/${rowOnFocus.id}`)}
                                                            >
                                                                {displayMessage('CONTROL_MESURE_DETAILS')}
                                                            </SL_Button>
                                                            {/* <SL_Button
                                                    className="my-1 mr-1"
                                                    onClick={() => setModalCreateControlMesureIsOpen(true)}
                                                >
                                                    {displayMessage('CREATE_CONTROL_MESURE')}
                                                </SL_Button> */}
                                                        </div>
                                                    </div>
                                                </>
                                            ),
                                        }}
                                        disableFilter={false}
                                        onRowClick={params => {
                                            setRowOnFocus(params.row)
                                        }}
                                        columns={control_mesures_grid_columns}
                                        className="flex-1 min-h-[61vh] max-h-[100vh]"
                                        loading={gridLoading}
                                    />
                                </div>
                            ),
                        },
                        {
                            key: FREQUENCIES_KEY,
                            label: displayMessage('FREQUENCIES'),
                            children: (
                                <>
                                    <div>
                                        {modalCreateFrequencyIsOpen && (
                                            <ModalCreateFrequency
                                                isOpen={modalCreateFrequencyIsOpen}
                                                onClose={setModalCreateFrequencyIsOpen}
                                                reloadFrequencies={loadFrequencies}
                                            />
                                        )}
                                        <FrequenciesFilters
                                            loadFrequencies={loadFrequencies}
                                            setGridLoading={setGridLoading}
                                            setFrequencies={setFrequencies}
                                        />
                                        <SL_Grid
                                            rows={frequencies ?? []}
                                            sortingMode={'server'}
                                            components={{
                                                Toolbar: () => (
                                                    <>
                                                        <div className="flex justify-between flex-row ss:flex-row">
                                                            <CustomToolbar />
                                                            <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                                <SL_Button
                                                                    className="my-1 mr-1"
                                                                    disabled={!rowOnFocus}
                                                                    onClick={() => navigate(`/probability-x-gravity/frequency/${rowOnFocus.id}`)}
                                                                >
                                                                    {displayMessage('FREQUENCY_DETAILS')}
                                                                </SL_Button>
                                                                {/* <SL_Button
                                                        className="my-1 mr-1"
                                                        onClick={() => setModalCreateFrequencyIsOpen(true)}
                                                    >
                                                        {displayMessage('CREATE_FREQUENCY')}
                                                    </SL_Button> */}
                                                            </div>
                                                        </div>
                                                    </>
                                                ),
                                            }}
                                            disableFilter={false}
                                            onRowClick={params => {
                                                setRowOnFocus(params.row)
                                            }}
                                            columns={frequencies_grid_columns}
                                            className="flex-1 min-h-[61vh] max-h-[100vh]"
                                            loading={gridLoading}
                                        />
                                    </div>
                                </>
                            ),
                        },
                        {
                            key: DETECTIONS_KEY,
                            label: displayMessage('DETECTIONS'),
                            children: (
                                <>
                                    <div>
                                        {modalCreateDetectionIsOpen && (
                                            <ModalCreateDetection
                                                isOpen={modalCreateDetectionIsOpen}
                                                onClose={setModalCreateDetectionIsOpen}
                                                reloadDetections={loadDetections}
                                            />
                                        )}
                                        <DetectionsFilters
                                            loadDetections={loadDetections}
                                            setGridLoading={setGridLoading}
                                            setDetections={setDetections}
                                        />
                                        <SL_Grid
                                            rows={detections ?? []}
                                            sortingMode={'server'}
                                            components={{
                                                Toolbar: () => (
                                                    <>
                                                        <div className="flex justify-between flex-row ss:flex-row">
                                                            <CustomToolbar />
                                                            <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                                <SL_Button
                                                                    className="my-1 mr-1"
                                                                    disabled={!rowOnFocus}
                                                                    onClick={() => navigate(`/probability-x-gravity/detection/${rowOnFocus.id}`)}
                                                                >
                                                                    {displayMessage('DETECTION_DETAILS')}
                                                                </SL_Button>
                                                                {/* <SL_Button
                                                        className="my-1 mr-1"
                                                        onClick={() => setModalCreateDetectionIsOpen(true)}
                                                    >
                                                        {displayMessage('CREATE_DETECTION')}
                                                    </SL_Button> */}
                                                            </div>
                                                        </div>
                                                    </>
                                                ),
                                            }}
                                            disableFilter={false}
                                            onRowClick={params => {
                                                setRowOnFocus(params.row)
                                            }}
                                            columns={detections_grid_columns}
                                            className="flex-1 min-h-[61vh] max-h-[100vh]"
                                            loading={gridLoading}
                                        />
                                    </div>
                                </>
                            ),
                        },
                        {
                            key: SEVERITIES_KEY,
                            label: displayMessage('SEVERITIES'),
                            children: (
                                <>
                                    <div>
                                        {modalCreateSeverityIsOpen && (
                                            <ModalCreateSeverity
                                                isOpen={modalCreateSeverityIsOpen}
                                                onClose={setModalCreateSeverityIsOpen}
                                                reloadSeverities={loadSeverities}
                                            />
                                        )}
                                        <SeveritiesFilters
                                            loadSeverities={loadSeverities}
                                            setGridLoading={setGridLoading}
                                            setSeverities={setSeverities}
                                        />
                                        <SL_Grid
                                            rows={severities ?? []}
                                            sortingMode={'server'}
                                            components={{
                                                Toolbar: () => (
                                                    <>
                                                        <div className="flex justify-between flex-row ss:flex-row">
                                                            <CustomToolbar />
                                                            <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                                <SL_Button
                                                                    className="my-1 mr-1"
                                                                    disabled={!rowOnFocus}
                                                                    onClick={() => navigate(`/probability-x-gravity/severity/${rowOnFocus.id}`)}
                                                                >
                                                                    {displayMessage('SEVERITY_DETAILS')}
                                                                </SL_Button>
                                                                {/* <SL_Button
                                                        className="my-1 mr-1"
                                                        onClick={() => setModalCreateSeverityIsOpen(true)}
                                                    >
                                                        {displayMessage('CREATE_SEVERITY')}
                                                    </SL_Button> */}
                                                            </div>
                                                        </div>
                                                    </>
                                                ),
                                            }}
                                            disableFilter={false}
                                            onRowClick={params => {
                                                setRowOnFocus(params.row)
                                            }}
                                            columns={severities_grid_columns}
                                            className="flex-1 min-h-[61vh] max-h-[100vh]"
                                            loading={gridLoading}
                                        />
                                    </div>
                                </>
                            ),
                        },
                        {
                            key: COVERAGE_SCALE_KEY,
                            label: displayMessage('COVERAGE_SCALES'),
                            children: (
                                <>
                                    <div>
                                        {modalCreateCoverageScaleIsOpen && (
                                            <ModalCreateCoverageScale
                                                isOpen={modalCreateCoverageScaleIsOpen}
                                                onClose={setModalCreateCoverageScaleIsOpen}
                                                reloadCoverageScales={loadCoverageScales}
                                            />
                                        )}
                                        <CoverageScalesFilters
                                            loadCoverageScales={loadCoverageScales}
                                            setGridLoading={setGridLoading}
                                            setCoverageScales={setCoverageScales}
                                        />
                                        <SL_Grid
                                            rows={coverageScales ?? []}
                                            sortingMode={'server'}
                                            components={{
                                                Toolbar: () => (
                                                    <>
                                                        <div className="flex justify-between flex-col ss:flex-row">
                                                            <CustomToolbar />
                                                            <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                                <SL_Button
                                                                    className="my-1 mr-1"
                                                                    disabled={!rowOnFocus}
                                                                    onClick={() => navigate(`/probability-x-gravity/coverage-scale/${rowOnFocus.id}`)}
                                                                >
                                                                    {displayMessage('COVERAGE_SCALE_DETAILS')}
                                                                </SL_Button>
                                                                {/* <SL_Button
                                                        className="my-1 mr-1"
                                                        onClick={() => setModalCreateCoverageScaleIsOpen(true)}
                                                    >
                                                        {displayMessage('CREATE_COVERAGE_SCALE')}
                                                    </SL_Button> */}
                                                            </div>
                                                        </div>
                                                    </>
                                                ),
                                            }}
                                            disableFilter={false}
                                            onRowClick={params => {
                                                setRowOnFocus(params.row)
                                            }}
                                            columns={coverage_scales_grid_columns}
                                            className="flex-1 min-h-[61vh] max-h-[100vh]"
                                            loading={gridLoading}
                                        />
                                    </div>
                                </>
                            ),
                        },
                        {
                            key: PRIORITY_LEVEL_KEY,
                            label: displayMessage('PRIORITY_LEVELS'),
                            children: (
                                <>
                                    <div>
                                        {modalCreatePriorityLevelIsOpen && (
                                            <ModalCreatePriorityLevel
                                                isOpen={modalCreatePriorityLevelIsOpen}
                                                onClose={setModalCreatePriorityLevelIsOpen}
                                                reloadPriorityLevels={loadPriorityLevels}
                                            />
                                        )}
                                        <PriorityLevelsFilters
                                            loadPriorityLevels={loadPriorityLevels}
                                            setGridLoading={setGridLoading}
                                            setPriorityLevels={setPriorityLevels}
                                        />
                                        <SL_Grid
                                            rows={priorityLevels ?? []}
                                            sortingMode={'server'}
                                            components={{
                                                Toolbar: () => (
                                                    <>
                                                        <div className="flex justify-between flex-col ss:flex-row">
                                                            <CustomToolbar />
                                                            <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                                <SL_Button
                                                                    className="my-1 mr-1"
                                                                    disabled={!rowOnFocus}
                                                                    onClick={() => navigate(`/probability-x-gravity/priority-level/${rowOnFocus.id}`)}
                                                                >
                                                                    {displayMessage('PRIORITY_LEVEL_DETAILS')}
                                                                </SL_Button>
                                                                {/* <SL_Button
                                                        className="my-1 mr-1"
                                                        onClick={() => setModalCreatePriorityLevelIsOpen(true)}
                                                    >
                                                        {displayMessage('CREATE_PRIORITY_LEVEL')}
                                                    </SL_Button> */}
                                                            </div>
                                                        </div>
                                                    </>
                                                ),
                                            }}
                                            disableFilter={false}
                                            onRowClick={params => {
                                                setRowOnFocus(params.row)
                                            }}
                                            columns={priority_levels_grid_columns}
                                            className="flex-1 min-h-[61vh] max-h-[100vh]"
                                            loading={gridLoading}
                                        />
                                    </div>
                                </>
                            ),
                        },
                    ]}
                />
            </div>
        </>
    )
}
