import { Form, Modal, Upload } from 'antd'
import { useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'

const { Dragger } = Upload

const STATUS_SUCCESS = 200

export function ModalCreateClientWorkSector({ isOpen, onClose, reloadWorkSectors, client_id }) {
    const formRef = useRef()

    const [loading, setLoading] = useState(false)

    const onSubmit = async workSector => {
        setLoading(true)
        try {
            const body = { name: workSector.name, client_id }

            const response = await api.workSectors.create(body)

            if (response.status === STATUS_SUCCESS) {
                onClose(false)
                await reloadWorkSectors()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            title={displayMessage('WORK_SECTOR_CREATION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            okText={displayMessage('FINISH')}
            maskClosable={false}
        >
            <Form onFinish={onSubmit} className="max-w-[500px] w-full" layout="vertical" requiredMark={false} ref={formRef}>
                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('FIRST_NAME')}</div>

                    <Form.Item name="name" rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]} className="mb-4">
                        <SL_Input placeholder={displayMessage('FIRST_NAME')} className={'w-full'} />
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    )
}
