import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import api from '../../services/api'
import { displayMessage } from '../../helpers/messages'
import { SL_Button } from '../../components/buttons/Button'
import { SL_Input } from '../../components/input/Input'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { EAlertTypes } from '../../enums/EAlertTypes'

export const ResetPasswordForm = () => {
    const { token } = useParams()

    const { showAlert, setShowLoading } = useContext(GeneralContext)

    const navigate = useNavigate()

    const onFinish = async values => {
        setShowLoading(true)
        try {
            await api.auth.resetPasswordForced(values, token)

            showAlert('RESET_PASSWORD_FORM_SUCCESS', EAlertTypes.SUCCESS)

            setTimeout(() => {
                navigate('/login')
            }, 500)
        } catch (e) {
            console.log(e)
        } finally {
            setShowLoading(false)
        }
    }

    return (
        <>
            <div className="text-sl-gray-50 font-bold mb-4 text-xl ss:text-2xl shadow-title">{displayMessage('CHANGE_PASSWORD_TITLE')}</div>

            <Form onFinish={onFinish} className="max-w-[400px] w-full" layout="vertical" requiredMark={false}>
                <div className="flex flex-col gap-1">
                    <div className="text-sl-gray-50 font-bold shadow-title">{displayMessage('NEW_PASSWORD_SHORT')}</div>

                    <Form.Item
                        name="newPassword"
                        rules={[
                            {
                                required: true,
                                message: displayMessage('DISPLAY_NEW_PASSWORD_SHORT'),
                            },
                        ]}
                        className="mb-4"
                    >
                        <SL_Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder={displayMessage('ENTER_NEW_PASSWORD_SHORT')}
                            className={'w-full font-bold shadow-md'}
                        />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1 mt-1">
                    <div className="text-sl-gray-50 font-bold shadow-title">{displayMessage('NEW_PASSWORD_CONFIRM')}</div>

                    <Form.Item
                        name="confirmNewPassword"
                        rules={[
                            {
                                required: true,
                                message: displayMessage('DISPLAY_NEW_PASSWORD_CONFIRM'),
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (getFieldValue('newPassword') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(displayMessage('PASSWORD_MUST_BE_EQUAL'))
                                },
                            }),
                        ]}
                        className="mb-6"
                    >
                        <SL_Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder={displayMessage('ENTER_NEW_PASSWORD_CONFIRM')}
                            className={'w-full font-bold shadow-md'}
                        />
                    </Form.Item>
                </div>

                <Form.Item hasFeedback className="mb-3 mt-2">
                    <SL_Button type="primary" className={'w-full font-bold shadow-md'} htmlType="submit">
                        {displayMessage('CONFIRM_CHANGE_PASSWORD')}
                    </SL_Button>
                </Form.Item>
            </Form>
        </>
    )
}
