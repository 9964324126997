import { useContext, useEffect, useState } from 'react'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { AuthContext } from '../../../context/AuthContext/AuthContext'
import api from '../../../services/api'
import { Alert, Drawer, Popconfirm, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { SL_Button } from '../../../components/buttons/Button'
import { EnvelopeOpen } from '@phosphor-icons/react'
import { displayMessage } from '../../../helpers/messages'
import { SL_Divider } from '../../../components/divider/Divider'
import NotificationType from './NotificationType'
import { SL_Select } from '../../../components/selects/Select'

const NotificationDrawer = ({ onClose, handleWithChanges }) => {
    const { setShowLoading } = useContext(GeneralContext)

    const [notifications, setNotifications] = useState([])
    const [offset, setOffset] = useState(0)
    const [count, setCount] = useState()
    const [notificationsTypes, setNotificationsTypes] = useState([])
    const [showOnlyNotRead, setShowOnlyNotRead] = useState(true)
    const [selectedClient, setSelectedClient] = useState(null)
    const [clients, setClients] = useState([])
    const limitRequisitions = 5
    const showMore = count && count > limitRequisitions && count > offset + limitRequisitions

    const { loggedUser } = useContext(AuthContext)

    useEffect(() => {
        loadNotifications()
    }, [showOnlyNotRead, selectedClient])

    useEffect(() => {
        loadNotifications()
        loadClients()
    }, [])

    const loadNotifications = async (offset = 0) => {
        setOffset(offset)
        setShowLoading(true)
        try {
            const filters = {
                user_id: loggedUser?.id,
                limit: limitRequisitions,
                offset: offset,
                client_id: selectedClient,
            }
            if (showOnlyNotRead) {
                filters.not_readed = true
            }
            const { data } = await api.notifications.listAll(filters)
            const { data: types } = await api.enums.find('ENotificationTypes')
            setCount(data.count)
            setNotificationsTypes(types)
            if (offset === 0) {
                setNotifications(data.rows)
            } else {
                setNotifications(notifications.concat(data.rows))
            }
        } finally {
            setShowLoading(false)
        }
    }

    const onClickReadAllNotifications = async () => {
        try {
            setShowLoading(true)

            await api.notifications.readAll(loggedUser?.id)
            loadNotifications()
            handleWithChanges()
        } finally {
            setShowLoading(false)
        }
    }

    const loadClients = async params => {
        const { data } = await api.client.list(params)
        setClients(data)
    }

    const handleChangeClient = value => {
        setSelectedClient(value)
    }

    return (
        <>
            <Drawer
                title={
                    <div className="flex justify-between items-center">
                        <div>{displayMessage('NOTIFICATIONS')}</div>
                        <Tooltip title={displayMessage('MARK_ALL_AS_READ')} placement="left">
                            <Popconfirm
                                placement="topRight"
                                title={displayMessage('MARK_ALL_AS_READ')}
                                description={<div className="w-[300px]">{displayMessage('INFO_MARK_ALL_AS_READ')}</div>}
                                onConfirm={onClickReadAllNotifications}
                                okText={displayMessage('YES')}
                                icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                            >
                                <SL_Button
                                    type=""
                                    shape="circle"
                                    icon={<EnvelopeOpen size={28} className="text-primary-900 flex items-center" />}
                                    className="flex items-center justify-center min-h-[40px] text-base text-primary-900"
                                />
                            </Popconfirm>
                        </Tooltip>
                    </div>
                }
                placement="right"
                onClose={() => onClose()}
                open={true}
            >
                <p className=" mb-2">{displayMessage('FILTER_NOTIFICATIONS')}</p>
                <SL_Select
                    options={[{ label: 'Todas', value: null }, ...clients.map(e => ({ label: e.name, value: e.id }))]}
                    onChange={value => handleChangeClient(value)}
                    placeholder={displayMessage('SELECT_ONE_CLIENT')}
                    value={selectedClient}
                    allowClear
                    className={`w-full mb-4`}
                />
                <SL_Button
                    onClick={() => setShowOnlyNotRead(!showOnlyNotRead)}
                    className="w-full"
                    style={{
                        boxShadow: '2px 4px 15px -9px #000000',
                    }}
                >
                    {showOnlyNotRead ? displayMessage('SHOW_ALL_NOTIFICATIONS') : displayMessage('SHOW_ONLY_NOT_READ')}
                </SL_Button>
                <SL_Divider />

                {count > 0 ? (
                    <>
                        <div className="flex flex-col gap-y-2">
                            {notifications.length > 0 &&
                                notifications?.map(notification => (
                                    <NotificationType
                                        key={notification.id}
                                        notification={notification}
                                        types={notificationsTypes}
                                        handleWithChanges={handleWithChanges}
                                        onClose={() => onClose()}
                                    />
                                ))}
                        </div>
                        <SL_Divider />
                        <SL_Button
                            className="w-full"
                            style={{
                                boxShadow: '2px 4px 15px -9px #000000',
                            }}
                            disabled={!showMore}
                            onClick={() => loadNotifications(offset + limitRequisitions)}
                        >
                            {displayMessage('SHOW_MORE')}
                        </SL_Button>
                    </>
                ) : (
                    <Alert description={displayMessage('INFO_NO_NOTIFICATIONS')} type="info" />
                )}
            </Drawer>
        </>
    )
}

export default NotificationDrawer
