import { notification } from 'antd'
import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { EAlertTypes } from '../../enums/EAlertTypes'
import { displayMessage } from '../../helpers/messages'
import { axiosInstance } from '../../services/axiosInstances'
import { AuthContext } from '../AuthContext/AuthContext'
import { GeneralContext } from './GeneralContext'

// Mantem uma variável atualizada com as dimensoes da tela
const useWindowSize = () => {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight])

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight])
        }

        window.addEventListener('resize', updateSize)
        updateSize()
        return () => window.removeEventListener('resize', updateSize)
    }, [])

    return size
}

export function GeneralContextProvider({ children }) {
    const { setLoggedUser } = useContext(AuthContext)

    const [windowWidth, windowHeight] = useWindowSize()
    const [show_loading, setShowLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification()

    useEffect(() => {
        setAxiosInterceptorConfig()
    }, [])

    function setAxiosInterceptorConfig() {
        axiosInstance.interceptors.response.use(
            function (rs) {
                // setShowLoading(false)
                return rs
            },
            function (err) {
                if (err?.response?.data?.redirect_to_login) {
                    setLoggedUser(null)
                } else if (err?.response?.data?.error) {
                    showAlert(err?.response?.data?.error)
                }

                // setShowLoading(false)
                return Promise.reject(err)
            }
        )
    }

    const showAlert = (descriptionMessage = null, type = EAlertTypes.ERROR, title = null, duration = 4) => {
        const description = descriptionMessage
            ? displayMessage(descriptionMessage)?.length > 0
                ? displayMessage(descriptionMessage)
                : descriptionMessage
            : ''
        const titleMsg = title ?? type.defaultTitle

        api[type.param]({
            description,
            duration,
            message: titleMsg,
        })
    }

    const mobile_mode = windowWidth <= 700 //px
    const breakPoints = {
        xl: windowWidth <= 1250, //px
        lg: windowWidth <= 1000, //px
        md: windowWidth <= 700, //px
        sm: windowWidth <= 500, //px
    }

    return (
        <>
            {contextHolder}

            <GeneralContext.Provider
                value={{
                    show_loading,
                    setShowLoading,
                    windowWidth,
                    windowHeight,
                    mobile_mode,
                    breakPoints,
                    showAlert,
                }}
            >
                {children}
            </GeneralContext.Provider>
        </>
    )
}
