import { DateTime } from 'luxon'
import { findUserTypeByCode } from '../../../helpers/helper'
import { displayMessage } from '../../../helpers/messages'

export const client_users_grid_columns = [
    {
        field: 'first_name',
        headerName: displayMessage('FIRST_NAME'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.first_name) return row.first_name
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'last_name',
        headerName: displayMessage('LAST_NAME'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.last_name) return row.last_name
            return displayMessage('EMPTY_01')
        },
    },

    {
        field: 'email',
        headerName: displayMessage('EMAIL'),
        type: 'string',
        width: 250,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.email) return row.email
            return displayMessage('EMPTY_01')
        },
    },
    // {
    //     field: 'WorkSector',
    //     headerName: displayMessage('WORK_SECTOR'),
    //     type: 'string',
    //     width: 200,
    //     valueFormatter: params => {
    //         const row = params.api.getRow(params.id)
    //         if (row.WorkSector) return row.WorkSector.name
    //         return displayMessage('EMPTY_01')
    //     },
    //     valueGetter: params => {
    //         const row = params.api.getRow(params.id)
    //         if (row.WorkSector) return row.WorkSector.name
    //         return displayMessage('EMPTY_01')
    //     }
    // },
    {
        field: 'type',
        headerName: displayMessage('USER_TYPE'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.type) {
                const userType = findUserTypeByCode(row.type)
                return userType.description
            }
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.type) {
                const userType = findUserTypeByCode(row.type)
                return userType.description
            }
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'is_active',
        headerName: 'Status de ativação',
        width: 150,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            return row.is_active ? displayMessage('ACTIVE') : displayMessage('INACTIVE')
        },
        type: 'singleSelect',
        valueOptions: [
            { value: true, label: 'Ativo' },
            { value: false, label: 'Inativo' },
        ],
    },

    {
        field: 'created_by_user_id',
        headerName: displayMessage('CREATED_BY'),
        type: 'string',
        width: 100,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.user_who_created) return `${row.user_who_created.first_name} ${row.user_who_created.last_name}`
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const row = params.api.getRow(params.id)
            if (row.user_who_created) return `${row.user_who_created.first_name} ${row.user_who_created.last_name}`
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'createdAt',
        headerName: displayMessage('CREATED_AT'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.createdAt) return DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
]
