import { DateTime } from 'luxon'
import { displayMessage } from '../../../helpers/messages'
import EUserTypes from '../../../enums/EUserTypes'
import { findClientTypeByCode, formatCnpj } from '../../../helpers/helper'

export const clients_grid_columns = [
    {
        field: 'name',
        headerName: displayMessage('CLIENT_NAME'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.name) return row.name
            return displayMessage('EMPTY_01')
        },
    },

    {
        field: 'cnpj',
        headerName: displayMessage('COMPANY_DOCUMENT'),
        type: 'string',
        width: 150,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.cnpj) return formatCnpj(row.cnpj)
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'cnae',
        headerName: displayMessage('CNAE'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.cnae) return row.cnae
            return displayMessage('EMPTY_01')
        },
    },
    // {
    //     field: 'type',
    //     headerName: displayMessage('CLIENT_TYPE'),
    //     type: 'string',
    //     width: 300,
    //     valueFormatter: params => {
    //         const row = params.api.getRow(params.id)
    //         const clientType = findClientTypeByCode(row.type)
    //         return clientType.description
    //     },
    //     valueGetter: params => {
    //         const row = params.api.getRow(params.id)
    //         const clientType = findClientTypeByCode(row.type)
    //         return clientType.description
    //     }
    // },
    {
        field: 'is_active',
        headerName: displayMessage('ACTIVATION_STATUS'),
        type: 'string',
        width: 150,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            return row.is_active ? displayMessage('ACTIVE') : displayMessage('INACTIVE')
        },
    },
    {
        field: 'inactivation_date',
        headerName: displayMessage('INACTIVATION_DATE'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.inactivation_date) return DateTime.fromISO(row.inactivation_date).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'createdAt',
        headerName: displayMessage('CREATED_AT'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.createdAt) return DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')
        },
    },
]
