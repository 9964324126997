import React, { useContext, useState } from 'react'
import { Form, Tooltip } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { SL_Button } from '../../../components/buttons/Button'
import { SL_Input } from '../../../components/input/Input'
import { SL_Select } from '../../../components/selects/Select'
import { Eraser } from '@phosphor-icons/react'
import { CnpjMaskedInput } from '../../../helpers/iMask'
import EClientTypes from '../../../enums/EClientTypes'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'

export default function ClientFilters(props) {
    const { loadClients, setGridLoading, setClients } = props

    const { mobile_mode } = useContext(GeneralContext)

    const [name, setName] = useState(null)
    const [cnpj, setCnpj] = useState(null)
    const [cnae, setCnae] = useState(null)
    const [email, setEmail] = useState(null)
    const [clientType, setClientType] = useState(null)
    const [statusActivation, setStatusActivation] = useState(null)

    const [form] = Form.useForm()

    const clearFilters = async () => {
        setName(null)
        setCnpj(null)
        setEmail(null)
        setClientType(null)
        setStatusActivation(null)
        form.resetFields()
        await loadClients()
    }

    const handleClientTypeChange = value => {
        setClientType(value)
    }

    const handleActivationStatusChange = value => {
        setStatusActivation(value)
    }

    const handleOnFilter = async () => {
        setGridLoading(true)
        try {
            const params = {
                name,
                cnpj,
                cnae,
                email,
                clientType,
                statusActivation,
            }

            const { data } = await loadClients(params)

            setClients(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    return (
        <>
            <div className="flex flex-col rounded-md p-2 border border-solid border-[#d9d9d9]">
                <span className="self-center font-bold text-lg flex-1 text-center">{displayMessage('SEARCH_FILTERS')}</span>
                <div className="flex flex-col ss:flex-row gap-y-2 gap-x-2 justify-between ">
                    <div>
                        <Form
                            onFinish={props.onFinish}
                            layout="vertical"
                            form={form}
                            ref={props.formRef}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleOnFilter()
                                }
                            }}
                            className="flex flex-col lg:flex-row gap-x-2 gap-y-2 md:flex-wrap"
                        >
                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('CLIENT_NAME')}
                                    requiredMark=""
                                    name="name"
                                    className={`m-0 ${mobile_mode ? 'w-full' : ''}`}
                                >
                                    <SL_Input placeholder={displayMessage('CLIENT_NAME')} onChange={e => setName(e.target.value)} />
                                </Form.Item>
                            </div>

                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('COMPANY_DOCUMENT')}
                                    requiredMark=""
                                    name="cnpj"
                                    className={`m-0 ${mobile_mode ? 'w-full' : ''}`}
                                >
                                    {CnpjMaskedInput({
                                        placeholder: displayMessage('COMPANY_DOCUMENT'),
                                        onChange: e => {
                                            setCnpj(e.unmaskedValue)
                                        },
                                    })}
                                </Form.Item>
                            </div>

                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('CNAE')}
                                    requiredMark=""
                                    name="cnae"
                                    className={`m-0 ${mobile_mode ? 'w-full' : ''}`}
                                >
                                    <SL_Input placeholder={displayMessage('CNAE')} onChange={e => setCnae(e.target.value)} />
                                </Form.Item>
                            </div>

                            {/* <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('CLIENT_TYPE')}
                                    requiredMark=""
                                    name="client_type"
                                    className={`m-0 ${mobile_mode ? 'w-full' : ''}`}
                                >
                                    <SL_Select
                                        maxTagCount="responsive"
                                        className="mb-1 h-[28px] w-72 rounded-sm"
                                        listItemHeight={100}
                                        title={displayMessage('CLIENT_TYPE')}
                                        placeholder={displayMessage('CLIENT_TYPE')}
                                        onChange={handleClientTypeChange}
                                        value={clientType}
                                        options={Object.values(EClientTypes).map(e => ({ label: e.description, value: e.code }))}
                                        allowClear
                                    />
                                </Form.Item>
                            </div> */}

                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('ACTIVATION_STATUS')}
                                    requiredMark=""
                                    name="statusActivation"
                                    className={`m-0 ${mobile_mode ? 'w-full' : ''}`}
                                >
                                    <SL_Select
                                        maxTagCount="responsive"
                                        className="mb-1 h-[28px] rounded-sm"
                                        listItemHeight={100}
                                        title={displayMessage('ACTIVATION_STATUS')}
                                        placeholder={displayMessage('ACTIVATION_STATUS')}
                                        onChange={handleActivationStatusChange}
                                        value={clientType}
                                        options={[
                                            { label: 'Ativo', value: 1 },
                                            { label: 'Inativo', value: 0 },
                                        ]}
                                        allowClear
                                    />
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="flex justify-self-end">
                        <div className={`flex ${mobile_mode ? 'w-full justify-between' : ''} items-end pb-1`}>
                            <Tooltip placement="topLeft" title={displayMessage('CLEAN_FILTERS')}>
                                <SL_Button
                                    className="md-mx-0 rounded-r-none bg-white border-solid border-1 border-primary-600 text-primary-600"
                                    onClick={() => clearFilters()}
                                >
                                    <Eraser size={20} color="black" />
                                </SL_Button>
                            </Tooltip>

                            <SL_Button className={`md-mx-0 rounded ${mobile_mode ? 'w-full ml-2' : ''}`} onClick={handleOnFilter}>
                                {displayMessage('TO_FILTER')}
                            </SL_Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
