import React, { useContext, useEffect, useState } from 'react'
import { Form, Tooltip } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { SL_Button } from '../../../components/buttons/Button'
import { SL_Input } from '../../../components/input/Input'
import { SL_Select } from '../../../components/selects/Select'
import { Eraser } from '@phosphor-icons/react'
import { ModalCreateClient } from './ModalCreateClient'
import api from '../../../services/api'
import EUserTypes from '../../../enums/EUserTypes'
import { useParams } from 'react-router-dom'
import { SL_Date } from '../../../components/date/Date'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'

export default function ClientUsersFilters(props) {
    const { loadAssociatedClientsUsers, loadClientsUsers, setGridLoading, setClientsUsers } = props

    const { clientId } = useParams()
    const { mobile_mode } = useContext(GeneralContext)

    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [userType, setUserType] = useState(null)
    const [createdBy, setCreatedBy] = useState(null)
    const [editedBy, setEditedBy] = useState(null)
    const [statusActivation, setStatusActivation] = useState(null)
    // const [createdAt, setCreatedAt] = useState(null)
    const [workSectorId, setWorkSectorId] = useState(null)
    const [form] = Form.useForm()

    // const [modalCreateClientIsOpen, setModalCreateClientIsOpen] = useState(false)

    const [allWorkSectors, setAllWorkSectors] = useState(null)

    useEffect(() => {
        loadWorkSectors()
    }, [])

    const loadWorkSectors = async () => {
        setGridLoading(true)
        try {
            const { data } = await api.workSectors.list({ client_id: clientId })

            setAllWorkSectors(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const clearFilters = async () => {
        setName(null)
        setEmail(null)
        setUserType(null)
        setStatusActivation(null)
        setCreatedBy(null)
        setEditedBy(null)
        // setCreatedAt(null)
        setWorkSectorId(null)
        form.resetFields()
        await loadClientsUsers()
        await loadAssociatedClientsUsers()
    }

    const handleUserTypeChange = value => {
        setUserType(value)
    }

    const handleActivationStatusChange = value => {
        setStatusActivation(value)
    }

    const handleWorkSectorChange = value => {
        setWorkSectorId(value)
    }

    // const handleCreatedAtChange = value => {
    //     setCreatedAt(value)
    // }

    const handleOnFilter = async () => {
        setGridLoading(true)
        try {
            const params = {
                name,
                email,
                userType,
                statusActivation,
                createdBy,
                editedBy,
                work_sector_id: workSectorId,
                // created_at: createdAt,
            }

            // const { data } = await loadClientsUsers(params)
            const { data } = await loadAssociatedClientsUsers(params)

            setClientsUsers(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    return (
        <>
            {/* {modalCreateClientIsOpen && (// trocar o reload para loadAssociatedClientsUsers?
                <ModalCreateClient isOpen={modalCreateClientIsOpen} onClose={setModalCreateClientIsOpen} reloadClients={loadClientsUsers} />
            )} */}

            <div className="flex flex-col rounded-md p-2 border border-solid border-[#d9d9d9]">
                <span className="self-center font-bold text-lg flex-1 text-center">{displayMessage('SEARCH_FILTERS')}</span>
                <div className="flex flex-col ss:flex-row gap-y-2 gap-x-2 justify-between">
                    <div>
                        <Form
                            onFinish={props.onFinish}
                            layout="vertical"
                            form={form}
                            ref={props.formRef}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleOnFilter()
                                }
                            }}
                            className="flex flex-col lg:flex-row gap-x-2 gap-y-2 md:flex-wrap"
                        >
                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('FIRST_NAME')}
                                    requiredMark=""
                                    name="first_name"
                                    className={`m-0 ${mobile_mode && 'w-full'}`}
                                >
                                    <SL_Input placeholder={displayMessage('FIRST_NAME')} onChange={e => setName(e.target.value)} />
                                </Form.Item>
                            </div>

                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item label={displayMessage('EMAIL')} requiredMark="" name="email" className={`m-0 ${mobile_mode && 'w-full'}`}>
                                    <SL_Input placeholder={displayMessage('EMAIL')} onChange={e => setEmail(e.target.value)} />
                                </Form.Item>
                            </div>

                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('USER_TYPE')}
                                    requiredMark=""
                                    name="user_type"
                                    className={`m-0 ${mobile_mode && 'w-full'}`}
                                >
                                    <SL_Select
                                        maxTagCount="responsive"
                                        className="mb-1 h-[28px] rounded-sm"
                                        listItemHeight={100}
                                        title={displayMessage('USER_TYPE')}
                                        placeholder={displayMessage('USER_TYPE')}
                                        onChange={handleUserTypeChange}
                                        value={userType}
                                        options={Object.values(EUserTypes).map(e => ({ label: e.description, value: e.code }))}
                                        allowClear
                                    />
                                </Form.Item>
                            </div>

                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('ACTIVATION_STATUS')}
                                    requiredMark=""
                                    name="statusActivation"
                                    className={`m-0 ${mobile_mode && 'w-full'}`}
                                >
                                    <SL_Select
                                        maxTagCount="responsive"
                                        className="mb-1 h-[28px] rounded-sm"
                                        listItemHeight={100}
                                        title={displayMessage('ACTIVATION_STATUS')}
                                        placeholder={displayMessage('ACTIVATION_STATUS')}
                                        onChange={handleActivationStatusChange}
                                        value={userType}
                                        options={[
                                            { label: 'Ativo', value: 1 },
                                            { label: 'Inativo', value: 0 },
                                        ]}
                                        allowClear
                                    />
                                </Form.Item>
                            </div>
                            {/* <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('WORK_SECTOR')}
                                    requiredMark=""
                                    name="work_sector_id"
                                    className={`m-0 ${mobile_mode ? 'w-full' : 'w-52'}`}
                                >
                                    <SL_Select
                                        maxTagCount="responsive"
                                        className="mb-1 h-[28px] rounded-sm"
                                        listItemHeight={100}
                                        title={displayMessage('WORK_SECTOR')}
                                        placeholder={displayMessage('WORK_SECTOR')}
                                        onChange={handleWorkSectorChange}
                                        value={workSectorId}
                                        options={allWorkSectors?.map(e => ({ label: e.name, value: e.id })) || []}
                                        allowClear
                                    />
                                </Form.Item>
                            </div> */}

                            {/* <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('CREATED_AT')}
                                    requiredMark=""
                                    name="created_at"
                                    className={`m-0 ${mobile_mode && 'w-full'}`}
                                >
                                    <SL_Date style={{ width: mobile_mode ? '100%' : 'auto' }} value={createdAt} format={'DD/MM/YYYY'} onChange={handleCreatedAtChange} />
                                </Form.Item>
                            </div> */}
                        </Form>
                    </div>
                    <div className="flex justify-self-end">
                        <div className={`flex ${mobile_mode ? 'w-full justify-between' : ''} items-end pb-1`}>
                            <Tooltip placement="topLeft" title={displayMessage('CLEAN_FILTERS')}>
                                <SL_Button
                                    className="md-mx-0 rounded-r-none bg-white border-solid border-1 border-primary-600 text-primary-600"
                                    onClick={() => clearFilters()}
                                >
                                    <Eraser size={20} color="black" />
                                </SL_Button>
                            </Tooltip>

                            <SL_Button className={`md-mx-0 rounded ${mobile_mode ? 'w-full ml-2' : ''}`} onClick={handleOnFilter}>
                                {displayMessage('TO_FILTER')}
                            </SL_Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
