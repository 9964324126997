import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext/AuthContext'
import { Navigate } from 'react-router-dom'

export const PermissionedRoute = ({ routePermissions = [], children }) => {
    const { loggedUser } = useContext(AuthContext)

    const handleOnValidatePermissions = () => {
        return loggedUser.type == routePermissions.code
    }

    if (children && handleOnValidatePermissions()) {
        return children
    }

    return <Navigate to={'/'} />
}
