import { Tabs } from 'antd'
import { displayMessage } from '../../helpers/messages'
import { CreateReport } from './components/CreateReport/CreateReport'
import { useContext, useEffect, useState } from 'react'
import api from '../../services/api'
import { SL_Grid } from '../../components/grid/SL_Grid'
import CustomToolbar from '../../components/grid/CustomToolbar'
import { SL_Button } from '../../components/buttons/Button'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { reports_grid_columns } from './grid_columns/reports_grid_columns'
import { useNavigate } from 'react-router-dom'
import { ModalConfirmationFinalizateReport } from './components/ModalConfirmationFinalizateReport'
import { DownloadOutlined } from '@ant-design/icons'
import { FooterTable } from '../../components/grid/FooterTable'
import { AuthContext } from '../../context/AuthContext/AuthContext'

const LIST_REPORTS_KEY = 1
const CREATE_REPORT_KEY = 2
const rowsPerPage = 40
const initialOffset = 0

export function Reports({}) {
    const { mobile_mode } = useContext(GeneralContext)
    const { loggedUser } = useContext(AuthContext)

    const navigate = useNavigate()

    const [reports, setReports] = useState([])

    const [gridLoading, setGridLoading] = useState(false)

    const [rowOnFocus, setRowOnFocus] = useState(null)

    const [activeTab, setActiveTab] = useState(LIST_REPORTS_KEY)

    const [modalConfirmationFinalizateReportIsOpen, setModalConfirmationFinalizateReportIsOpen] = useState(false)

    useEffect(() => {
        loadReports()
    }, [])

    const [loading, setLoading] = useState(false)

    const loadReports = async params => {
        setGridLoading(true)
        try {
            params = { ...params }
            const { data: reports } = await api.reports.list(params)
            setReports(reports)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const handleTabChange = async key => {
        setActiveTab(key)
    }

    const changeTabToList = () => {
        setActiveTab(LIST_REPORTS_KEY)
    }

    const generatePdf = async reportId => {
        setLoading(true)
        try {
            const pdfName = await api.reports.generate_pdf({ id: reportId, follow_up: false })
            const response = await api.reports.open_pdf(pdfName.data, { responseType: 'blob' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(response.data)
            link.download = `Relatório_${reportId + '_' + pdfName.data}.pdf`
            link.click()
            const parent = link.parentNode
            if (link && parent) {
                parent.removeChild(link)
            }

            alert(PDF_DOWNLOADED_SUCCESS, 'success')
            console.log(reportId)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex flex-col w-full h-[100vh]">
            <Tabs
                destroyInactiveTabPane
                defaultActiveKey={LIST_REPORTS_KEY}
                activeKey={activeTab}
                onChange={handleTabChange}
                items={[
                    {
                        key: LIST_REPORTS_KEY,
                        label: displayMessage('REPORTS'),
                        children: (
                            <div className={`flex flex-col ${mobile_mode ? 'h-[59vh]' : 'md:h-[65vh] xl:h-[77vh]'} w-full`}>
                                {modalConfirmationFinalizateReportIsOpen && (
                                    <ModalConfirmationFinalizateReport
                                        isOpen={modalConfirmationFinalizateReportIsOpen}
                                        onClose={setModalConfirmationFinalizateReportIsOpen}
                                        reloadReports={loadReports}
                                        reportId={rowOnFocus.id}
                                    />
                                )}

                                <SL_Grid
                                    rows={reports}
                                    pageSize={40}
                                    rowsPerPageOptions={[40]}
                                    components={{
                                        Toolbar: () => (
                                            <>
                                                <div className="flex justify-between flex-row ss:flex-row">
                                                    <CustomToolbar />
                                                    <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                        {/* {loggedUser.type == 2 && */}
                                                        <SL_Button
                                                            className="my-1 mr-1"
                                                            danger
                                                            disabled={!rowOnFocus || rowOnFocus?.finalization_date !== null}
                                                            onClick={() => setModalConfirmationFinalizateReportIsOpen(true)}
                                                        >
                                                            {displayMessage('FINALIZATE_REPORT')}
                                                        </SL_Button>
                                                        {/* } */}
                                                        <SL_Button
                                                            className="my-1 mr-1"
                                                            disabled={!rowOnFocus}
                                                            onClick={() => navigate(`/reports/${rowOnFocus.id}`)}
                                                        >
                                                            {displayMessage('REPORT_DETAILS')}
                                                        </SL_Button>
                                                        <SL_Button
                                                            className="my-1 mr-1"
                                                            disabled={!rowOnFocus}
                                                            loading={loading}
                                                            onClick={() => generatePdf(rowOnFocus.id)}
                                                        >
                                                            <DownloadOutlined />
                                                            {displayMessage('PDF')}
                                                        </SL_Button>
                                                    </div>
                                                </div>
                                            </>
                                        ),
                                    }}
                                    disableFilter={false}
                                    onRowClick={params => {
                                        setRowOnFocus(params.row)
                                    }}
                                    columns={reports_grid_columns}
                                    className="flex-1 h-[600px]"
                                    loading={gridLoading}
                                />
                            </div>
                        ),
                        style: { height: '100%' },
                    },
                    {
                        key: CREATE_REPORT_KEY,
                        label: displayMessage('CREATE_REPORT'),
                        children: <CreateReport changeTab={changeTabToList} reloadReports={loadReports} />,
                    },
                ]}
            />
        </div>
    )
}
