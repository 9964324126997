import { Form, Modal, Upload } from 'antd'
import { useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import { CnaeMaskedInput, CnpjMaskedInput } from '../../../helpers/iMask'
import { SL_Select } from '../../../components/selects/Select'
import EClientTypes from '../../../enums/EClientTypes'
import EActivationStatus from '../../../enums/EActivationStatus'
import { extractNumbersFromString } from '../../../helpers/helper'

const STATUS_SUCCESS = 200

export function ModalCreateClient({ isOpen, onClose, reloadClients }) {
    const formRef = useRef()

    const [loading, setLoading] = useState(false)
    const [userType, setUserType] = useState(null)
    const [logoImage, setLogoImage] = useState([])
    const [logoHasError, setLogoHasError] = useState(false)

    const handleUserTypeChange = value => {
        setUserType(value)
    }

    const onSubmit = async client => {
        if (logoImage.length == 0) {
            setLogoHasError(true)
            return
        }

        setLogoHasError(false)
        setLoading(true)
        try {
            const formattedClientData = formatClient(client)

            const formData = new FormData()

            formData.append('client_data', JSON.stringify(formattedClientData))

            const file = new File([logoImage[0].originFileObj], logoImage[0].name, { type: logoImage[0].type })

            formData.append('attachments', file)

            const response = await api.client.create(formData)

            if (response.status === STATUS_SUCCESS) {
                onClose(false)
                await reloadClients()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const formatClient = client => {
        client.cnpj = extractNumbersFromString(client.cnpj)
        client.cnae_number = extractNumbersFromString(client.cnae_number)

        return client
    }

    const handleFileOnChange = ({ fileList: newFileList }) => {
        setLogoImage(newFileList)
    }

    const handleOnFilePreview = async file => {
        let src = file.url

        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()

                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }

        const image = new Image()
        image.src = src

        const imgWindow = window.open(src)
        imgWindow?.document.write(image.outerHTML)
    }

    return (
        <Modal
            title={displayMessage('CLIENT_CREATION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            okText={displayMessage('FINISH')}
            maskClosable={false}
        >
            <Form onFinish={onSubmit} className="max-w-[500px] w-full" layout="vertical" requiredMark={false} ref={formRef}>
                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('CLIENT_NAME')}</div>

                    <Form.Item name="name" rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]} className="mb-4">
                        <SL_Input placeholder={displayMessage('CLIENT_NAME')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('COMPANY_DOCUMENT')}</div>

                    <Form.Item name="cnpj" rules={[{ required: true, message: displayMessage('DISPLAY_CNPJ') }]} className="mb-6">
                        {CnpjMaskedInput({
                            placeholder: displayMessage('COMPANY_DOCUMENT'),
                        })}
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('CNAE_NUMBER')}</div>

                    <Form.Item name="cnae_number" rules={[{ required: true, message: displayMessage('DISPLAY_CNAE_NUMBER') }]} className="mb-6">
                        {CnaeMaskedInput({
                            placeholder: displayMessage('CNAE_NUMBER'),
                        })}
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('CNAE')}</div>

                    <Form.Item name="cnae" rules={[{ required: true, message: displayMessage('DISPLAY_CNAE') }]} className="mb-6">
                        <SL_Input placeholder={displayMessage('CNAE')} className={'w-full'} />
                    </Form.Item>
                </div>

                {/* <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('CLIENT_TYPE')}</div>

                    <Form.Item name="type" rules={[{ required: true, message: displayMessage('DISPLAY_CLIENT_TYPE') }]} className="mb-6">
                        <SL_Select
                            maxTagCount="responsive"
                            className="mb-1 h-[28px] rounded-sm"
                            listItemHeight={100}
                            title={displayMessage('CLIENT_TYPE')}
                            placeholder={displayMessage('CLIENT_TYPE')}
                            onChange={handleUserTypeChange}
                            value={userType}
                            options={Object.values(EClientTypes).map(e => ({ label: e.description, value: e.code }))}
                            allowClear
                        />
                    </Form.Item>
                </div> */}

                <div className="flex flex-col gap-1 mt-1 ml-6">
                    <div className="">{displayMessage('CLIENT_LOGO')}</div>
                    <Upload
                        beforeUpload={() => false}
                        listType="picture-card"
                        fileList={logoImage}
                        onChange={handleFileOnChange}
                        onPreview={handleOnFilePreview}
                    >
                        {logoImage.length < 1 && '+'}
                    </Upload>
                </div>
                <div className="text-xs mt-2 text-center w-40">
                    <p>{displayMessage('RECOMMENDATION_DIMENSIONS')}</p>
                    <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                </div>
                {logoHasError && <p style={{ color: 'red' }}>Informe a logo do cliente</p>}
            </Form>
        </Modal>
    )
}

//ver qual o tamanho no relatório
