import { Checkbox, Form, Switch, Tabs } from 'antd'
import { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../services/api'
import { SL_ClipLoader } from '../../components/loading/ClipLoader'
import { displayMessage } from '../../helpers/messages'
import { SL_Input } from '../../components/input/Input'
import { SL_Select } from '../../components/selects/Select'
import { SL_Button } from '../../components/buttons/Button'
import { CpfMaskedInput, PhoneMaskedInput } from '../../helpers/iMask'
import EUserTypes from '../../enums/EUserTypes'
import EActivationStatus from '../../enums/EActivationStatus'
import styles from '../../services/styles'
import { AuthContext } from '../../context/AuthContext/AuthContext'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export function ClientUser({}) {
    const { clientUserId } = useParams()
    const { loggedUser } = useContext(AuthContext)
    const { mobile_mode } = useContext(GeneralContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { clientId } = useParams()

    const formRef = useRef()

    const [user, setUser] = useState(null)
    const [clientAddress, setClientAddress] = useState(null)
    const [workSectors, setWorkSectors] = useState(null)
    const [workSectorId, setWorkSectorId] = useState(null)
    const [allAddressesChecked, setAllAddressesChecked] = useState(false)
    const [selectedAddresses, setSelectedAddresses] = useState([])
    const [shouldHaveAllAddresses, setShouldHaveAllAddresses] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const [isActive, setIsActive] = useState(null)

    const STATUS_SUCCESS = 200

    useEffect(() => {
        loadClientUser()
        loadClientAddresses()
        loadWorkSectors()
        setIsEditing(false)
    }, [])

    useEffect(() => {
        if (user && clientAddress) {
            const userAddresses = clientAddress.filter(address => isAddressChecked(address)).map(address => address.id)
            setSelectedAddresses(userAddresses)
        }
    }, [user, clientAddress])

    useEffect(() => {
        if (user && user.ClientUserAssociations) {
            setShouldHaveAllAddresses(user.ClientUserAssociations[0].should_have_all_addresses)
        }
    }, [user])

    const loadClientUser = async () => {
        setLoading(true)
        try {
            const { data } = await api.clientUsers.find(clientUserId)
            setUser(data)
            setIsActive(data.is_active)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const loadClientAddresses = async params => {
        try {
            const auxParams = { client_id: clientId, ...params }

            const { data } = await api.addresses.list(auxParams)
            const ActiveAddresses = data.filter(e => e.deletedAt == null)
            setClientAddress(ActiveAddresses)
        } catch (error) {
            console.log(error)
        }
    }

    const loadWorkSectors = async params => {
        try {
            const { data } = await api.workSectors.list({ client_id: clientId, ...params })

            setWorkSectors(data)
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = async user => {
        setLoading(true)
        try {
            const userToSubmit = {
                client_id: clientId,
                client_user_id: clientUserId,
                should_have_all_addresses: user.all_addresses ?? false,
                addresses: allAddressesChecked ? clientAddress.map(address => address.id) : selectedAddresses,
                work_sector: user.work_sector_id,
                created_by_user_id: loggedUser.id,
                client_user_type: user?.type === 'Regular' ? 2 : 1,
            }
            const response = await api.clientUsersAssocition.update(clientId, userToSubmit)
            if (response.status === STATUS_SUCCESS) {
                await loadClientUser()
                const currentPath = location.pathname + location.search
                if (currentPath.endsWith('?client')) {
                    navigate(`/clients/${clientId}?tab=3`)
                } else {
                    navigate(-1)
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleWorkSectorChange = value => {
        setWorkSectorId(value)
    }

    const isUserWorkSector = () => {
        if (user?.type === 1) return 'Todos'
        const userWorkSector = workSectors?.find(ws => ws.id === user?.ClientUserWorkSectors[0]?.work_sector_id)
        return userWorkSector ? userWorkSector.id : null
    }

    const handleAllAddressesChange = checked => {
        setShouldHaveAllAddresses(checked)
        setAllAddressesChecked(checked)
        if (checked) {
            setSelectedAddresses(clientAddress.map(address => address.id))
        } else {
            setSelectedAddresses([])
        }
    }

    const handleAddressChange = (addressId, checked) => {
        if (user?.type === 2) {
            if (checked) {
                setSelectedAddresses([addressId])
            } else {
                setSelectedAddresses([])
            }
        } else {
            if (checked) {
                setSelectedAddresses([...selectedAddresses, addressId])
            } else {
                setSelectedAddresses(selectedAddresses.filter(id => id !== addressId))
            }
            // Desmarcar "Todos os endereços atuais e futuros" se algum endereço for desmarcado
            if (allAddressesChecked) {
                setAllAddressesChecked(false)
                formRef.current.setFieldsValue({ all_addresses: false })
            }
        }
    }

    const isAddressChecked = address => {
        const check =
            Array.isArray(address.clientUserAddresses) &&
            address.clientUserAddresses.some(e => e.client_user_id == clientUserId && e.addresses_id == address.id)
        return check
    }

    return (
        <>
            <div>
                {loading ? (
                    <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                        <SL_ClipLoader loading={true} />
                    </div>
                ) : (
                    <>
                        <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    key: 1,
                                    label: displayMessage('BOND_DETAILS'),
                                    children: (
                                        <div>
                                            <div className="w-full flex flex-row justify-end ">
                                                <div className="mr-2 ">{displayMessage('ENABLE_EDIT')}</div>
                                                <Switch checked={isEditing} onChange={e => setIsEditing(e)} />
                                            </div>
                                            <div className="w-full flex justify-center">
                                                <Form
                                                    onFinish={onSubmit}
                                                    className="max-w-[800px] w-full"
                                                    layout="vertical"
                                                    requiredMark={false}
                                                    ref={formRef}
                                                >
                                                    {mobile_mode ? (
                                                        <>
                                                            <div className="flex flex-1 flex-col gap-1">
                                                                <div className="">{displayMessage('FIRST_NAME')}</div>

                                                                <Form.Item
                                                                    name="first_name"
                                                                    initialValue={user?.first_name}
                                                                    rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]}
                                                                    className="mb-4"
                                                                >
                                                                    <SL_Input
                                                                        placeholder={displayMessage('FIRST_NAME')}
                                                                        className={isEditing ? 'w-full ' : 'w-full text-gray-400'}
                                                                        disabled={true}
                                                                    />
                                                                </Form.Item>
                                                            </div>

                                                            <div className="flex flex-1 flex-col gap-1">
                                                                <div className="">{displayMessage('LAST_NAME')}</div>

                                                                <Form.Item
                                                                    name="last_name"
                                                                    initialValue={user?.last_name}
                                                                    rules={[{ required: true, message: displayMessage('DISPLAY_LAST_NAME') }]}
                                                                    className="mb-6"
                                                                >
                                                                    <SL_Input
                                                                        className={isEditing ? 'w-full ' : 'w-full text-gray-400'}
                                                                        placeholder={displayMessage('LAST_NAME')}
                                                                        disabled={true}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="flex flex-row justify-between">
                                                                <div className="flex flex-1 flex-col gap-1 mr-5">
                                                                    <div className="">{displayMessage('FIRST_NAME')}</div>

                                                                    <Form.Item
                                                                        name="first_name"
                                                                        initialValue={user?.first_name}
                                                                        rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]}
                                                                        className="mb-4"
                                                                    >
                                                                        <SL_Input
                                                                            placeholder={displayMessage('FIRST_NAME')}
                                                                            className={'w-full text-gray-400'}
                                                                            disabled={true}
                                                                        />
                                                                    </Form.Item>
                                                                </div>

                                                                <div className="flex flex-1 flex-col gap-1">
                                                                    <div className="">{displayMessage('LAST_NAME')}</div>

                                                                    <Form.Item
                                                                        name="last_name"
                                                                        initialValue={user?.last_name}
                                                                        rules={[{ required: true, message: displayMessage('DISPLAY_LAST_NAME') }]}
                                                                        className="mb-6"
                                                                    >
                                                                        <SL_Input
                                                                            className={'w-full text-gray-400'}
                                                                            placeholder={displayMessage('LAST_NAME')}
                                                                            disabled={true}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className="flex flex-col gap-1 mt-1">
                                                        <div className="">{displayMessage('USER_TYPE')}</div>

                                                        <Form.Item
                                                            name="type"
                                                            initialValue={
                                                                Object.values(EUserTypes).find(e => e.code === user?.type)?.description ||
                                                                displayMessage('USER_TYPE')
                                                            }
                                                            rules={[{ required: true, message: displayMessage('DISPLAY_USER_TYPE') }]}
                                                            className="mb-6"
                                                        >
                                                            <SL_Input className={'w-full text-gray-400'} disabled={true} />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="flex flex-col gap-1 mt-1">
                                                        <div className="">{displayMessage('CLIENT_ADDRESSES')}</div>
                                                        {user?.type === 1 && (
                                                            <Form.Item
                                                                name="all_addresses"
                                                                // valuePropName="checked"
                                                                className="mb-3"
                                                            >
                                                                <Checkbox
                                                                    checked={shouldHaveAllAddresses}
                                                                    disabled={!isEditing}
                                                                    onChange={e => handleAllAddressesChange(e.target.checked)}
                                                                >
                                                                    {displayMessage('ALL_CURRENT_AND_FUTURE_ADDRESSES')}
                                                                </Checkbox>
                                                            </Form.Item>
                                                        )}
                                                        <div className="max-h-80 overflow-y-auto bg-gray-100 p-2 rounded mb-2">
                                                            {clientAddress?.map(address => (
                                                                <Form.Item
                                                                    key={address?.id}
                                                                    name={`address_${address?.id}`}
                                                                    // valuePropName="checked"
                                                                    className="mb-4"
                                                                >
                                                                    <Checkbox
                                                                        checked={selectedAddresses.includes(address.id)}
                                                                        disabled={!isEditing}
                                                                        onChange={e => handleAddressChange(address.id, e.target.checked)}
                                                                    >
                                                                        {`${address?.city}-${address?.state}, ${address?.neighbourhood}, Rua ${address?.street} nº ${address?.number}, ${address?.complement}`}
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            ))}
                                                        </div>
                                                    </div>

                                                    <Form.Item
                                                        name="work_sector_id"
                                                        rules={[
                                                            user?.type === 2
                                                                ? { required: true, message: displayMessage('DISPLAY_WORK_SECTOR') }
                                                                : { required: false },
                                                        ]}
                                                        className="mb-6"
                                                        initialValue={workSectors ? isUserWorkSector() : undefined}
                                                    >
                                                        <SL_Select
                                                            maxTagCount="responsive"
                                                            className="mb-1 h-[28px] rounded-sm"
                                                            listItemHeight={100}
                                                            title={displayMessage('WORK_SECTOR')}
                                                            placeholder={displayMessage('WORK_SECTOR')}
                                                            onChange={handleWorkSectorChange}
                                                            value={workSectors ? isUserWorkSector() : undefined}
                                                            options={
                                                                user?.type === 1
                                                                    ? [{ label: 'Todos', value: 'Todos' }]
                                                                    : workSectors
                                                                          ?.filter(e => e.inactivation_date === null)
                                                                          .map(e => ({ label: e.name, value: e.id }))
                                                            }
                                                            allowClear={user?.type !== 1}
                                                            disabled={!isEditing || user?.type === 1}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item hasFeedback className="mb-3 mt-2">
                                                        <SL_Button
                                                            disabled={!isEditing}
                                                            type="primary"
                                                            className={'w-full font-bold shadow-md'}
                                                            htmlType="submit"
                                                        >
                                                            {displayMessage('EDIT')}
                                                        </SL_Button>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </>
                )}
            </div>
        </>
    )
}
