import { Form, Modal, Upload } from 'antd'
import { useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import { useParams } from 'react-router-dom'
import { CepMaskedInput } from '../../../helpers/iMask'
import { extractNumbersFromString } from '../../../helpers/helper'

const STATUS_SUCCESS = 200

export function ModalCreateClientAddress({ isOpen, onClose, reloadClientAdressess, externClientId = null }) {
    const formRef = useRef()
    const { clientId } = useParams()

    const [loading, setLoading] = useState(false)

    const onSubmit = async address => {
        setLoading(true)
        try {
            const formattedAddress = formatAddress(address)
            const body = { ...formattedAddress, client_id: clientId ?? externClientId }

            const response = await api.addresses.create(body)

            if (response.status === STATUS_SUCCESS) {
                onClose(false)
                await reloadClientAdressess()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const formatAddress = address => {
        address.cep = extractNumbersFromString(address.cep)

        return address
    }
    return (
        <Modal
            title={displayMessage('CLIENT_ADDRESS_CREATION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            okText={displayMessage('FINISH')}
            maskClosable={false}
        >
            <Form onFinish={onSubmit} className="max-w-[500px] w-full" layout="vertical" requiredMark={false} ref={formRef}>
                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('POSTAL_CODE')}</div>

                    <Form.Item name="cep" rules={[{ required: true, message: displayMessage('DISPLAY_CEP') }]} className="mb-4">
                        {CepMaskedInput({
                            placeholder: displayMessage('POSTAL_CODE'),
                        })}
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('STATE')}</div>

                    <Form.Item name="state" rules={[{ required: true, message: displayMessage('DISPLAY_STATE') }]} className="mb-4">
                        <SL_Input placeholder={displayMessage('STATE')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('CITY')}</div>

                    <Form.Item name="city" rules={[{ required: true, message: displayMessage('DISPLAY_CITY') }]} className="mb-4">
                        <SL_Input placeholder={displayMessage('CITY')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('NEIGHBOURHOOD')}</div>

                    <Form.Item name="neighbourhood" rules={[{ required: true, message: displayMessage('DISPLAY_NEIGHBOURHOOD') }]} className="mb-4">
                        <SL_Input placeholder={displayMessage('NEIGHBOURHOOD')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('STREET')}</div>

                    <Form.Item name="street" className="mb-4">
                        <SL_Input placeholder={displayMessage('STREET')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('NUMBER')}</div>

                    <Form.Item name="number" className="mb-4">
                        <SL_Input placeholder={displayMessage('NUMBER')} className={'w-full'} />
                    </Form.Item>
                </div>

                <div className="flex flex-col gap-1">
                    <div className="">{displayMessage('COMPLEMENT')}</div>

                    <Form.Item name="complement" className="mb-4">
                        <SL_Input placeholder={displayMessage('COMPLEMENT')} className={'w-full'} />
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    )
}
