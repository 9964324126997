import { DateTime } from 'luxon'
import { displayMessage } from '../../../helpers/messages'
import { Tooltip } from 'antd'

export const risks_grid_columns = [
    {
        field: 'RiskType',
        headerName: displayMessage('RISK_TYPE'),
        type: 'string',
        width: 230,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row?.RiskKind?.RiskActivity?.RiskType) return row.RiskKind.RiskActivity.RiskType.name
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            return params?.row?.RiskKind?.RiskActivity?.RiskType?.name
        },
    },
    {
        field: 'RiskActivity',
        headerName: displayMessage('RISK_ACTIVITY'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row?.RiskKind?.RiskActivity) return row.RiskKind.RiskActivity.name
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            return params?.row?.RiskKind?.RiskActivity?.name
        },
    },
    {
        field: 'RiskKind',
        headerName: displayMessage('RISK_KIND'),
        type: 'string',
        width: 350,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row?.RiskKind) return row.RiskKind.name
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            return params?.row?.RiskKind?.name
        },
    },
    {
        field: 'title',
        headerName: displayMessage('TITLE'),
        type: 'string',
        width: 600,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.title) return row.title
            return displayMessage('EMPTY_01')
        },
        renderCell: params => {
            return (
                <Tooltip
                    placement="bottom"
                    title={params.value}
                    overlayStyle={{ maxWidth: 500 }}
                    overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                >
                    <strong style={{ width: '100%', fontWeight: 'normal' }}>{params.value}</strong>
                </Tooltip>
            )
        },
    },
    {
        field: 'description',
        headerName: displayMessage('MODULE_DESCRIPTION'),
        type: 'string',
        width: 600,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.description) return row.description
            return displayMessage('EMPTY_01')
        },
        renderCell: params => {
            return (
                <Tooltip
                    placement="bottom"
                    title={params.value}
                    overlayStyle={{ maxWidth: 500 }}
                    overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                >
                    <strong style={{ width: '100%', fontWeight: 'normal' }}>{params.value}</strong>
                </Tooltip>
            )
        },
    },
    {
        field: 'Recommendation',
        headerName: displayMessage('RECOMMENDATION'),
        type: 'string',
        width: 400,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row?.Recommendation) return row.Recommendation.title
            return displayMessage('EMPTY_01')
        },
        renderCell: params => {
            return (
                <Tooltip
                    placement="bottom"
                    title={params.formattedValue}
                    overlayStyle={{ maxWidth: 500 }}
                    overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                >
                    <strong style={{ width: '100%', fontWeight: 'normal' }}>{params.formattedValue}</strong>
                </Tooltip>
            )
        },
        valueGetter: params => {
            return params?.row?.Recommendation?.title
        },
    },
    {
        field: 'is_active',
        headerName: displayMessage('ACTIVATION_STATUS'),
        type: 'string',
        width: 150,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row) return row.is_active ? displayMessage('ACTIVE') : displayMessage('INACTIVE')
        },
        type: 'singleSelect',
        valueOptions: [
            { value: true, label: 'Ativo' },
            { value: false, label: 'Inativo' },
        ],
    },
    {
        field: 'inactivation_date',
        headerName: displayMessage('INACTIVATION_DATE'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.inactivation_date) return DateTime.fromISO(row.inactivation_date).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'createdAt',
        headerName: displayMessage('CREATED_AT'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.createdAt) return DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
]
