import { useContext, useState } from 'react'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../../helpers/messages'
import { Form, Tooltip } from 'antd'
import { SL_Input } from '../../../components/input/Input'
import { SL_Select } from '../../../components/selects/Select'
import EActivationStatus from '../../../enums/EActivationStatus'
import { SL_Date } from '../../../components/date/Date'
import { Eraser } from '@phosphor-icons/react'
import { SL_Button } from '../../../components/buttons/Button'

export default function StrengthFilters(props) {
    const { loadStrengths, setGridLoading, setStrengths } = props

    const { mobile_mode } = useContext(GeneralContext)

    const [title, setTitle] = useState(null)
    // const [createdAt, setCreatedAt] = useState(null)
    const [statusActivation, setStatusActivation] = useState(null)
    const [form] = Form.useForm()

    const clearFilters = async () => {
        setTitle(null)
        setStatusActivation(null)
        form.resetFields()
        await loadStrengths()
    }

    const handleActivationStatusChange = value => {
        setStatusActivation(value)
    }

    // const handleCreatedAtChange = value => {
    //     setCreatedAt(value)
    // }

    const handleOnFilter = async () => {
        setGridLoading(true)
        try {
            const params = {
                title,
                statusActivation,
                // created_at: createdAt
            }

            const { data } = await loadStrengths(params)

            setStrengths(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    return (
        <>
            <div className="flex flex-col rounded-md p-2 border border-solid border-[#d9d9d9]">
                <span className="self-center font-bold text-lg flex-1 text-center mb-2">{displayMessage('SEARCH_FILTERS')}</span>
                <div className={`w-full flex ${mobile_mode ? 'flex-col' : 'flex-row'} gap-y-2 gap-x-2 justify-between`}>
                    <div>
                        <Form
                            onFinish={props.onFinish}
                            layout="vertical"
                            form={form}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleOnFilter()
                                }
                            }}
                            className="flex flex-col lg:flex-row gap-x-2 gap-y-2 md:flex-wrap"
                        >
                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('TITLE')}
                                    requiredMark=""
                                    name="title"
                                    className={`m-0 ${mobile_mode ? 'w-full' : ''}`}
                                >
                                    <SL_Input placeholder={displayMessage('TITLE')} onChange={e => setTitle(e.target.value)} />
                                </Form.Item>
                            </div>
                            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('ACTIVATION_STATUS')}
                                    requiredMark=""
                                    name="activation_status"
                                    className={`m-0 ${mobile_mode ? 'w-full' : ''}`}
                                >
                                    <SL_Select
                                        maxTagCount="responsive"
                                        className="mb-1 h-[28px] rounded-sm"
                                        listItemHeight={100}
                                        title={displayMessage('ACTIVATION_STATUS')}
                                        placeholder={displayMessage('ACTIVATION_STATUS')}
                                        onChange={handleActivationStatusChange}
                                        value={statusActivation}
                                        options={Object.values(EActivationStatus).map(e => ({ label: e.description, value: e.code }))}
                                        allowClear
                                    />
                                </Form.Item>
                            </div>

                            {/* <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                                <Form.Item
                                    label={displayMessage('CREATED_AT')}
                                    requiredMark=""
                                    name="created_at"
                                    className={`m-0 ${mobile_mode ? 'w-full' : ''}`}
                                >
                                    <SL_Date style={{ width: mobile_mode ? '100%' : '' }} value={createdAt} format={'DD/MM/YYYY'} onChange={handleCreatedAtChange} />
                                </Form.Item>
                            </div> */}
                        </Form>
                    </div>
                    <div className="flex justify-self-end">
                        <div className={`flex ${mobile_mode ? 'w-full justify-between' : ''} items-end pb-1`}>
                            <Tooltip placement="topLeft" title={displayMessage('CLEAN_FILTERS')}>
                                <SL_Button
                                    className="md-mx-0 rounded-r-none bg-white border-solid border-1 border-primary-600 text-primary-600"
                                    onClick={() => clearFilters()}
                                >
                                    <Eraser size={20} color="black" />
                                </SL_Button>
                            </Tooltip>

                            <SL_Button className={`md-mx-0 rounded ${mobile_mode ? 'w-full ml-2' : ''}`} onClick={handleOnFilter}>
                                {displayMessage('TO_FILTER')}
                            </SL_Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
