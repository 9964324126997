import { Checkbox, Form, Modal } from 'antd'
import { useContext, useEffect, useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import { SL_Select } from '../../../components/selects/Select'
import EUserTypes from '../../../enums/EUserTypes'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../../context/AuthContext/AuthContext'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { EAlertTypes } from '../../../enums/EAlertTypes'

const STATUS_SUCCESS = 200

//selecionei um usuário. Preciso das informações de ClientUser, ClientuserAssociation (para saber se deve ter todos os endereços) e de clientUserAddress (para saber os endereços a que ele pertence)

//Quando um usuário for selecionado, devemos marcar seus checkbox com base nas informações recebidas. se deve ter todos os endereços ou quais endereços já possui.

export function ModalVinculateClientUser({ isOpen, onClose, reloadClientUsers, currentClientUsers, workSector, clientAddress }) {
    const formRef = useRef()
    const { clientId } = useParams()
    const { loggedUser } = useContext(AuthContext)
    const { showAlert } = useContext(GeneralContext)

    const [loading, setLoading] = useState(false)
    const [clientUsers, setClientUsers] = useState(null)
    const [clientUserId, setClientUserId] = useState(null)
    // const [clientAddress, setClientAddress] = useState([])

    const [clientWorkSector, setClientWorkSector] = useState(null)
    const [userType, setUserType] = useState(null)
    const [allAddressesChecked, setAllAddressesChecked] = useState(false)
    const [selectedAddresses, setSelectedAddresses] = useState([])
    const [workSectorId, setWorkSectorId] = useState(null)

    useEffect(() => {
        loadClientUsers({ client_id: clientId, current_client_users: currentClientUsers, vinculate: true })
    }, [])

    const loadClientUsers = async params => {
        setLoading(true)
        try {
            const { data } = await api.clientUsers.list(params)
            setClientUsers(data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleUserTypeChange = value => {
        setUserType(value)
    }

    const handleWorkSectorChange = value => {
        setWorkSectorId(value)
    }

    const handleClientUserChange = value => {
        const selectedUser = clientUsers.find(user => user.id === value)
        setClientUserId(value)
        setUserType(selectedUser?.type)
        if (selectedUser?.type === 1) {
            setWorkSectorId('Todos')
            formRef.current.setFieldsValue({ work_sector_id: 'Todos' })
        } else {
            setWorkSectorId(null)
            formRef.current.setFieldsValue({ work_sector_id: null })
        }
    }

    const handleAllAddressesChange = () => {
        const newCheckedState = !allAddressesChecked
        setAllAddressesChecked(newCheckedState)
        const newSelectedAddresses = newCheckedState ? clientAddress.map(address => address.id) : []
        setSelectedAddresses(newSelectedAddresses)
        formRef.current.setFieldsValue(
            clientAddress.reduce((acc, address) => {
                acc[`address_${address.id}`] = newCheckedState
                return acc
            }, {})
        )
    }

    const handleAddressChange = (addressId, checked) => {
        if (!checked) {
            setAllAddressesChecked(false)
            formRef.current.setFieldsValue({ all_addresses: false })
            setSelectedAddresses(prev => prev.filter(id => id !== addressId))
        } else {
            if (userType === 2) {
                // Desmarcar todos os outros checkboxes
                setSelectedAddresses([addressId])
                formRef.current.setFieldsValue(
                    clientAddress.reduce((acc, address) => {
                        acc[`address_${address.id}`] = address.id === addressId
                        return acc
                    }, {})
                )
            } else {
                setSelectedAddresses(prev => [...prev, addressId])
            }
        }
    }

    const onSubmit = async user => {
        setLoading(true)
        try {
            const userToSubmit = {
                client_id: clientId,
                client_user_id: user.client_user,
                should_have_all_addresses: user.all_addresses,
                addresses: allAddressesChecked ? clientAddress.map(address => address.id) : selectedAddresses,
                work_sector: user.work_sector_id,
                created_by_user_id: loggedUser.id,
            }
            if (selectedAddresses.length === 0) {
                showAlert(displayMessage('SELECT_AT_LEAST_ONE_ADDRESS'), EAlertTypes.ERROR)
                return
            }
            const response = await api.clientUsersAssocition.create(userToSubmit)
            if (response.status === STATUS_SUCCESS) {
                onClose(false)
                await reloadClientUsers()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            title={displayMessage('VINCULATE_USER')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            okText={displayMessage('FINISH')}
            maskClosable={false}
        >
            <Form onFinish={onSubmit} className="max-w-[500px] w-full" layout="vertical" requiredMark={false} ref={formRef}>
                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('USER')}</div>
                    <Form.Item name="client_user" rules={[{ required: true, message: displayMessage('DISPLAY_WORK_SECTOR') }]} className="mb-6">
                        <SL_Select
                            showSearch
                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                            maxTagCount="responsive"
                            className="mb-1 h-[28px] rounded-sm"
                            listItemHeight={100}
                            title={displayMessage('USER')}
                            placeholder={displayMessage('USER')}
                            onChange={handleClientUserChange}
                            value={clientUserId}
                            options={clientUsers
                                ?.filter(e => e.is_active === true)
                                .map(e => ({ label: `${e.first_name} ${e.last_name}`, value: e.id }))}
                            allowClear
                        />
                    </Form.Item>
                </div>

                {clientUserId && (
                    <div className="flex flex-col gap-1 mt-1">
                        <div className="">{displayMessage('CLIENT_ADDRESSES')}</div>
                        {userType === 1 && (
                            <Form.Item name="all_addresses" valuePropName="checked" className="mb-3 ">
                                <Checkbox onChange={handleAllAddressesChange} checked={allAddressesChecked}>
                                    {displayMessage('ALL_CURRENT_AND_FUTURE_ADDRESSES')}
                                </Checkbox>
                            </Form.Item>
                        )}

                        <div className="max-h-48 overflow-y-auto bg-gray-100 p-2 rounded">
                            {clientAddress?.map(address => (
                                <Form.Item key={address.id} name={`address_${address.id}`} valuePropName="checked" className="mb-3">
                                    <Checkbox
                                        onChange={e => handleAddressChange(address.id, e.target.checked)}
                                        disabled={userType !== 1 && formRef.current && formRef.current.getFieldValue(`address_${address.id}`)}
                                    >
                                        {`${address.city} - ${address.state}. ${address.neighbourhood}, Rua ${address.street} nº ${address.number}`}
                                    </Checkbox>
                                </Form.Item>
                            ))}
                        </div>
                    </div>
                )}

                <div className="flex flex-col gap-1 mt-1">
                    <div className="">{displayMessage('WORK_SECTOR')}</div>
                    <Form.Item name="work_sector_id" rules={[{ required: true, message: displayMessage('DISPLAY_WORK_SECTOR') }]} className="mb-6">
                        <SL_Select
                            maxTagCount="responsive"
                            className="mb-1 h-[28px] rounded-sm"
                            listItemHeight={100}
                            title={displayMessage('WORK_SECTOR')}
                            placeholder={displayMessage('WORK_SECTOR')}
                            onChange={handleWorkSectorChange}
                            value={workSectorId}
                            options={
                                userType === 1
                                    ? [{ label: 'Todos', value: 'Todos' }]
                                    : workSector?.filter(e => e.inactivation_date === null).map(e => ({ label: e.name, value: e.id }))
                            }
                            allowClear={userType !== 1}
                            disabled={userType === 1}
                        />
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    )
}
