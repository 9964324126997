import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../../../services/api'
import { SL_Grid } from '../../../../components/grid/SL_Grid'
import CustomToolbar from '../../../../components/grid/CustomToolbar'
import { GeneralContext } from '../../../../context/GeneralContext/GeneralContext'
import { SL_Button } from '../../../../components/buttons/Button'
import { follow_ups_grid_columns } from './grid_columns/follow_ups_grid_columns'
import { displayMessage } from '../../../../helpers/messages'
import { ModalCreateFollowUp } from './components/ModalCreateFollowUp'
import { DownloadOutlined } from '@ant-design/icons'

export function FollowUps({ loadFollowUps, followUps, gridLoading }) {
    const { reportId } = useParams()
    const { mobile_mode } = useContext(GeneralContext)

    const navigate = useNavigate()

    const [rowOnFocus, setRowOnFocus] = useState(null)

    const [modalCreateFollowUpIsOpen, setModalCreateFollowUpIsOpen] = useState(false)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadFollowUps()
    }, [])

    const generatePdf = async reportId => {
        setLoading(true)
        try {
            const pdfName = await api.reports.generate_pdf({ id: reportId, follow_up: true })
            const response = await api.reports.open_pdf(pdfName.data, { responseType: 'blob' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(response.data)
            link.download = `Relatório_${reportId + '_' + pdfName.data}.pdf`
            link.click()
            const parent = link.parentNode
            if (link && parent) {
                parent.removeChild(link)
            }

            alert(PDF_DOWNLOADED_SUCCESS, 'success')
            console.log(reportId)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {modalCreateFollowUpIsOpen && (
                <ModalCreateFollowUp isOpen={modalCreateFollowUpIsOpen} onClose={setModalCreateFollowUpIsOpen} reloadFollowUps={loadFollowUps} />
            )}

            <SL_Grid
                rows={followUps}
                sortingMode={'server'}
                components={{
                    Toolbar: () => (
                        <>
                            <div className="flex justify-between flex-row ss:flex-row">
                                <CustomToolbar />
                                <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                    <SL_Button
                                        className="my-1 mr-1"
                                        disabled={!rowOnFocus}
                                        onClick={() => navigate(`/reports/${reportId}/follow-up/${rowOnFocus.id}`)}
                                    >
                                        {displayMessage('FOLLOW_UP_DETAIL')}
                                    </SL_Button>
                                    <SL_Button className="my-1 mr-1" onClick={() => setModalCreateFollowUpIsOpen(true)}>
                                        {displayMessage('CREATE_FOLLOW_UP')}
                                    </SL_Button>
                                    <SL_Button
                                        className="my-1 mr-1"
                                        disabled={!rowOnFocus}
                                        loading={loading}
                                        onClick={() => generatePdf(rowOnFocus.id)}
                                    >
                                        <DownloadOutlined />
                                        {displayMessage('PDF')}
                                    </SL_Button>
                                </div>
                            </div>
                        </>
                    ),
                }}
                disableFilter={false}
                onRowClick={params => {
                    setRowOnFocus(params.row)
                }}
                columns={follow_ups_grid_columns}
                className="flex-1 min-h-[61vh] max-h-[100vh]"
                loading={gridLoading}
            />
        </>
    )
}
