import EClientTypes from '../enums/EClientTypes'
import EUserTypes from '../enums/EUserTypes'

export function formatCpf(cpf) {
    const inputCpf = cpf.replace(/\D/g, '')

    return inputCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function formatCep(cep) {
    return cep.replace(/(\d{5})(\d{3})/, '$1-$2')
}

export function formatCnpj(cnpj) {
    if (!cnpj) return ''

    const inputCnpj = cnpj.replace(/\D/g, '')

    return inputCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export function formatCnae(cnae_number) {
    if (!cnae_number) return ''

    const inputCnae = cnae_number.replace(/\D/g, '')

    return inputCnae.replace(/(\d{2})(\d{2})(\d{1})(\d{2})/, '$1.$2-$3-$4')
}

export function formatPhone(ddd, phone) {
    if (!ddd || !phone) return
    const inputPhone = phone.replace(/\D/g, '')
    if (!ddd) {
        return inputPhone.replace(/(\d{5})(\d{4})/, '$1-$2')
    } else {
        return `(${ddd}) ${inputPhone.substring(0, 5)}-${inputPhone.substring(5)}`
    }
}

export function findUserTypeByCode(code) {
    for (const userType in EUserTypes) {
        if (EUserTypes[userType].code == code) {
            return EUserTypes[userType]
        }
    }

    return null
}

export function findClientTypeByCode(code) {
    for (const clientType in EClientTypes) {
        if (EClientTypes[clientType].code == code) {
            return EClientTypes[clientType]
        }
    }
    return null
}

export function extractNumbersFromString(str) {
    return str.replace(/\D/g, '')
}

export function emptyString(str) {
    if (!str) return true

    if (str == '') return true

    if (str.length == 0) return true

    if (str == undefined) return true

    if (str == null) return true

    if (!str || str.trim() === '') return true

    return false
}
