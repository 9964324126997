import { useContext, useEffect, useRef, useState } from 'react'
import { displayMessage } from '../../../helpers/messages'
import { Button, Collapse, Form, List, Tour, Upload } from 'antd'
import { SL_Input } from '../../../components/input/Input'
import { SL_Button } from '../../../components/buttons/Button'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { CloseCircleOutlined } from '@ant-design/icons'
import { EAlertTypes } from '../../../enums/EAlertTypes'
import api from '../../../services/api'
import { emptyString } from '../../../helpers/helper'
import { useNavigate } from 'react-router-dom'

import first_page_cover_image_example from '../../../assets/first_page_cover_image_example.png'

import second_page_header_image_example from '../../../assets/second_page_header_image_example.png'
import second_page_first_text_image_example from '../../../assets/second_page_first_text_image_example.png'
import second_page_middle_title_image_example from '../../../assets/second_page_middle_title_image_example.png'
import second_page_second_text_image_example from '../../../assets/second_page_second_text_image_example.png'

import main_goal_text_image_example from '../../../assets/main_goal_text_image_example.png'
import main_specific_text_image_example from '../../../assets/main_specific_text_image_example.png'

import probability_vs_seriousness_matrix_image_example from '../../../assets/probability_vs_seriousness_matrix_image_example.png'

import raised_risks_text_image_example from '../../../assets/raised_risks_text_image_example.png'

import next_steps_first_text_image_example from '../../../assets/next_steps_first_text_image_example.png'
import next_steps_5w2h_image_example from '../../../assets/next_steps_5w2h_image_example.png'
import next_steps_second_text_image_example from '../../../assets/next_steps_second_text_image_example.png'

import report_config_reference_normative_image_example from '../../../assets/report_config_reference_normative_image_example.png'

const { Panel } = Collapse

const FIRST_PAGE_PANEL_KEY = 1
const SECOND_PAGE_PANEL_KEY = 2
const MAIN_PANEL_KEY = 3
const PROBABILITY_VS_SERIOUSNESS_MATRIX_PANEL_KEY = 4
const RAISED_RISKS_PANEL_KEY = 5
const NEXT_STEPS_PANEL_KEY = 6
const REPORT_CONFIG_REFERENCE_NORMATIVES_PANEL_KEY = 7
const TECHNICAL_RESPONSABILITY_PANEL_KEY = 8

const STATUS_SUCCESS = 200

export function CreateReportConfig({}) {
    const { showAlert, setShowLoading, mobile_mode } = useContext(GeneralContext)

    const navigate = useNavigate()

    const reportConfigReferenceNormativesRef = useRef()
    const [form] = Form.useForm()

    const [firstPageTourIsOpen, setFirstPageTourIsOpen] = useState(false)
    const first_page_ref = useRef(null)
    const first_page_tour_steps = [
        {
            title: 'Envie uma imagem',
            description: 'A imagem enviada ficará disposta sobre a primeira página do pdf, como no exemplo',
            placement: 'right',
            cover: <img src={first_page_cover_image_example} style={{ height: '50%', width: '50%' }} />,
            target: () => first_page_ref.current,
        },
    ]

    const [secondPageTourIsOpen, setSecondPageTourIsOpen] = useState(false)
    const second_page_header_image_ref = useRef(null)
    const second_page_first_text_ref = useRef(null)
    const second_page_middle_title_ref = useRef(null)
    const second_page_second_text_ref = useRef(null)
    const second_page_tour_steps = [
        {
            title: 'Envie uma imagem',
            description: 'A imagem enviada ficará disposta sobre o cabeçalho da segunda página do pdf, como no exemplo',
            placement: 'right',
            cover: <img src={second_page_header_image_example} style={{ height: '50%', width: '50%' }} />,
            target: () => second_page_header_image_ref.current,
        },
        {
            title: 'Informe o primeiro texto',
            description: 'O texto informado neste campo ficará disposto sobre a segunda página do pdf, como no exemplo',
            placement: 'right',
            cover: <img src={second_page_first_text_image_example} style={{ height: '50%', width: '50%' }} />,
            target: () => second_page_first_text_ref.current,
        },
        {
            title: 'Informe o título do meio texto',
            description: 'O título informado neste campo ficará disposto sobre a segunda página do pdf, como no exemplo',
            placement: 'right',
            cover: <img src={second_page_middle_title_image_example} style={{ height: '50%', width: '50%' }} />,
            target: () => second_page_middle_title_ref.current,
        },
        {
            title: 'Informe o segundo texto',
            description: 'O texto informado neste campo ficará disposto sobre a segunda página do pdf, como no exemplo',
            placement: 'right',
            cover: <img src={second_page_second_text_image_example} style={{ height: '50%', width: '50%' }} />,
            target: () => second_page_second_text_ref.current,
        },
    ]

    const [mainTourIsOpen, setMainTourIsOpen] = useState(false)
    const main_goal_text_ref = useRef(null)
    const main_specific_text_ref = useRef(null)
    const main_tour_steps = [
        {
            title: 'Informe o texto de objetivos gerais',
            description: 'O texto informado neste campo ficará disposto sobre a sessão de objetivos gerais do pdf, como no exemplo',
            placement: 'right',
            cover: <img src={main_goal_text_image_example} style={{ height: '50%', width: '50%' }} />,
            target: () => main_goal_text_ref.current,
        },
        {
            title: 'Informe o texto de objetivos específicos',
            description: 'O texto informado neste campo ficará disposto sobre a seção de objetivos específicos do pdf, como no exemplo',
            placement: 'right',
            cover: <img src={main_specific_text_image_example} style={{ height: '50%', width: '50%' }} />,
            target: () => main_specific_text_ref.current,
        },
    ]

    const [probabilityVsSeriousnessMatrixTourIsOpen, setProbabilityVsSeriousnessMatrixTourIsOpen] = useState(false)
    const probability_vs_seriousness_matrix_image_ref = useRef(null)
    const probability_vs_seriousness_matrix_steps = [
        {
            title: 'Envie uma imagem',
            description: 'A imagem enviada ficará disposta no final da sessão de Probabilidade X Gravidade do pdf, como no exemplo',
            placement: 'right',
            cover: <img src={probability_vs_seriousness_matrix_image_example} style={{ height: '50%', width: '50%' }} />,
            target: () => probability_vs_seriousness_matrix_image_ref.current,
        },
    ]

    const [raisedRisksTourIsOpen, setRaisedRisksTourIsOpen] = useState(false)
    const raised_risks_text_ref = useRef(null)
    const raised_risks_steps = [
        {
            title: 'Informe o texto dos riscos levantados',
            description: 'O texto informado ficará disposto na sessão de riscos levantados do pdf, como no exemplo',
            placement: 'right',
            cover: <img src={raised_risks_text_image_example} style={{ height: '50%', width: '50%' }} />,
            target: () => raised_risks_text_ref.current,
        },
    ]

    const [nextStepsTourIsOpen, setNextStepsTourIsOpen] = useState(false)
    const next_steps_first_text_ref = useRef(null)
    const next_steps_5w2h_image_ref = useRef(null)
    const next_steps_second_text_ref = useRef(null)
    const next_steps_tour_steps = [
        {
            title: 'Informe o primeiro texto dos próximos passos',
            description: 'O texto informado ficará disposto na sessão de próximos passos do PDF, como no exemplo',
            placement: 'right',
            cover: <img src={next_steps_first_text_image_example} style={{ height: '50%', width: '50%' }} />,
            target: () => next_steps_first_text_ref.current,
        },
        {
            title: 'Envie uma imagem',
            description: 'A imagem enviada ficará disposta na sessão de próximos passos do PDF, como no exemplo',
            placement: 'right',
            cover: <img src={next_steps_5w2h_image_example} style={{ height: '50%', width: '50%' }} />,
            target: () => next_steps_5w2h_image_ref.current,
        },
        {
            title: 'Informe o segundo texto dos próximos passos',
            description: 'O texto informado ficará disposto na sessão de próximos passos do PDF, como no exemplo',
            placement: 'right',
            cover: <img src={next_steps_second_text_image_example} style={{ height: '50%', width: '50%' }} />,
            target: () => next_steps_second_text_ref.current,
        },
    ]

    const [technicalResponsabilityTourIsOpen, setTechnicalResponsabilityTourIsOpen] = useState(false)
    const technical_responsability_text_ref = useRef(null)
    const technical_responsability_image_ref = useRef(null)
    const technical_responsability_steps = [
        {
            title: 'Informe o texto do técnico responsável',
            description: 'O texto informado ficará disposto na sessão de responsabilidade técnica do PDF, como no exemplo',
            placement: 'right',
            cover: <img style={{ height: '50%', width: '50%' }} />,
            target: () => technical_responsability_text_ref.current,
        },
        {
            title: 'Envie uma imagem',
            description: 'A imagem enviada ficará disposta na sessão de responsabilidade técnica do pdf, como no exemplo',
            placement: 'right',
            cover: <img style={{ height: '50%', width: '50%' }} />,
            target: () => technical_responsability_image_ref.current,
        },
    ]

    const [reportConfigReferenceNormativeTourIsOpen, setReportConfigReferenceNormativeTourIsOpen] = useState(false)
    const report_config_reference_normative_ref = useRef(null)
    const report_config_reference_normative_steps = [
        {
            title: 'Cadastre uma referência normativa',
            description: 'As referências normativas cadastradas aqui ficarão dispostas na sessão de referências normativas do PDF, como no exemplo',
            placement: 'top',
            cover: <img src={report_config_reference_normative_image_example} style={{ height: '70%', width: '70%' }} />,
            target: () => report_config_reference_normative_ref.current,
        },
    ]

    const [reportConfigTitle, setReportConfigTitle] = useState(null)

    const [firstPageCoverImage, setFirstPageCoverImage] = useState([])

    const [secondPageHeaderImage, setSecondPageHeaderImage] = useState([])
    const [secondPageFirstText, setSecondPageFirstText] = useState(null)
    const [secondPageMiddleTitle, setSecondPageMiddleTitle] = useState(null)
    const [secondPageSecondText, setSecondPageSecondText] = useState(null)

    const [mainGoalText, setMainGoalText] = useState(null)
    const [mainSpecificText, setMainSpecificText] = useState(null)

    const [probabilityVsSeriousnessMatrixImage, setProbabilityVsSeriousnessMatrixImage] = useState([])

    const [raisedRisksText, setRaisedRisksText] = useState(null)

    const [nextStepsFirstText, setNextStepsFirstText] = useState(null)
    const [nextSteps5w2hImage, setNextSteps5w2hImage] = useState([])
    const [nextStepsSecondText, setNextStepsSecondText] = useState(null)

    const [technicalResponsabilityText, setTechnicalResponsabilityText] = useState(null)
    const [technicalResponsabilityImage, setTechnicalResponsabilityImage] = useState([])
    const [technicalResponsabilityImageText, setTechnicalResponsabilityImageText] = useState([])

    const [reportConfigReferenceNormatives, setReportConfigReferenceNormatives] = useState([])

    const [reportConfigReferenceDescription, setReportConfigReferenceDescription] = useState(null)
    const [reportConfigReferenceLogoImage, setReportConfigReferenceLogoImage] = useState([])

    const handleFileOnChange = ({ fileList: newFileList }, setState) => {
        setState(newFileList)
    }

    const handleOnFilePreview = async file => {
        let src = file.url

        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()

                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }

        const image = new Image()
        image.src = src

        const imgWindow = window.open(src)
        imgWindow?.document.write(image.outerHTML)
    }

    const handleOnNewReportConfigReferenceNormativeCreated = () => {
        if (reportConfigReferenceLogoImage.length == 0) {
            showAlert(displayMessage('ERROR_LOGO_IS_MISSING', EAlertTypes.ERROR))
            return
        }

        const object = {
            temporary_id: crypto.randomUUID(),
            description: reportConfigReferenceDescription,
            logo: reportConfigReferenceLogoImage[0],
        }

        setReportConfigReferenceNormatives(prev => [...prev, object])

        form.resetFields()
        setReportConfigReferenceDescription(null)
        setReportConfigReferenceLogoImage([])

        return
    }

    const handleOnRemoveReportConfigReferenceNormative = temporary_id => {
        if (!temporary_id) return

        const newReportConfigReferencesNormatives = reportConfigReferenceNormatives.filter(e => e.temporary_id !== temporary_id)
        setReportConfigReferenceDescription(null)
        setReportConfigReferenceLogoImage([])

        setReportConfigReferenceNormatives(newReportConfigReferencesNormatives)
    }

    const validateBeforeSubmit = () => {
        if (emptyString(reportConfigTitle)) {
            showAlert(displayMessage('ERROR_REPORT_CONFIG_TITLE_IS_MISSING'), EAlertTypes.ERROR)
            return false
        }

        if (firstPageCoverImage.length == 0) {
            showAlert(displayMessage('ERROR_FIRST_PAGE_COVER_IMAGE_IS_MISSING'), EAlertTypes.ERROR)
            return false
        }

        if (secondPageHeaderImage.length == 0) {
            showAlert(displayMessage('ERROR_SECOND_PAGE_HEADER_IMAGE_IS_MISSING'))
            return false
        }

        if (emptyString(secondPageFirstText)) {
            showAlert(displayMessage('ERROR_SECOND_PAGE_FIRST_TEXT_IS_MISSING'), EAlertTypes.ERROR)
            return false
        }

        if (emptyString(secondPageMiddleTitle)) {
            showAlert(displayMessage('ERROR_SECOND_PAGE_MIDDLE_TITLE_IS_MISSING'), EAlertTypes.ERROR)
            return false
        }

        if (emptyString(secondPageSecondText)) {
            showAlert(displayMessage('ERROR_SECOND_PAGE_SECOND_TEXT_IS_MISSING'), EAlertTypes.ERROR)
            return false
        }

        if (emptyString(mainGoalText)) {
            showAlert(displayMessage('ERROR_MAIN_GOAL_TEXT_IS_MISSING'), EAlertTypes.ERROR)
            return false
        }

        if (emptyString(mainSpecificText)) {
            showAlert(displayMessage('ERROR_MAIN_SPECIFIC_TEXT_IS_MISSING'), EAlertTypes.ERROR)
            return false
        }

        if (probabilityVsSeriousnessMatrixImage.length == 0) {
            showAlert(displayMessage('ERROR_PROBABILITY_VS_SERIOUSNESS_MATRIX_IMAGE_IS_MISSING'), EAlertTypes.ERROR)
            return false
        }

        if (emptyString(raisedRisksText)) {
            showAlert(displayMessage('ERROR_RAISED_RISKS_IS_MISSING'), EAlertTypes.ERROR)
            return false
        }

        if (emptyString(nextStepsFirstText)) {
            showAlert(displayMessage('ERROR_NEXT_STEPS_FIRST_TEXT_IS_MISSING'), EAlertTypes.ERROR)
            return false
        }

        if (nextSteps5w2hImage.length == 0) {
            showAlert(displayMessage('ERROR_5WH2_IMAGE_IS_MISSING'))
            return false
        }

        if (emptyString(nextStepsSecondText)) {
            showAlert(displayMessage('ERROR_NEXT_STEPS_SECOND_TEXT_IS_MISSING'))
            return false
        }

        if (reportConfigReferenceNormatives.length == 0) {
            showAlert(displayMessage('ERROR_REFERENCE_NORMATIVES_IS_MISSING'))
            return false
        }

        if (emptyString(technicalResponsabilityText)) {
            showAlert('ERROR_TECHNICAL_RESPONSABILITY_TEXT_IS_MISSING')
            return false
        }

        if (technicalResponsabilityImageText.length == 0) {
            showAlert(displayMessage('ERROR_TECHNICAL_RESPONSABILITY_IMAGE_TEXT_IS_MISSING'))
            return false
        }

        if (technicalResponsabilityImage.length == 0) {
            showAlert(displayMessage('ERROR_TECHNICAL_RESPONSABILITY_IMAGE_IS_MISSING'))
            return false
        }

        return true
    }

    const makeReportConfigObjectToCreate = () => {
        const formData = new FormData()

        const general_data = {
            title: reportConfigTitle,
            second_page_first_text: secondPageFirstText,
            second_page_middle_title: secondPageMiddleTitle,
            second_page_second_text: secondPageSecondText,
            main_goal_text: mainGoalText,
            main_specific_text: mainSpecificText,
            raised_risks_text: raisedRisksText,
            next_steps_first_text: nextStepsFirstText,
            next_steps_second_text: nextStepsSecondText,
            technical_resposability_text: technicalResponsabilityText,
        }

        formData.append('report_config_data', JSON.stringify(general_data))
        formData.append('references_normatives_data', JSON.stringify(reportConfigReferenceNormatives))

        formData.append(
            'first_page_cover_image_id',
            new File([firstPageCoverImage[0].originFileObj], firstPageCoverImage[0].name, { type: firstPageCoverImage[0].type })
        )
        formData.append(
            'second_page_header_image_id',
            new File([secondPageHeaderImage[0].originFileObj], secondPageHeaderImage[0].name, { type: secondPageHeaderImage[0].type })
        )
        formData.append(
            'probability_vs_seriousness_matrix_image_id',
            new File([probabilityVsSeriousnessMatrixImage[0].originFileObj], probabilityVsSeriousnessMatrixImage[0].name, {
                type: probabilityVsSeriousnessMatrixImage[0].type,
            })
        )
        formData.append(
            'next_steps_5w2h_image_id',
            new File([nextSteps5w2hImage[0].originFileObj], nextSteps5w2hImage[0].name, { type: nextSteps5w2hImage[0].type })
        )
        formData.append(
            'technical_resposability_image_id',
            new File([technicalResponsabilityImage[0].originFileObj], technicalResponsabilityImage[0].name, {
                type: technicalResponsabilityImage[0].type,
            })
        )
        formData.append(
            'final_image_message_id',
            new File([technicalResponsabilityImageText[0].originFileObj], technicalResponsabilityImageText[0].name, {
                type: technicalResponsabilityImageText[0].type,
            })
        )

        for (let i = 0; i < reportConfigReferenceNormatives.length; i++) {
            const reference_normative = reportConfigReferenceNormatives[i]

            formData.append(
                `reference_normative_${reference_normative.temporary_id}`,
                new File([reference_normative.logo.originFileObj], reference_normative.logo.name, { type: reference_normative.logo.type })
            )
        }

        return formData
    }

    const handleOnReportConfigCreate = async () => {
        setShowLoading(true)
        try {
            const isValid = validateBeforeSubmit()

            if (isValid) {
                const data = makeReportConfigObjectToCreate()
                const response = await api.reportConfigs.create(data)

                if (response.status == STATUS_SUCCESS) {
                    navigate('/settings')
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    return (
        <>
            <div className="flex justify-center text-lg">{displayMessage('REPORT_CONFIG_CREATION')}</div>
            <div>
                <div className="text-base mt-5">{displayMessage('REPORT_CONFIG_TITLE')}</div>
                <SL_Input
                    value={reportConfigTitle}
                    className="mt-2"
                    placeholder={displayMessage('REPORT_CONFIG_TITLE')}
                    onChange={e => setReportConfigTitle(e.target.value)}
                />
            </div>
            <Collapse ghost accordion size="large" className="mt-5">
                <Panel header={displayMessage('FIRST_PAGE')} key={FIRST_PAGE_PANEL_KEY}>
                    <div className="flex justify-end">
                        {!mobile_mode && (
                            <>
                                <Button type="primary" className="mt-5" onClick={() => setFirstPageTourIsOpen(true)}>
                                    {displayMessage('DEMONSTRATION')}
                                </Button>
                                <Tour open={firstPageTourIsOpen} onClose={() => setFirstPageTourIsOpen(false)} steps={first_page_tour_steps} />
                            </>
                        )}
                    </div>
                    <div ref={first_page_ref} className="inline-block">
                        {displayMessage('FIRST_PAGE_COVER_IMAGE')}
                    </div>
                    <div className="flex flex-row w-full text-center items-center mt-3">
                        <div className="flex flex-col">
                            <div className="flex w-full mt-3 gap-1">
                                <Upload
                                    beforeUpload={() => false}
                                    listType="picture-card"
                                    fileList={firstPageCoverImage}
                                    onChange={e => handleFileOnChange(e, setFirstPageCoverImage)}
                                    onPreview={handleOnFilePreview}
                                >
                                    {firstPageCoverImage?.length < 1 && '+'}
                                </Upload>
                            </div>
                        </div>
                        <div className="text-xs">
                            <p>{displayMessage('RECOMMENDATION_DIMENSIONS')}</p>
                            <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                        </div>
                    </div>
                </Panel>
                <Panel header={displayMessage('SECOND_PAGE')} key={SECOND_PAGE_PANEL_KEY}>
                    <div className="flex justify-end">
                        {!mobile_mode && (
                            <>
                                <Button type="primary" className="mt-5" onClick={() => setSecondPageTourIsOpen(true)}>
                                    {displayMessage('DEMONSTRATION')}
                                </Button>
                                <Tour open={secondPageTourIsOpen} onClose={() => setSecondPageTourIsOpen(false)} steps={second_page_tour_steps} />
                            </>
                        )}
                    </div>
                    <div ref={second_page_header_image_ref} className="inline-block">
                        {displayMessage('SECOND_PAGE_HEADER_IMAGE')}
                    </div>
                    <div className="flex flex-row w-full text-center items-center mt-3">
                        <div className="flex flex-col">
                            <div className="flex w-full mt-3 gap-1">
                                <Upload
                                    beforeUpload={() => false}
                                    listType="picture-card"
                                    fileList={secondPageHeaderImage}
                                    onChange={e => handleFileOnChange(e, setSecondPageHeaderImage)}
                                    onPreview={handleOnFilePreview}
                                >
                                    {secondPageHeaderImage?.length < 1 && '+'}
                                </Upload>
                            </div>
                        </div>
                        <div className="text-xs">
                            <p>{displayMessage('RECOMMENDATION_DIMENSIONS_FOR_OVERVIEW')}</p>
                            <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                        </div>
                    </div>
                    <div ref={second_page_first_text_ref} className="inline-block mt-5">
                        {displayMessage('SECOND_PAGE_FIRST_TEXT')}
                    </div>
                    <SL_Input
                        maxLength={null}
                        className="h-44"
                        textarea
                        value={secondPageFirstText}
                        onChange={e => setSecondPageFirstText(e.target.value)}
                    />
                    <div ref={second_page_middle_title_ref} className="inline-block mt-5">
                        {displayMessage('SECOND_PAGE_MIDDLE_TITLE')}
                    </div>
                    <SL_Input
                        maxLength={null}
                        className="h-44"
                        textarea
                        value={secondPageMiddleTitle}
                        onChange={e => setSecondPageMiddleTitle(e.target.value)}
                    />

                    <div ref={second_page_second_text_ref} className="inline-block mt-5">
                        {displayMessage('SECOND_PAGE_SECOND_TEXT')}
                    </div>
                    <SL_Input
                        maxLength={null}
                        className="h-44"
                        textarea
                        value={secondPageSecondText}
                        onChange={e => setSecondPageSecondText(e.target.value)}
                    />
                </Panel>
                <Panel header={displayMessage('OBJECTIVES')} key={MAIN_PANEL_KEY}>
                    <div className="flex justify-end">
                        {!mobile_mode && (
                            <>
                                <Button type="primary" className="mt-5" onClick={() => setMainTourIsOpen(true)}>
                                    {displayMessage('DEMONSTRATION')}
                                </Button>
                                <Tour open={mainTourIsOpen} onClose={() => setMainTourIsOpen(false)} steps={main_tour_steps} />
                            </>
                        )}
                    </div>
                    <div ref={main_goal_text_ref} className="inline-block">
                        {displayMessage('MAIN_GOAL_LABEL')}
                    </div>
                    <SL_Input maxLength={null} className="h-44" textarea value={mainGoalText} onChange={e => setMainGoalText(e.target.value)} />
                    <div ref={main_specific_text_ref} className="inline-block mt-5">
                        {displayMessage('MAIN_SPECIFIC_LABEL')}
                    </div>
                    <SL_Input
                        maxLength={null}
                        className="h-44"
                        textarea
                        value={mainSpecificText}
                        onChange={e => setMainSpecificText(e.target.value)}
                    />
                </Panel>
                <Panel header={displayMessage('PROBABILITY_X_GRAVITY')} key={PROBABILITY_VS_SERIOUSNESS_MATRIX_PANEL_KEY}>
                    <div className="flex justify-end">
                        {!mobile_mode && (
                            <>
                                <Button type="primary" className="mt-5" onClick={() => setProbabilityVsSeriousnessMatrixTourIsOpen(true)}>
                                    {displayMessage('DEMONSTRATION')}
                                </Button>
                                <Tour
                                    open={probabilityVsSeriousnessMatrixTourIsOpen}
                                    onClose={() => setProbabilityVsSeriousnessMatrixTourIsOpen(false)}
                                    steps={probability_vs_seriousness_matrix_steps}
                                />
                            </>
                        )}
                    </div>
                    <div ref={probability_vs_seriousness_matrix_image_ref} className="inline-block">
                        {displayMessage('PROBABILITY_X_SERIOUSNESS_MATRIX_IMAGE')}
                    </div>
                    <div className="flex flex-row w-full text-center items-center mt-3">
                        <div className="flex flex-col">
                            <div className="flex w-full mt-3 gap-1">
                                <Upload
                                    beforeUpload={() => false}
                                    listType="picture-card"
                                    fileList={probabilityVsSeriousnessMatrixImage}
                                    onChange={e => handleFileOnChange(e, setProbabilityVsSeriousnessMatrixImage)}
                                    onPreview={handleOnFilePreview}
                                >
                                    {probabilityVsSeriousnessMatrixImage?.length < 1 && '+'}
                                </Upload>
                            </div>
                        </div>
                        <div className="text-xs">
                            <p>{displayMessage('RECOMMENDATION_DIMENSIONS')}</p>
                            <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                        </div>
                    </div>
                </Panel>
                <Panel header={displayMessage('RAISED_RISKS')} key={RAISED_RISKS_PANEL_KEY}>
                    <div className="flex justify-end">
                        {!mobile_mode && (
                            <>
                                <Button type="primary" onClick={() => setRaisedRisksTourIsOpen(true)}>
                                    {displayMessage('DEMONSTRATION')}
                                </Button>
                                <Tour open={raisedRisksTourIsOpen} onClose={() => setRaisedRisksTourIsOpen(false)} steps={raised_risks_steps} />
                            </>
                        )}
                    </div>
                    <div ref={raised_risks_text_ref} className="inline-block mt-5">
                        {displayMessage('RAISED_RISKS_TEXT_LABEL')}
                    </div>
                    <SL_Input maxLength={null} className="h-44" textarea value={raisedRisksText} onChange={e => setRaisedRisksText(e.target.value)} />
                </Panel>
                <Panel header={displayMessage('NEXT_STEPS')} key={NEXT_STEPS_PANEL_KEY}>
                    <div className="flex justify-end">
                        {!mobile_mode && (
                            <>
                                <Button type="primary" onClick={() => setNextStepsTourIsOpen(true)}>
                                    {displayMessage('DEMONSTRATION')}
                                </Button>
                                <Tour open={nextStepsTourIsOpen} onClose={() => setNextStepsTourIsOpen(false)} steps={next_steps_tour_steps} />
                            </>
                        )}
                    </div>
                    <div ref={next_steps_first_text_ref} className="inline-block">
                        {displayMessage('NEXT_STEPS_FIRST_TEXT_LABEL')}
                    </div>
                    <SL_Input
                        maxLength={null}
                        className="h-44"
                        textarea
                        value={nextStepsFirstText}
                        onChange={e => setNextStepsFirstText(e.target.value)}
                    />

                    <div ref={next_steps_5w2h_image_ref} className="inline-block mt-3">
                        {displayMessage('NEXT_STEPS_5WH2_IMAGE_LABEL')}
                    </div>
                    <div className="flex flex-row w-full text-center items-center mt-3">
                        <div className="flex flex-col">
                            <div className="flex w-full mt-3 gap-1">
                                <Upload
                                    beforeUpload={() => false}
                                    listType="picture-card"
                                    fileList={nextSteps5w2hImage}
                                    onChange={e => handleFileOnChange(e, setNextSteps5w2hImage)}
                                    onPreview={handleOnFilePreview}
                                >
                                    {nextSteps5w2hImage?.length < 1 && '+'}
                                </Upload>
                            </div>
                        </div>
                        <div className="text-xs">
                            <p>{displayMessage('RECOMMENDATION_DIMENSIONS')}</p>
                            <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                        </div>
                    </div>
                    <div ref={next_steps_second_text_ref} className="inline-block">
                        {displayMessage('NEXT_STEPS_SECOND_TEXT_LABEL')}
                    </div>
                    <SL_Input
                        maxLength={null}
                        className="h-44"
                        textarea
                        value={nextStepsSecondText}
                        onChange={e => setNextStepsSecondText(e.target.value)}
                    />
                </Panel>
                <Panel header={displayMessage('REPORT_CONFIG_REFERENCES_NORMATIVES')} key={REPORT_CONFIG_REFERENCE_NORMATIVES_PANEL_KEY}>
                    <div className="flex justify-end">
                        {!mobile_mode && (
                            <>
                                <Button type="primary" onClick={() => setReportConfigReferenceNormativeTourIsOpen(true)}>
                                    {displayMessage('DEMONSTRATION')}
                                </Button>
                                <Tour
                                    open={reportConfigReferenceNormativeTourIsOpen}
                                    onClose={() => setReportConfigReferenceNormativeTourIsOpen(false)}
                                    steps={report_config_reference_normative_steps}
                                />
                            </>
                        )}
                    </div>
                    <Form
                        onFinish={handleOnNewReportConfigReferenceNormativeCreated}
                        className="max-w-[700px] w-full"
                        layout="vertical"
                        requiredMark={false}
                        form={form}
                        ref={reportConfigReferenceNormativesRef}
                    >
                        <div className="flex flex-col gap-1">
                            <div className="">{displayMessage('DESCRIPTION')}</div>
                            <Form.Item
                                name="description"
                                rules={[{ required: true, message: displayMessage('DISPLAY_DESCRIPTION') }]}
                                className="mb-4"
                            >
                                <SL_Input
                                    placeholder={displayMessage('DESCRIPTION')}
                                    value={reportConfigReferenceDescription}
                                    onChange={e => setReportConfigReferenceDescription(e.target.value)}
                                />
                            </Form.Item>
                        </div>

                        <div className="flex flex-col gap-1">
                            <div className="mt-5">{displayMessage('UPLOAD_LOGO_MESSAGE')}</div>
                            <div className="flex flex-row w-full text-center items-center mt-3">
                                <div className="flex flex-col">
                                    <div className="flex w-full mt-3 gap-1">
                                        <Upload
                                            beforeUpload={() => false}
                                            listType="picture-card"
                                            fileList={reportConfigReferenceLogoImage}
                                            onChange={e => handleFileOnChange(e, setReportConfigReferenceLogoImage)}
                                            onPreview={handleOnFilePreview}
                                        >
                                            {reportConfigReferenceLogoImage?.length < 1 && '+'}
                                        </Upload>
                                    </div>
                                </div>
                                <div className="text-xs">
                                    <p>{displayMessage('RECOMMENDATION_DIMENSIONS')}</p>
                                    <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                                </div>
                            </div>
                        </div>

                        <div ref={report_config_reference_normative_ref} className="flex flex-col gap-1">
                            <SL_Button onClick={() => reportConfigReferenceNormativesRef.current.submit()}>{displayMessage('ADD')}</SL_Button>
                        </div>
                    </Form>
                    {reportConfigReferenceNormatives.length > 0 && (
                        <List
                            size="large"
                            className="mt-5"
                            bordered
                            dataSource={reportConfigReferenceNormatives}
                            renderItem={item => (
                                <List.Item>
                                    <div className="w-full flex flex-row mt-1 justify-between">
                                        <div className="flex flex-row">
                                            <div>{item?.description}</div>
                                            <div className="ml-10">
                                                <img
                                                    src={item?.logo?.thumbUrl}
                                                    alt={item?.description}
                                                    style={{ height: '80%', width: '90%', borderRadius: '50%' }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="ml-5 cursor-pointer"
                                            onClick={() => handleOnRemoveReportConfigReferenceNormative(item.temporary_id)}
                                        >
                                            <CloseCircleOutlined style={{ fontSize: '20px' }} />
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    )}
                </Panel>
                <Panel header={displayMessage('TECHNICAL_RESPONSABILITY')} key={TECHNICAL_RESPONSABILITY_PANEL_KEY}>
                    <div className="flex flex-col gap-1 mt-1">
                        <div className="">{displayMessage('TECHNICAL_RESPONSABILITY_TEXT')}</div>
                        <SL_Input
                            maxLength={null}
                            className="h-44"
                            textarea
                            value={technicalResponsabilityText}
                            onChange={e => setTechnicalResponsabilityText(e.target.value)}
                        />
                    </div>

                    <div className="flex flex-col gap-1 mt-3">
                        <div className="">{displayMessage('FINAL_IMAGE_MESSAGE')}</div>
                        <div className="flex flex-row w-full text-center items-center">
                            <div className="flex flex-col">
                                <div className="flex w-full mt-3 gap-1">
                                    <Upload
                                        beforeUpload={() => false}
                                        listType="picture-card"
                                        fileList={technicalResponsabilityImageText}
                                        onChange={e => handleFileOnChange(e, setTechnicalResponsabilityImageText)}
                                        onPreview={handleOnFilePreview}
                                    >
                                        {technicalResponsabilityImageText?.length < 1 && '+'}
                                    </Upload>
                                </div>
                            </div>
                            <div className="text-xs">
                                <p>{displayMessage('RECOMMENDATION_DIMENSIONS_FOR_TECHNICAL_RESPONSABILITY')}</p>
                                <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 mt-1">
                        <div className="">{displayMessage('TECHNICAL_RESPONSABILITY_IMAGE')}</div>
                        <div className="flex flex-row w-full text-center items-center">
                            <div className="flex flex-col">
                                <div className="flex w-full mt-3 gap-1">
                                    <Upload
                                        beforeUpload={() => false}
                                        listType="picture-card"
                                        fileList={technicalResponsabilityImage}
                                        onChange={e => handleFileOnChange(e, setTechnicalResponsabilityImage)}
                                        onPreview={handleOnFilePreview}
                                    >
                                        {technicalResponsabilityImage?.length < 1 && '+'}
                                    </Upload>
                                </div>
                            </div>
                            <div className="text-xs">
                                <p>{displayMessage('RECOMMENDATION_DIMENSIONS_FOR_TECHNICAL_RESPONSABILITY')}</p>
                                <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                            </div>
                        </div>
                    </div>
                </Panel>

                <div className="w-full flex items-center justify-center mt-10">
                    <SL_Button className={'w-2/4'} onClick={handleOnReportConfigCreate}>
                        {displayMessage('CREATE_REPORT_CONFIG')}
                    </SL_Button>
                </div>
            </Collapse>
        </>
    )
}
