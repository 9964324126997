import { useRef, useEffect, useContext } from 'react'
import { Modal } from 'antd'

import { displayMessage } from '../../../../../helpers/messages'
import { GeneralContext } from '../../../../../context/GeneralContext/GeneralContext'
import { ImportAttachments } from '../../../../../components/attachments/ImportAttachments'

export function ModalAttachmentChoice({ fileInputRef = useRef(null), multiple = false, onChangeAttachments, onClose }) {
    const { mobile_mode } = useContext(GeneralContext)

    useEffect(() => {
        const handleBeforeUnload = () => {
            fileInputRef.current.value = null
        }
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    return (
        <Modal
            title={<span className="text-lg font-bold text-primary-900 p-5">{displayMessage('ATTACHMENTS')}</span>}
            open={true}
            footer={null}
            centered={true}
            onCancel={onClose}
            width={mobile_mode ? '90%' : '50%'}
        >
            <ImportAttachments onClose={onClose} onChangeAttachments={onChangeAttachments} />
        </Modal>
    )
}
