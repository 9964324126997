import { useEffect, useState, useRef, useContext } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import api from '../../../services/api'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import { Form, Popconfirm, Switch, Tabs } from 'antd'
import { SL_Select } from '../../../components/selects/Select'
import { SL_Button } from '../../../components/buttons/Button'
import EUserTypes from '../../../enums/EUserTypes'
import EActivationStatus from '../../../enums/EActivationStatus'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import styles from '../../../services/styles'
import { AuthContext } from '../../../context/AuthContext/AuthContext'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { SL_Grid } from '../../../components/grid/SL_Grid'
import { clients_grid_columns } from '../grid_columns/clients_grid_columns'
import CustomToolbar from '../../../components/grid/CustomToolbar'

export function ClientUserDetails() {
    const { clientUserId } = useParams()
    const { loggedUser } = useContext(AuthContext)
    const { mobile_mode } = useContext(GeneralContext)
    const navigate = useNavigate()
    const location = useLocation()
    const formRef = useRef()

    const [userInfos, setUserInfos] = useState()
    const [isActive, setIsActive] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [client, setClient] = useState([])
    const [rowOnFocus, setRowOnFocus] = useState(null)
    console.log(rowOnFocus)
    const [gridLoading, setGridLoading] = useState(false)

    useEffect(() => {
        loadClientUser()
        loadAssociatedClientUsers()
    }, [])

    const loadClientUser = async () => {
        setLoading(true)
        try {
            const { data } = await api.clientUsers.find(clientUserId)
            setUserInfos(data)
            setIsActive(data.is_active)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const loadAssociatedClientUsers = async () => {
        setGridLoading(true)
        try {
            const { data } = await api.clientUsersAssocition.list({ client_user_id: clientUserId })
            setClient(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const onSubmit = async clientUser => {
        setLoading(true)
        try {
            const auxUser = { ...clientUser, last_edited_by_user_id: loggedUser.id, is_active: isActive, client_user_id: clientUserId }

            const { data } = await api.clientUsers.update(clientUserId, auxUser)
            setUserInfos(data)

            navigate(-1)
        } catch (error) {
            console.log(error)
        } finally {
            setIsEditing(false)
            setLoading(false)
        }
    }

    const handleUserTypeChange = async e => {
        setUserInfos(prev => ({ ...prev, type: e }))
    }

    const desvinculateUser = async () => {
        try {
            const { data } = await api.clientUsers.desvinculate({
                client_id: rowOnFocus.client_id,
                client_user_id: clientUserId,
                client_user_type: userInfos.type,
            })
            console.log(data)
            await loadAssociatedClientUsers()
        } catch (error) {
            console.log(error)
        } finally {
            setRowOnFocus(null)
        }
    }

    return (
        <div>
            {loading ? (
                <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                    <SL_ClipLoader loading={true} />
                </div>
            ) : (
                <>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                key: 1,
                                label: displayMessage('USER_DETAILS'),
                                children: (
                                    <div>
                                        <div className="w-full flex flex-row justify-end">
                                            <div className="mr-2">{displayMessage('ENABLE_EDIT')}</div>
                                            <Switch checked={isEditing} onChange={e => setIsEditing(e)} />
                                        </div>
                                        <div className="w-full flex justify-center">
                                            <Form
                                                onFinish={onSubmit}
                                                className="max-w-[800px] w-full"
                                                layout="vertical"
                                                requiredMark={false}
                                                ref={formRef}
                                            >
                                                {mobile_mode ? (
                                                    <>
                                                        <div className="flex flex-1 flex-col gap-1">
                                                            <div className="">{displayMessage('FIRST_NAME')}</div>
                                                            <Form.Item
                                                                name="first_name"
                                                                initialValue={userInfos?.first_name}
                                                                rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]}
                                                                className="mb-4"
                                                            >
                                                                <SL_Input
                                                                    placeholder={displayMessage('FIRST_NAME')}
                                                                    className={isEditing ? 'w-full ' : 'w-full text-gray-400'}
                                                                    disabled={!isEditing}
                                                                />
                                                            </Form.Item>
                                                        </div>

                                                        <div className="flex flex-1 flex-col gap-1">
                                                            <div className="">{displayMessage('LAST_NAME')}</div>
                                                            <Form.Item
                                                                name="last_name"
                                                                initialValue={userInfos?.last_name}
                                                                rules={[{ required: true, message: displayMessage('DISPLAY_LAST_NAME') }]}
                                                                className="mb-6"
                                                            >
                                                                <SL_Input
                                                                    className={isEditing ? 'w-full ' : 'w-full text-gray-400'}
                                                                    placeholder={displayMessage('LAST_NAME')}
                                                                    disabled={!isEditing}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="flex flex-row justify-between">
                                                            <div className="flex flex-1 flex-col gap-1 mr-5">
                                                                <div className="">{displayMessage('FIRST_NAME')}</div>
                                                                <Form.Item
                                                                    name="first_name"
                                                                    initialValue={userInfos?.first_name}
                                                                    rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]}
                                                                    className="mb-4"
                                                                >
                                                                    <SL_Input
                                                                        placeholder={displayMessage('FIRST_NAME')}
                                                                        className={isEditing ? 'w-full ' : 'w-full text-gray-400'}
                                                                        disabled={!isEditing}
                                                                    />
                                                                </Form.Item>
                                                            </div>

                                                            <div className="flex flex-1 flex-col gap-1">
                                                                <div className="">{displayMessage('LAST_NAME')}</div>
                                                                <Form.Item
                                                                    name="last_name"
                                                                    initialValue={userInfos?.last_name}
                                                                    rules={[{ required: true, message: displayMessage('DISPLAY_LAST_NAME') }]}
                                                                    className="mb-6"
                                                                >
                                                                    <SL_Input
                                                                        className={isEditing ? 'w-full ' : 'w-full text-gray-400'}
                                                                        placeholder={displayMessage('LAST_NAME')}
                                                                        disabled={!isEditing}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="flex flex-col gap-1 mt-1">
                                                    <div>{displayMessage('EMAIL')}</div>
                                                    <Form.Item
                                                        name="email"
                                                        initialValue={userInfos?.email}
                                                        rules={[{ required: true, message: displayMessage('DISPLAY_EMAIL'), type: 'email' }]}
                                                        className="mb-6"
                                                    >
                                                        <SL_Input
                                                            className={isEditing ? 'w-full ' : 'w-full text-gray-400'}
                                                            placeholder={displayMessage('EMAIL')}
                                                            disabled={!isEditing}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div className="flex flex-col gap-1 mt-1">
                                                    <div>{displayMessage('USER_TYPE')}</div>
                                                    <Form.Item
                                                        name="type"
                                                        initialValue={userInfos?.type}
                                                        rules={[{ required: true, message: displayMessage('DISPLAY_USER_TYPE') }]}
                                                        className="mb-6"
                                                    >
                                                        <SL_Select
                                                            maxTagCount="responsive"
                                                            className="mb-1 h-[28px] rounded-sm"
                                                            listItemHeight={100}
                                                            title={displayMessage('USER_TYPE')}
                                                            placeholder={Object.values(EUserTypes).map(e => {
                                                                if (e.code == userInfos?.type) {
                                                                    return e.description
                                                                }
                                                            })}
                                                            onChange={handleUserTypeChange}
                                                            value={userInfos?.type}
                                                            options={Object.values(EUserTypes).map(e => ({
                                                                label: e.description,
                                                                value: e.code,
                                                            }))}
                                                            allowClear
                                                            disabled={!isEditing}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div className="flex flex-col gap-1 mt-1">
                                                    <div>{displayMessage('ACTIVATION_STATUS')}</div>
                                                    <SL_Input
                                                        placeholder={
                                                            isActive ? EActivationStatus.ATIVO.description : EActivationStatus.INATIVO.description
                                                        }
                                                        className={'w-full'}
                                                        disabled={true}
                                                    />
                                                </div>

                                                <div className="flex flex-col gap-1 mt-5">
                                                    <div>{displayMessage('ASSOCIATED_CLIENTS')}</div>

                                                    <SL_Grid
                                                        rows={client ?? []}
                                                        sortingMode={'server'}
                                                        components={{
                                                            Toolbar: () => (
                                                                <>
                                                                    <div className="flex justify-between flex-row ss:flex-row">
                                                                        <CustomToolbar />
                                                                        <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                                            <Popconfirm
                                                                                title={
                                                                                    'Isso ira remover o usuário de todos os endereços desta empresa. Deseja continuar?'
                                                                                }
                                                                                onConfirm={() => desvinculateUser(true)}
                                                                                onCancel={() => setRowOnFocus(null)}
                                                                            >
                                                                                <SL_Button className="my-1 mr-1" disabled={!rowOnFocus}>
                                                                                    {displayMessage('UNVINCULATE_USER')}
                                                                                </SL_Button>
                                                                            </Popconfirm>
                                                                            <SL_Button
                                                                                className="my-1 mr-1"
                                                                                disabled={!rowOnFocus}
                                                                                onClick={() =>
                                                                                    navigate(
                                                                                        `/clients/${rowOnFocus.client_id}/client-user/${clientUserId}`
                                                                                    )
                                                                                }
                                                                            >
                                                                                {displayMessage('BOND_DETAILS')}
                                                                            </SL_Button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ),
                                                        }}
                                                        disableFilter={false}
                                                        onRowClick={params => {
                                                            setRowOnFocus(params.row)
                                                        }}
                                                        columns={clients_grid_columns}
                                                        className="flex-1 min-h-[30vh] max-h-[50vh] overflow-y-auto"
                                                        loading={gridLoading}
                                                    />
                                                </div>

                                                <div className="flex flex-col gap-1 mt-5 mb-5">
                                                    <SL_Button
                                                        className="md-mx-0 rounder-l-none"
                                                        style={{
                                                            backgroundColor: !isEditing
                                                                ? 'gray'
                                                                : isActive
                                                                ? styles.colors['sl-red']['400']
                                                                : '#0e730d',
                                                            color: 'white',
                                                        }}
                                                        disabled={!isEditing}
                                                        onClick={() => setIsActive(prev => !prev)}
                                                    >
                                                        {isActive ? 'Desativar' : 'Ativar'}
                                                    </SL_Button>
                                                </div>

                                                <Form.Item hasFeedback className="mb-3 mt-2">
                                                    <SL_Button
                                                        disabled={!isEditing}
                                                        type="primary"
                                                        className={'w-full font-bold shadow-md'}
                                                        htmlType="submit"
                                                    >
                                                        {displayMessage('EDIT')}
                                                    </SL_Button>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </>
            )}
        </div>
    )
}
