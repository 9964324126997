import { DateTime } from 'luxon'
import { displayMessage } from '../../../helpers/messages'
import { formatCep } from '../../../helpers/helper'

export const client_addressess_grid_columns = [
    {
        field: 'cep',
        headerName: displayMessage('POSTAL_CODE'),
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.cep) return formatCep(row.cep)
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'state',
        headerName: displayMessage('STATE'),
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.state) return row.state
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'city',
        headerName: displayMessage('CITY'),
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.city) return row.city
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'neighbourhood',
        headerName: displayMessage('NEIGHBOURHOOD'),
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.neighbourhood) return row.neighbourhood
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'street',
        headerName: displayMessage('STREET'),
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.street) return row.street
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'number',
        headerName: displayMessage('NUMBER'),
        width: 100,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.number) return row.number
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'complement',
        headerName: displayMessage('COMPLEMENT'),
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.complement) return row.complement
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'createdAt',
        headerName: displayMessage('CREATED_AT'),
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.createdAt) return DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
]
