import { Button, Modal } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import api from '../../../services/api'

export function ModalConfirmationDeleteRisk({ isOpen, onClose, reloadRisks, reportRiskId }) {
    const handleOnDeleteRisk = async () => {
        try {
            await api.reportRisks.delete(reportRiskId)
        } catch (error) {
            console.log(error)
        } finally {
            await reloadRisks()
            onClose(false)
        }
    }

    return (
        <Modal
            title={displayMessage('ATENTION')}
            centered
            style={{ padding: 20 }}
            open={isOpen}
            onCancel={() => onClose(false)}
            footer={[
                <Button onClick={() => onClose(false)}>{displayMessage('CANCEL')}</Button>,
                <Button type="primary" onClick={handleOnDeleteRisk}>
                    {displayMessage('CONFIRM')}
                </Button>,
            ]}
            maskClosable={false}
        >
            <div className="flex w-full justify-center">
                <ExclamationCircleOutlined style={{ fontSize: 30 }} />
            </div>
            <p className="text-center mt-3">{displayMessage('DELETE_REPORT_RISK_CONFIRMATION_MESSAGE')}</p>
        </Modal>
    )
}
