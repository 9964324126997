import { DateTime } from 'luxon'
import { displayMessage } from '../../../helpers/messages'

export const severities_grid_columns = [
    {
        field: 'description',
        headerName: displayMessage('MODULE_DESCRIPTION'),
        type: 'string',
        width: 500,
    },
    {
        field: 'graduation',
        headerName: displayMessage('GRADUATION'),
        type: 'integer',
        width: 100,
    },
    {
        field: 'is_active',
        headerName: displayMessage('ACTIVATION_STATUS'),
        type: 'string',
        width: 150,
        renderCell: params => (params.row.is_active ? displayMessage('ACTIVE') : displayMessage('INACTIVE')),
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            return row.is_active ? displayMessage('ACTIVE') : displayMessage('INACTIVE')
        },
        type: 'singleSelect',
        valueOptions: [
            { value: true, label: 'Ativo' },
            { value: false, label: 'Inativo' },
        ],
    },
    {
        field: 'inactivation_date',
        headerName: displayMessage('INACTIVATION_DATE'),
        type: 'date',
        width: 200,
        renderCell: params => {
            if (params.row.inactivation_date !== null) {
                return <p>{DateTime.fromISO(params.row.inactivation_date).toFormat('dd/MM/yyyy HH:mm:ss')}</p>
            }

            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'createdAt',
        headerName: displayMessage('CREATED_AT'),
        type: 'date',
        width: 200,
        renderCell: params => {
            if (params.row.createdAt) {
                return <p>{DateTime.fromISO(params.row.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')}</p>
            }

            return displayMessage('EMPTY_01')
        },
    },
]
