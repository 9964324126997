import { Button, Card, Checkbox, Collapse, ConfigProvider, Image, Tooltip } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useContext, useEffect } from 'react'
import { useFetchReportData } from '../hooks/useFetchReportData'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { colors } from '../../../services/styles'
import api from '../../../services/api'

const { Panel } = Collapse

export function RisksDetailPanel({ risk, isConflictFromApp, setReportRiskIdToResolve, reportRiskIdToResolve }) {
    /**
     * 06/06/2024
     * A ideia é usar este componente para apresentar cada um dos dois riscos que ficarão em conflito
     * não consegui fazer mostar, imagino que ele está esperando outra formatação
     */

    useEffect(() => {
        console.log(risk)
    }, [])

    const {
        riskTypes,
        risks,
        strengths,
        frequencies,
        controlMesures,
        affectedPillars,
        priorityLevels,
        coverageScales,
        severities,
        detections,
        reportConfigs,
    } = useFetchReportData()

    const { mobile_mode, windowWidth } = useContext(GeneralContext)

    const handleOnSelectReportRisk = e => {
        if (e.target.checked) {
            setReportRiskIdToResolve(risk?.id)
        } else {
            setReportRiskIdToResolve(null)
        }
    }

    console.log(risk?.ReportRiskClientUsers)

    return (
        <ConfigProvider
            theme={{
                components: {
                    Card: {
                        headerBg: colors.primary[200],
                    },
                },
            }}
        >
            <Card
                title={
                    <div className="flex flex-row w-[600px] justify-between">
                        {isConflictFromApp ? displayMessage('APP') : displayMessage('PAINEL')}

                        <div>
                            <Checkbox onChange={handleOnSelectReportRisk} checked={reportRiskIdToResolve == risk?.id} />
                        </div>
                    </div>
                }
                className={`flex flex-col w-full ${windowWidth > 1800 ? 'h-[700px]' : 'h-[460px]'}`}
            >
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('RISK')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{risk?.Risk?.title ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('RECOMMENDATION')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{risk?.Recommendation?.title ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('NORMATIVES')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            {risk?.Risk?.Normatives?.length > 0 ? (
                                <div className="flex flex-col ml-2">
                                    {risk?.Risk?.Normatives.map((normatives, index) => {
                                        return (
                                            <div key={index}>
                                                {displayMessage('TITLE')}: {normatives?.title} | {displayMessage('DESCRIPTION')}:{' '}
                                                {normatives?.description}
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : (
                                displayMessage('EMPTY_01')
                            )}
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('CONTROL_MESURE')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{controlMesures.find(e => e.id == risk.control_mesure_id)?.name ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('FREQUENCY')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{frequencies.find(e => e.id == risk.frequency_id)?.name ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('DETECTION')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{detections.find(e => e.id == risk.detection_id)?.name ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('SEVERITY')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{severities.find(e => e.id == risk.severity_id)?.name ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('COVERAGE_SCALE')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{coverageScales.find(e => e.id == risk.coverage_scale_id)?.name ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('AFFECTED_PILLAR')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{affectedPillars.find(e => e.id == risk.affected_pillar_id)?.title ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('PRIORITY_LEVEL')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{risk?.PriorityLevel?.name ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('LOCATION')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{risk.location ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('OBSERVATION')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{risk.observations ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    {/* <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>
                            {displayMessage('RESPONSIBLE_TECHNICAL')}
                        </p>
                    </div> */}
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <div className="ml-2">
                                {risk.ReportRiskClientUsers.length > 0
                                    ? risk?.ReportRiskClientUsers?.map(rp => {
                                          return {
                                              label: `${rp?.ClientUser?.first_name} ${rp?.ClientUser?.last_name}`,
                                              value: rp?.ClientUser?.id,
                                          }

                                          // return <div key={rp.id}>{`${rp?.ClientUser?.first_name} ${rp?.ClientUser?.last_name}`}</div>
                                      })
                                    : displayMessage('EMPTY_01')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('WORK_SECTOR')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{risk?.WorkSector?.name ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('IMAGE')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex justify-start">
                            <Tooltip title={displayMessage('CLICK_IMAGE_TO_OPEN')}>
                                {risk?.image_id ? (
                                    <Image src={api.attachments.imageDownload(risk?.image_id)} width={45} />
                                ) : (
                                    displayMessage('EMPTY_01')
                                )}
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={`text-sm flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-3`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '30%' }}>
                        <p style={{ alignSelf: 'center' }}>{displayMessage('EVIDENCE_DESCRIPTION')}</p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">{risk.image_description ?? displayMessage('EMPTY_01')}</p>
                        </div>
                    </div>
                </div>
            </Card>
        </ConfigProvider>
    )
}
