import { Form, Popconfirm, Switch, Tabs, Upload } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { useContext, useEffect, useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import { CnpjMaskedInput } from '../../../helpers/iMask'
import { SL_Select } from '../../../components/selects/Select'
import api from '../../../services/api'
import { useNavigate, useParams } from 'react-router-dom'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import { SL_Button } from '../../../components/buttons/Button'
import EClientTypes from '../../../enums/EClientTypes'
import EActivationStatus from '../../../enums/EActivationStatus'
import styles from '../../../services/styles'
import { SL_Grid } from '../../../components/grid/SL_Grid'
import { client_users_grid_columns } from '../grid_columns/client_users_grid_columns'
import CustomToolbar from '../../../components/grid/CustomToolbar'
import ClientUsersFilters from './ClientUsersFilters'
import { ModalCreateClientUser, ModalVinculateClientUser } from './ModalVinculateClientUser'
import { ModalCreateWorkSector } from './ModalCreateWorkSector'
import { work_sector_grid_columns } from '../grid_columns/work_sector_grid_columns'
import WorkSectorsFilters from './WorkSectorsFilters'
import { client_addressess_grid_columns } from '../grid_columns/client_addresses_grid_columns'
import { ModalCreateClientAddress } from './ModalCreateClientAddress'
import ClientAddressFilters from './ClientAddressFilters'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import exportToExcel from '../../../services/export'

import defaultImage from '../../../assets/default_image.jpg'
import { DateTime } from 'luxon'
import { findUserTypeByCode, formatPhone } from '../../../helpers/helper'

const WORK_SECTORS_KEY = 2
const VINCULATED_USERS_KEY = 3
const CLIENT_ADDRESS_KEY = 4

export function ClientDetails({}) {
    const { clientId } = useParams()

    const { mobile_mode } = useContext(GeneralContext)

    const formRef = useRef()

    const formRefWorkSectors = useRef()
    const formRefClientUsers = useRef()
    const formRefClientAddresses = useRef()

    const [client, setClient] = useState(null)
    const [loading, setLoading] = useState(false)
    const [gridLoading, setGridLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const [clientAlreadyLoaded, setClientAlreadyLoaded] = useState(false)

    const [rowOnFocus, setRowOnFocus] = useState(null)

    const [isActive, setIsActive] = useState(null)

    const [workSectors, setWorkSectors] = useState(null)

    const [clientUsers, setClientUsers] = useState(null)

    const [clientAddresses, setClientAddressess] = useState(null)

    const [clientUsersAssociated, setClientUsersAssociated] = useState(null)

    const [logoImage, setLogoImage] = useState([])

    const [modalCreateWorkSectorIsOpen, setModalCreateWorkSectorIsOpen] = useState(false)
    const [modalVinculateClientUserIsOpen, setModalVinculateClientUserIsOpen] = useState(false)
    // const [modalUnvinculateClientUserIsOpen, setModalUnvinculateClientUserIsOpen] = useState(false)
    const [modalCreateClientAddressIsOpen, setModalCreateClientAddressIsOpen] = useState(false)

    const [defaultTab, setDefaultTab] = useState(1)

    const navigate = useNavigate()
    const cnpjMinLenght = 14

    // const [activeTabKey, setActiveTabKey] = useState(1);

    useEffect(() => {
        // selectDefaultTab()
        loadClient(), loadClientUsers()
        loadAssociatedClientUsers()
        loadWorkSectors()
        loadClientAddresses()
    }, [])

    useEffect(() => {
        if (client) {
            setClientAlreadyLoaded(true)
            return
        }

        setClientAlreadyLoaded(false)
    }, [client])

    useEffect(() => {
        setLogoImage([])
    }, [isEditing])

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const tab = query.get('tab')
        if (tab) {
            setDefaultTab(Number(tab))
        }
        handleOnTabChange(tab)
    }, [location.search])

    const loadClient = async () => {
        setLoading(true)
        try {
            const { data } = await api.client.find(clientId)

            setClient(data)
            setIsActive(data.is_active)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const loadWorkSectors = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.workSectors.list({ client_id: clientId, ...params })

            setWorkSectors(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const loadClientUsers = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.clientUsers.list(clientId)

            setClientUsers(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const loadAssociatedClientUsers = async () => {
        setGridLoading(true)
        try {
            const { data } = await api.clientUsersAssocition.list({ clientId })
            setClientUsersAssociated(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const loadClientAddresses = async params => {
        setGridLoading(true)
        try {
            const auxParams = { client_id: clientId, ...params }

            const { data } = await api.addresses.list(auxParams)
            const ActiveAddresses = data.filter(e => e.deletedAt == null)
            setClientAddressess(ActiveAddresses)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const onSubmit = async client => {
        setLoading(true)
        try {
            const auxClient = { ...client, is_active: isActive }

            const formData = new FormData()

            formData.append('client_data', JSON.stringify(auxClient))

            if (logoImage.length > 0) {
                const file = new File([logoImage[0].originFileObj], logoImage[0].name, { type: logoImage[0].type })

                formData.append('attachments', file)
            }
            await api.client.update(clientId, formData)
            await loadClient()
        } catch (error) {
            console.log(error)
        } finally {
            setIsEditing(false)
            setLoading(false)
        }
    }

    const handleClientTypeChange = async e => {
        setClient(prev => ({ ...prev, type: e }))
    }

    const handleFileOnChange = ({ fileList: newFileList }) => {
        setLogoImage(newFileList)
    }

    const handleOnFilePreview = async file => {
        let src = file.url

        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()

                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }

        const image = new Image()
        image.src = src

        const imgWindow = window.open(src)
        imgWindow?.document.write(image.outerHTML)
    }

    const handleOnTabChange = async key => {
        if (key == WORK_SECTORS_KEY) {
            await loadWorkSectors()
            return
        }

        if (key == VINCULATED_USERS_KEY) {
            await loadClientUsers()
            return
        }

        if (key == CLIENT_ADDRESS_KEY) {
            await loadClientAddresses()
            return
        }

        return
    }

    const desvinculateUser = async () => {
        try {
            const { data } = await api.clientUsers.desvinculate({
                client_id: clientId,
                client_user_id: rowOnFocus.client_user_id,
                client_user_type: rowOnFocus.ClientUser.type,
            })
            console.log(data)
            await loadAssociatedClientUsers()
        } catch (error) {
            console.log(error)
        } finally {
            setRowOnFocus(null)
        }
    }

    return (
        <>
            <div>
                {loading ? (
                    <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                        <SL_ClipLoader loading={true} />
                    </div>
                ) : (
                    <>
                        {modalCreateWorkSectorIsOpen && (
                            <ModalCreateWorkSector
                                isOpen={modalCreateWorkSectorIsOpen}
                                onClose={setModalCreateWorkSectorIsOpen}
                                reloadWorkSectors={loadWorkSectors}
                                client_id={clientId}
                            />
                        )}
                        {modalVinculateClientUserIsOpen && (
                            <ModalVinculateClientUser
                                isOpen={modalVinculateClientUserIsOpen}
                                onClose={setModalVinculateClientUserIsOpen}
                                reloadClientUsers={loadAssociatedClientUsers}
                                rowOnFocus={rowOnFocus}
                                workSector={workSectors}
                                clientAddress={clientAddresses}
                                currentClientUsers={clientUsersAssociated}
                            />
                        )}

                        {modalCreateClientAddressIsOpen && (
                            <ModalCreateClientAddress
                                isOpen={modalCreateClientAddressIsOpen}
                                onClose={setModalCreateClientAddressIsOpen}
                                reloadClientAdressess={loadClientAddresses}
                            />
                        )}
                        <Tabs
                            defaultActiveKey={defaultTab}
                            onChange={handleOnTabChange}
                            items={[
                                {
                                    key: 1,
                                    label: displayMessage('CLIENT_DETAILS'),
                                    children: (
                                        <div>
                                            <div className="w-full flex flex-row justify-end">
                                                <div className="mr-2">{displayMessage('ENABLE_EDIT')}</div>
                                                <Switch onChange={e => setIsEditing(e)} />
                                            </div>
                                            <div className={`w-full flex ${mobile_mode ? 'flex-col' : 'flex-row'} justify-between`}>
                                                <div className="w-full flex justify-center">
                                                    <Form
                                                        onFinish={onSubmit}
                                                        className="max-w-[500px] w-full"
                                                        layout="vertical"
                                                        requiredMark={false}
                                                        ref={formRef}
                                                    >
                                                        <div className="flex flex-col gap-1">
                                                            <div className="">{displayMessage('CLIENT_NAME')}</div>

                                                            <Form.Item
                                                                name="name"
                                                                initialValue={client?.name}
                                                                rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]}
                                                                className="mb-4"
                                                            >
                                                                <SL_Input
                                                                    placeholder={displayMessage('CLIENT_NAME')}
                                                                    className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                    disabled={!isEditing}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="flex flex-col gap-1 mt-1">
                                                            <div className="">{displayMessage('COMPANY_DOCUMENT')}</div>

                                                            <Form.Item
                                                                name="cnpj"
                                                                initialValue={client?.cnpj}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: displayMessage('COMPANY_CNPJ_LENGTH'),
                                                                        validator: (_, value) => {
                                                                            const cnpjDigits = value.replace(/[^\d]/g, '')
                                                                            return cnpjDigits.length === cnpjMinLenght
                                                                                ? Promise.resolve()
                                                                                : Promise.reject()
                                                                        },
                                                                    },
                                                                ]}
                                                                className="mb-6"
                                                            >
                                                                {CnpjMaskedInput({
                                                                    placeholder: displayMessage('COMPANY_DOCUMENT'),
                                                                    disabled: !isEditing,
                                                                    value: client?.cnpj,
                                                                })}
                                                            </Form.Item>
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <div className="">{displayMessage('CNAE')}</div>

                                                            <Form.Item
                                                                name="cnae"
                                                                initialValue={client?.cnae}
                                                                rules={[{ required: true, message: displayMessage('DISPLAY_CNAE') }]}
                                                                className="mb-4"
                                                            >
                                                                <SL_Input
                                                                    placeholder={displayMessage('CNAE')}
                                                                    className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                    disabled={!isEditing}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        {/* <div className="flex flex-col gap-1 mt-1">
                                                            <div className="">{displayMessage('CLIENT_TYPE')}</div>

                                                            <Form.Item
                                                                name="type"
                                                                initialValue={client?.type}
                                                                rules={[{ required: true, message: displayMessage('DISPLAY_USER_TYPE') }]}
                                                                className="mb-6"
                                                            >
                                                                <SL_Select
                                                                    maxTagCount="responsive"
                                                                    className="mb-1 h-[28px] rounded-sm"
                                                                    listItemHeight={100}
                                                                    title={displayMessage('USER_TYPE')}
                                                                    placeholder={Object.values(EClientTypes).map(e => {
                                                                        if (e.code == client?.type) {
                                                                            return e.description
                                                                        }
                                                                    })}
                                                                    onChange={handleClientTypeChange}
                                                                    value={client?.type}
                                                                    options={Object.values(EClientTypes).map(e => ({
                                                                        label: e.description,
                                                                        value: e.code,
                                                                    }))}
                                                                    allowClear
                                                                    disabled={!isEditing}
                                                                />
                                                            </Form.Item>
                                                        </div> */}

                                                        <div className="flex flex-col gap-1 mt-1">
                                                            <div className="">{displayMessage('ACTIVATION_STATUS')}</div>
                                                            <SL_Input
                                                                placeholder={
                                                                    isActive
                                                                        ? EActivationStatus.ATIVO.description
                                                                        : EActivationStatus.INATIVO.description
                                                                }
                                                                className={'w-full'}
                                                                disabled={true}
                                                            />
                                                        </div>

                                                        <div className="flex flex-col gap-1 mt-5 mb-5">
                                                            <SL_Button
                                                                className="md-mx-0 rounder-l-none"
                                                                style={{
                                                                    backgroundColor: !isEditing
                                                                        ? 'gray'
                                                                        : isActive
                                                                        ? styles.colors['sl-red']['400']
                                                                        : '#0e730d',
                                                                    color: 'white',
                                                                }}
                                                                disabled={!isEditing}
                                                                onClick={() => setIsActive(prev => !prev)}
                                                            >
                                                                {isActive ? 'Desativar' : 'Ativar'}
                                                            </SL_Button>
                                                        </div>

                                                        <Form.Item hasFeedback className="mb-3 mt-2">
                                                            <SL_Button
                                                                disabled={!isEditing}
                                                                type="primary"
                                                                className={'w-full font-bold shadow-md '}
                                                                htmlType="submit"
                                                            >
                                                                {displayMessage('EDIT')}
                                                            </SL_Button>
                                                        </Form.Item>
                                                    </Form>
                                                </div>
                                                <div className="w-full flex flex-col justify-center" style={{ alignItems: 'center' }}>
                                                    <p>{displayMessage('CLIENT_LOGO')}</p>
                                                    {clientAlreadyLoaded ? (
                                                        client?.logo_attachment_id != null ? (
                                                            <img
                                                                src={api.attachments.imageDownload(client?.logo_attachment_id)}
                                                                className="w-[300px] h-[162px]"
                                                                onError={e => console.log('error image: ', e)}
                                                            />
                                                        ) : (
                                                            <img src={defaultImage} className="w-[300px] h-[200px]" />
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {isEditing ? (
                                                        <div className="mt-10">
                                                            <p>{displayMessage('EDIT_LOGO')}</p>
                                                            <div className="w-full flex justify-center text-center align-middle mt-5">
                                                                <Upload
                                                                    style={{ alignSelf: 'center' }}
                                                                    beforeUpload={() => false}
                                                                    listType="picture-card"
                                                                    fileList={logoImage}
                                                                    onChange={handleFileOnChange}
                                                                    onPreview={handleOnFilePreview}
                                                                >
                                                                    {logoImage.length < 1 && '+'}
                                                                </Upload>
                                                                <div className="text-xs mt-2 text-center w-40">
                                                                    <p>{displayMessage('RECOMMENDATION_DIMENSIONS')}</p>
                                                                    <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    key: WORK_SECTORS_KEY,
                                    label: displayMessage('CLIENT_WORK_SECTORS'),
                                    children: (
                                        <>
                                            <WorkSectorsFilters
                                                formRef={formRefWorkSectors}
                                                loadSectors={loadWorkSectors}
                                                setGridLoading={setGridLoading}
                                                setWorkSectors={setWorkSectors}
                                            />
                                            <SL_Grid
                                                rows={workSectors ?? []}
                                                sortingMode={'server'}
                                                components={{
                                                    Toolbar: () => (
                                                        <>
                                                            <div className="flex justify-between flex-row ss:flex-row">
                                                                <CustomToolbar />
                                                                <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                                    <SL_Button
                                                                        className="my-1 mr-1"
                                                                        disabled={!rowOnFocus}
                                                                        onClick={() => navigate(`/clients/${clientId}/work-sector/${rowOnFocus.id}`)}
                                                                    >
                                                                        {displayMessage('WORK_SECTOR_DETAILS')}
                                                                    </SL_Button>

                                                                    <SL_Button
                                                                        className="my-1 mr-1"
                                                                        onClick={() => setModalCreateWorkSectorIsOpen(true)}
                                                                    >
                                                                        {displayMessage('WORK_SECTOR_CREATION')}
                                                                    </SL_Button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ),
                                                }}
                                                disableFilter={false}
                                                onRowClick={params => {
                                                    setRowOnFocus(params.row)
                                                }}
                                                columns={work_sector_grid_columns}
                                                className="flex-1 min-h-[61vh] max-h-[100vh]"
                                                loading={gridLoading}
                                            />
                                        </>
                                    ),
                                },
                                {
                                    key: VINCULATED_USERS_KEY,
                                    label: displayMessage('VINCULATED_USERS'),
                                    children: (
                                        <>
                                            <div>
                                                <ClientUsersFilters
                                                    loadClientsUsers={loadClientUsers}
                                                    loadAssociatedClientsUsers={loadAssociatedClientUsers}
                                                    setGridLoading={setGridLoading}
                                                    setClientUsers={setClientUsers}
                                                    formRef={formRefWorkSectors}
                                                />
                                                <SL_Grid
                                                    rows={clientUsersAssociated ?? []}
                                                    sortingMode={'server'}
                                                    components={{
                                                        Toolbar: () => (
                                                            <>
                                                                <div className="flex justify-between flex-row ss:flex-row">
                                                                    <CustomToolbar />
                                                                    <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                                        <Popconfirm
                                                                            title={
                                                                                'Isso ira remover o usuário de todos os endereços desta empresa. Deseja continuar?'
                                                                            }
                                                                            onConfirm={() => desvinculateUser(true)}
                                                                            onCancel={() => setRowOnFocus(null)}
                                                                        >
                                                                            <SL_Button className="my-1 mr-1" disabled={!rowOnFocus}>
                                                                                {displayMessage('UNVINCULATE_USER')}
                                                                            </SL_Button>
                                                                        </Popconfirm>
                                                                        <SL_Button
                                                                            className="my-1 mr-1"
                                                                            disabled={!rowOnFocus}
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `/clients/${clientId}/client-user/${rowOnFocus.client_user_id}?client`
                                                                                )
                                                                            }
                                                                        >
                                                                            {displayMessage('BOND_DETAILS')}
                                                                        </SL_Button>
                                                                        <SL_Button
                                                                            className="my-1 mr-1"
                                                                            onClick={() => setModalVinculateClientUserIsOpen(true)}
                                                                        >
                                                                            {displayMessage('VINCULATE_USER')}
                                                                        </SL_Button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ),
                                                    }}
                                                    disableFilter={false}
                                                    onRowClick={params => {
                                                        setRowOnFocus(params.row)
                                                    }}
                                                    columns={client_users_grid_columns}
                                                    className="flex-1 min-h-[61vh] max-h-[100vh]"
                                                    loading={gridLoading}
                                                />
                                            </div>
                                        </>
                                    ),
                                },
                                {
                                    key: CLIENT_ADDRESS_KEY,
                                    label: displayMessage('CLIENT_ADDRESSES'),
                                    children: (
                                        <>
                                            <div>
                                                <ClientAddressFilters
                                                    loadClientAddress={loadClientAddresses}
                                                    setGridLoading={setGridLoading}
                                                    setClientAddresses={setClientAddressess}
                                                    formRef={formRefClientAddresses}
                                                />
                                                <SL_Grid
                                                    rows={clientAddresses ?? []}
                                                    sortingMode={'server'}
                                                    components={{
                                                        Toolbar: () => (
                                                            <>
                                                                <div className="flex justify-between flex-row ss:flex-row">
                                                                    <CustomToolbar />
                                                                    <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                                        <SL_Button
                                                                            className="my-1 mr-1"
                                                                            disabled={!rowOnFocus}
                                                                            onClick={() =>
                                                                                navigate(`/clients/${clientId}/client-address/${rowOnFocus.id}`)
                                                                            }
                                                                        >
                                                                            {displayMessage('CLIENT_ADDRESS_DETAILS')}
                                                                        </SL_Button>

                                                                        <SL_Button
                                                                            className="my-1 mr-1"
                                                                            onClick={() => setModalCreateClientAddressIsOpen(true)}
                                                                        >
                                                                            {displayMessage('CLIENT_ADDRESS_CREATION')}
                                                                        </SL_Button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ),
                                                    }}
                                                    disableFilter={false}
                                                    onRowClick={params => {
                                                        setRowOnFocus(params.row)
                                                    }}
                                                    columns={client_addressess_grid_columns}
                                                    className="flex-1 min-h-[61vh] max-h-[100vh]"
                                                    loading={gridLoading}
                                                />
                                            </div>
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </>
                )}
            </div>
        </>
    )
}
