import { DateTime } from 'luxon'
import { displayMessage } from '../../../helpers/messages'
import { findUserTypeByCode, formatCpf } from '../../../helpers/helper'

export const users_grid_columns = [
    {
        field: 'first_name',
        headerName: displayMessage('FIRST_NAME'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.first_name) return row.first_name
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'last_name',
        headerName: displayMessage('LAST_NAME'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.last_name) return row.last_name
            return displayMessage('EMPTY_01')
        },
    },

    {
        field: 'crea',
        headerName: displayMessage('CREA'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.crea) return row.crea
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'assignments',
        headerName: displayMessage('ASSIGNMENTS'),
        type: 'string',
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (typeof row.assignments != 'object') return row.assignments
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'email',
        headerName: displayMessage('EMAIL'),
        type: 'string',
        width: 350,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.email) return row.email
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'type',
        headerName: displayMessage('USER_TYPE'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.type) {
                const userType = findUserTypeByCode(row.type)
                return userType.description
            }
            return displayMessage('EMPTY_01')
        },
        valueGetter: params => {
            const userType = findUserTypeByCode(params.row.type)
            return userType.description
        },
    },
    {
        field: 'is_active',
        headerName: 'Status de ativação',
        width: 150,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            return row.is_active ? displayMessage('ACTIVE') : displayMessage('INACTIVE')
        },
        valueGetter: params => (params.row.is_active ? displayMessage('ACTIVE') : displayMessage('INACTIVE')),
    },
    {
        field: 'createdAt',
        headerName: displayMessage('CREATED_AT'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.createdAt) return DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
]
