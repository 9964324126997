import { Form, Switch, Tabs, Upload } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { useContext, useEffect, useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import { SL_Select } from '../../../components/selects/Select'
import EUserTypes from '../../../enums/EUserTypes'
import api from '../../../services/api'
import { useParams } from 'react-router-dom'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import { SL_Button } from '../../../components/buttons/Button'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import defaultImage from '../../../assets/default_image.jpg'
import EActivationStatus from '../../../enums/EActivationStatus'
import styles from '../../../services/styles'

export function UserDetails({}) {
    const { userId } = useParams()

    const { mobile_mode } = useContext(GeneralContext)

    const formRef = useRef()

    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [signatureImage, setSignatureImage] = useState([])

    const [isActive, setIsActive] = useState(null)
    const [assignments, setAssignments] = useState(null)

    const [userAlreadyLoaded, setUserAlreadyLoaded] = useState(false)

    useEffect(() => {
        loadUser()
    }, [])

    useEffect(() => {
        if (user) {
            setUserAlreadyLoaded(true)
            return
        }

        setUserAlreadyLoaded(false)
    }, [user])

    useEffect(() => {
        setSignatureImage([])
    }, [isEditing])

    const loadUser = async () => {
        setLoading(true)
        try {
            const { data } = await api.user.find(userId)

            setUser(data)
            setIsActive(data.is_active)

            if (Object.values(data.assignments)?.length > 0) {
                setAssignments(data.assignments.toString())
            } else {
                setAssignments(null)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const onSubmit = async e => {
        setLoading(true)
        try {
            const formData = new FormData()

            formData.append('user_data', JSON.stringify({ ...e, is_active: isActive }))

            if (signatureImage.length > 0) {
                const file = new File([signatureImage[0].originFileObj], signatureImage[0].name, { type: signatureImage[0].type })
                formData.append('attachments', file)
            }

            const user = await api.user.update(userId, formData)
            setUser(user)
        } catch (error) {
            console.log(error)
        } finally {
            setIsEditing(false)
            setLoading(false)
            await loadUser()
        }
    }

    const handleUserTypeChange = async e => {
        setUser(prev => ({ ...prev, type: e }))
    }

    const handleFileOnChange = ({ fileList: newFileList }) => {
        setSignatureImage(newFileList)
    }

    const handleOnFilePreview = async file => {
        let src = file.url

        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()

                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }

        const image = new Image()
        image.src = src

        const imgWindow = window.open(src)
        imgWindow?.document.write(image.outerHTML)
    }

    return (
        <>
            <div>
                {loading ? (
                    <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                        <SL_ClipLoader loading={true} />
                    </div>
                ) : (
                    <>
                        <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    key: 1,
                                    label: displayMessage('USER_DETAILS'),
                                    children: (
                                        <div>
                                            <div className="w-full flex flex-row justify-end">
                                                <div className="mr-2">{displayMessage('ENABLE_EDIT')}</div>
                                                <Switch onChange={e => setIsEditing(e)} />
                                            </div>
                                            <div className={`w-full flex ${mobile_mode ? 'flex-col' : 'flex-row'} justify-between`}>
                                                <div className="w-full flex justify-center">
                                                    <Form
                                                        onFinish={onSubmit}
                                                        className="max-w-[500px] w-full"
                                                        layout="vertical"
                                                        requiredMark={false}
                                                        ref={formRef}
                                                    >
                                                        <div className="flex flex-col gap-1">
                                                            <div className="">{displayMessage('FIRST_NAME')}</div>

                                                            <Form.Item
                                                                name="first_name"
                                                                initialValue={user?.first_name}
                                                                rules={[{ required: true, message: displayMessage('DISPLAY_NAME') }]}
                                                                className="mb-4"
                                                            >
                                                                <SL_Input
                                                                    placeholder={displayMessage('FIRST_NAME')}
                                                                    className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                    disabled={!isEditing}
                                                                />
                                                            </Form.Item>
                                                        </div>

                                                        <div className="flex flex-col gap-1 mt-1">
                                                            <div className="">{displayMessage('LAST_NAME')}</div>

                                                            <Form.Item
                                                                name="last_name"
                                                                initialValue={user?.last_name}
                                                                rules={[{ required: true, message: displayMessage('DISPLAY_LAST_NAME') }]}
                                                                className="mb-6"
                                                            >
                                                                <SL_Input
                                                                    className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                    placeholder={displayMessage('LAST_NAME')}
                                                                    disabled={!isEditing}
                                                                />
                                                            </Form.Item>
                                                        </div>

                                                        <div className="flex flex-col gap-1 mt-1">
                                                            <div className="">{displayMessage('EMAIL')}</div>

                                                            <Form.Item
                                                                name="email"
                                                                initialValue={user?.email}
                                                                rules={[{ required: true, message: displayMessage('DISPLAY_EMAIL') }]}
                                                                className="mb-6"
                                                            >
                                                                <SL_Input
                                                                    className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                    placeholder={displayMessage('EMAIL')}
                                                                    disabled={!isEditing}
                                                                />
                                                            </Form.Item>
                                                        </div>

                                                        <div className="flex flex-col gap-1 mt-1">
                                                            <div className="">{displayMessage('CREA')}</div>

                                                            <Form.Item
                                                                name="crea"
                                                                initialValue={user?.crea}
                                                                rules={[{ message: displayMessage('DISPLAY_EMAIL') }]}
                                                                className="mb-6"
                                                            >
                                                                <SL_Input
                                                                    className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                    placeholder={displayMessage('CREA')}
                                                                    disabled={!isEditing}
                                                                />
                                                            </Form.Item>
                                                        </div>

                                                        <div className="flex flex-col gap-1 mt-1">
                                                            <div className="">{displayMessage('USER_TYPE')}</div>

                                                            <Form.Item
                                                                name="type"
                                                                initialValue={user?.type}
                                                                rules={[{ required: true, message: displayMessage('DISPLAY_USER_TYPE') }]}
                                                                className="mb-6"
                                                            >
                                                                <SL_Select
                                                                    maxTagCount="responsive"
                                                                    className="mb-1 h-[28px] rounded-sm"
                                                                    listItemHeight={100}
                                                                    title={displayMessage('USER_TYPE')}
                                                                    placeholder={Object.values(EUserTypes).map(e => {
                                                                        if (e.code == user?.type) {
                                                                            return e.description
                                                                        }
                                                                    })}
                                                                    onChange={handleUserTypeChange}
                                                                    value={user?.type}
                                                                    options={Object.values(EUserTypes).map(e => ({
                                                                        label: e.description,
                                                                        value: e.code,
                                                                    }))}
                                                                    allowClear
                                                                    disabled={!isEditing}
                                                                />
                                                            </Form.Item>
                                                        </div>

                                                        <div className="flex flex-col gap-1 mt-1">
                                                            <div className="">{displayMessage('ASSIGNMENTS')}</div>
                                                            <Form.Item
                                                                name="assignments"
                                                                initialValue={assignments}
                                                                className="mb-6"
                                                                rules={[{ required: true, message: displayMessage('DISPLAY_ASSIGNMENTS') }]}
                                                            >
                                                                <SL_Input
                                                                    className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                    placeholder={displayMessage('ASSIGNMENTS')}
                                                                    disabled={!isEditing}
                                                                />
                                                            </Form.Item>
                                                        </div>

                                                        <div className="flex flex-col gap-1 mt-1">
                                                            <div className="">{displayMessage('ACTIVATION_STATUS')}</div>

                                                            <SL_Input
                                                                placeholder={
                                                                    isActive
                                                                        ? EActivationStatus.ATIVO.description
                                                                        : EActivationStatus.INATIVO.description
                                                                }
                                                                className={'w-full'}
                                                                disabled={true}
                                                            />
                                                        </div>

                                                        <div className="flex flex-col gap-1 mt-5 mb-5">
                                                            <SL_Button
                                                                className="md-mx-0 rounder-l-none"
                                                                style={{
                                                                    backgroundColor: !isEditing
                                                                        ? 'gray'
                                                                        : isActive
                                                                        ? styles.colors['sl-red']['400']
                                                                        : '#0e730d',
                                                                    color: 'white',
                                                                }}
                                                                disabled={!isEditing}
                                                                onClick={() => setIsActive(prev => !prev)}
                                                            >
                                                                {isActive ? 'Desativar' : 'Ativar'}
                                                            </SL_Button>
                                                        </div>

                                                        <Form.Item hasFeedback className="mb-3 mt-2">
                                                            <SL_Button
                                                                disabled={!isEditing}
                                                                type="primary"
                                                                className={'w-full font-bold shadow-md'}
                                                                htmlType="submit"
                                                            >
                                                                {displayMessage('EDIT')}
                                                            </SL_Button>
                                                        </Form.Item>
                                                    </Form>
                                                </div>
                                                <div className="w-full flex flex-col justify-center" style={{ alignItems: 'center' }}>
                                                    <p>{displayMessage('USER_SIGNATURE')}</p>
                                                    {userAlreadyLoaded ? (
                                                        user?.signature_attachment_id != null ? (
                                                            <img
                                                                src={api.attachments.imageDownload(user?.signature_attachment_id)}
                                                                className="w-[300px] h-[162px]"
                                                            ></img>
                                                        ) : (
                                                            <img src={defaultImage} className="w-[300px] h-[200px]"></img>
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {isEditing ? (
                                                        <div className="mt-10">
                                                            <p>{displayMessage('EDIT_SIGNATURE')}</p>
                                                            <div className="w-full flex justify-center text-center align-middle mt-5">
                                                                <Upload
                                                                    style={{ alignSelf: 'center' }}
                                                                    beforeUpload={() => false}
                                                                    listType="picture-card"
                                                                    fileList={signatureImage}
                                                                    onChange={handleFileOnChange}
                                                                    onPreview={handleOnFilePreview}
                                                                >
                                                                    {signatureImage.length < 1 && '+'}
                                                                </Upload>
                                                                <div className="text-xs mt-2 text-center w-40">
                                                                    <p>{displayMessage('RECOMMENDATION_DIMENSIONS')}</p>
                                                                    <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </>
                )}
            </div>
        </>
    )
}
