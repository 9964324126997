import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../../services/api'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import { Form, Switch } from 'antd'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import EActivationStatus from '../../../enums/EActivationStatus'
import { SL_Button } from '../../../components/buttons/Button'
import styles from '../../../services/styles'

export function DetectionDetails({}) {
    const { detectionId } = useParams()

    const navigate = useNavigate()

    const formRef = useRef()

    const [detection, setDetection] = useState(null)
    const [loading, setLoading] = useState(false)

    const [isEditing, setIsEditing] = useState(false)

    const [isActive, setIsActive] = useState(null)

    useEffect(() => {
        loadDetection()
    }, [])

    const loadDetection = async () => {
        setLoading(true)
        try {
            const { data } = await api.detections.find(detectionId)

            setDetection(data)
            setIsActive(data.is_active)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const onSubmit = async e => {
        setLoading(true)
        try {
            const auxDetection = { ...e, is_active: isActive }
            await api.detections.update(detectionId, auxDetection)
            navigate('/probability-x-gravity')
        } catch (error) {
            console.log(error)
        } finally {
            setIsEditing(false)
            setLoading(false)
        }
    }

    return (
        <>
            <div>
                {loading ? (
                    <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                        <SL_ClipLoader loading={true} />
                    </div>
                ) : (
                    <>
                        <div className="w-full flex flex-row justify-end">
                            <div className="mr-2">{displayMessage('ENABLE_EDIT')}</div>
                            <Switch onChange={e => setIsEditing(e)} />
                        </div>
                        <div className="w-full flex justify-center">
                            <Form onFinish={onSubmit} className="max-w-[700px] w-full" layout="vertical" requiredMark={false} ref={formRef}>
                                <div className="flex flex-col gap-1">
                                    <div className="">{displayMessage('MODULE_DESCRIPTION')}</div>

                                    <Form.Item
                                        name="description"
                                        initialValue={detection?.description}
                                        rules={[{ required: true, message: displayMessage('DISPLAY_DESCRIPTION') }]}
                                        className="mb-4"
                                    >
                                        <SL_Input
                                            placeholder={displayMessage('MODULE_DESCRIPTION')}
                                            className={'w-full text-gray-400'}
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </div>

                                <div className="flex flex-col gap-1">
                                    <div className="">{displayMessage('GRADUATION')}</div>

                                    <Form.Item
                                        name="graduation"
                                        initialValue={detection?.graduation}
                                        rules={[{ required: true, message: displayMessage('DISPLAY_GRADUATION') }]}
                                        className="mb-4"
                                    >
                                        <SL_Input
                                            placeholder={displayMessage('GRADUATION')}
                                            className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>
                                </div>

                                {/* <div className="flex flex-col gap-1 mt-1">
                                    <div className="">
                                        {displayMessage('ACTIVATION_STATUS')}
                                    </div>
                                    <SL_Input
                                        placeholder={isActive ? EActivationStatus.ATIVO.description : EActivationStatus.INATIVO.description}
                                        className={'w-full'}
                                        disabled={true}
                                    />
                                </div>

                                <div className="flex flex-col gap-1 mt-5 mb-5">
                                    <SL_Button
                                        className="md-mx-0 rounder-l-none"
                                        style={{
                                            backgroundColor: !isEditing ? 'gray' : isActive ? styles.colors["sl-red"]["400"] : '#0e730d',
                                            color: 'white'
                                        }}
                                        disabled={!isEditing}
                                        onClick={() => setIsActive(prev => !prev)}
                                    >
                                        {isActive ? 'Desativar' : 'Ativar'}
                                    </SL_Button>
                                </div> */}

                                <Form.Item hasFeedback className="mb-3 mt-2">
                                    <SL_Button disabled={!isEditing} type="primary" className={'w-full font-bold shadow-md'} htmlType="submit">
                                        {displayMessage('EDIT')}
                                    </SL_Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}
