import { DateTime } from 'luxon'
import { displayMessage } from '../../../helpers/messages'

export const frequencies_grid_columns = [
    {
        field: 'description',
        headerName: displayMessage('MODULE_DESCRIPTION'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.description) return row.description
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'graduation',
        headerName: displayMessage('GRADUATION'),
        type: 'integer',
        width: 100,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.graduation != null || row.graduation != null) return row.graduation
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'is_active',
        headerName: displayMessage('ACTIVATION_STATUS'),
        type: 'string',
        width: 150,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            return row.is_active ? displayMessage('ACTIVE') : displayMessage('INACTIVE')
        },
        type: 'singleSelect',
        valueOptions: [
            { value: true, label: 'Ativo' },
            { value: false, label: 'Inativo' },
        ],
    },
    {
        field: 'inactivation_date',
        headerName: displayMessage('INACTIVATION_DATE'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.inactivation_date) return DateTime.fromISO(row.inactivation_date).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'createdAt',
        headerName: displayMessage('CREATED_AT'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.createdAt) return DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
]
