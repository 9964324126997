import React, { useContext, useEffect, useState } from 'react'
import { Paperclip, Question } from '@phosphor-icons/react'
import { Divider, Tooltip, Button, Image } from 'antd'
import { colors } from '../../../../../services/styles'
import './chat.css'
import { EyeOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { SL_Button } from '../../../../../components/buttons/Button'
import { displayMessage } from '../../../../../helpers/messages'
import api from '../../../../../services/api'
import { GeneralContext } from '../../../../../context/GeneralContext/GeneralContext'

const default_attachments_to_show = 3
const max_attachment_characters = 18
export function ChatMessage({ isFollowupFinished, message, messages, setMessages, followupReportRisk }) {
    const { description, id, ClientUser, User, Attachments } = message

    const { showAlert } = useContext(GeneralContext)
    const position = User ? 'end' : 'start'
    const [showAllAttachments, setShowAllAttachments] = useState(false)
    const remainingAttachments = Attachments.length > default_attachments_to_show ? Attachments.length - default_attachments_to_show : 0
    const [attachmentSelected, setAttachmentSelected] = useState(followupReportRisk?.Attachment ?? null)
    const [isSelectingAttachment, setIsSelectingAttachment] = useState(false)
    const [pageLoaded, setPageLoaded] = useState(false)
    const [imageVisible, setImageVisible] = useState(null)
    useEffect(() => {
        if (pageLoaded && isSelectingAttachment) {
            updateFollowupReportRisk()
        } else {
            setAttachmentSelected(followupReportRisk?.Attachment)
        }
        setPageLoaded(true)
    }, [attachmentSelected])

    const updateFollowupReportRisk = async () => {
        try {
            let body = {
                followup_approval_id: id,
            }
            if (attachmentSelected) {
                body = {
                    ...body,
                    attachment_description: description,
                    attachment_id: attachmentSelected.id,
                    is_attachment_selected: true,
                }
            } else {
                body = {
                    ...body,
                    attachment_description: null,
                    attachment_id: null,
                    is_attachment_selected: false,
                }
            }
            await api.followupReportRisk.updateAttachmentRelation(followupReportRisk.id, body)
        } catch (error) {
            console.log(error)
        }
    }

    const handleDownload = async attachment => {
        try {
            let url
            if (attachment.file_type.includes('image')) {
                url = api.attachments.imageDownload(attachment.id)
            } else {
                const response = await api.attachments.openAttachment(attachment.id, { responseType: 'blob' })
                if (response.status !== 200) {
                    throw new Error(`Failed to download. Status code: ${response.status}`)
                }
                const { data } = response
                url = window.URL.createObjectURL(data)
            }

            const link = document.createElement('a')
            link.href = url
            link.download = attachment.name
            document.body.appendChild(link)
            link.click()

            if (!attachment.file_type.includes('image')) {
                window.URL.revokeObjectURL(url)
            }
            document.body.removeChild(link)
        } catch (error) {
            console.error('Error downloading attachment:', error)
        }
    }

    const renderAttachments = () => {
        if (Attachments.length <= default_attachments_to_show || showAllAttachments) {
            return (
                <div className="flex flex-wrap">
                    {Attachments.map((attachment, index) => (
                        <>
                            <div
                                key={index}
                                className={`w-[175px] shadow-md hover:shadow-xl rounded bg-white`}
                                style={{
                                    border: `3px ${attachmentSelected?.id == attachment.id ? 'solid' : 'dotted'} ${
                                        attachmentSelected?.id != attachment.id ? colors.primary[200] : colors.primary[600]
                                    }`,
                                }}
                            >
                                <Tooltip
                                    title={attachmentSelected?.id == attachment?.id && displayMessage('ATTACHMENT_SELECTED_FOR_RISK')}
                                    overlayStyle={{ maxWidth: 600 }}
                                    overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                                    className="uppercase font-bold text-white w-full"
                                >
                                    <div
                                        className="flex items-center justify-center h-[70px]"
                                        onClick={() => !isFollowupFinished && handleOnSelectAttachment(attachment.id)}
                                    >
                                        <Paperclip size={50} weight="thin" color={colors.primary[900]} />
                                    </div>
                                </Tooltip>
                                <Divider className="my-1" />

                                <div className="flex items-center justify-center p-2 text-xs">
                                    <a
                                        className="text-center cursor-pointer"
                                        key={attachment.id}
                                        onClick={() => handleDownload(attachment)}
                                        style={{ display: 'block', marginBottom: '4px' }}
                                    >
                                        <Tooltip
                                            title={attachment.name}
                                            overlayStyle={{ maxWidth: 500 }}
                                            overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                                        >
                                            {attachment.name.length > max_attachment_characters
                                                ? `${attachment.name.slice(0, max_attachment_characters)}...`
                                                : attachment.name}
                                        </Tooltip>
                                    </a>
                                    {attachment.file_type.includes('image') && (
                                        <div className="truncate ml-5" onClick={() => setImageVisible(attachment.id)}>
                                            <EyeOutlined />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {imageVisible && (
                                <Image
                                    style={{
                                        display: 'none',
                                    }}
                                    src={api.attachments.imageDownload(imageVisible)}
                                    preview={{
                                        visible: imageVisible ? true : false,
                                        scaleStep: 20,
                                        src: api.attachments.imageDownload(imageVisible),
                                        onVisibleChange: value => {
                                            setImageVisible(value)
                                        },
                                    }}
                                />
                            )}
                        </>
                    ))}
                    {Attachments.length > default_attachments_to_show && (
                        <div className={`w-[175px] h-[115px] flex justify-center items-center shadow-md hover:shadow-xl rounded bg-white`}>
                            <div className="flex items-center justify-center text-xs">
                                <Button
                                    className="text-2xl"
                                    icon={<MinusOutlined />}
                                    type="link"
                                    onClick={() => setShowAllAttachments(false)}
                                ></Button>
                            </div>
                        </div>
                    )}
                </div>
            )
        } else {
            return (
                <div className="flex flex-wrap">
                    {Attachments.slice(0, default_attachments_to_show).map((attachment, index) => (
                        <>
                            <div
                                key={index}
                                className={`w-[175px] shadow-md hover:shadow-xl rounded bg-white`}
                                style={{
                                    border: `3px ${attachmentSelected?.id == attachment.id ? 'solid' : 'dotted'} ${
                                        attachmentSelected?.id != attachment.id ? colors.primary[200] : colors.primary[600]
                                    }`,
                                }}
                            >
                                <Tooltip
                                    title={attachmentSelected?.id == attachment?.id && displayMessage('ATTACHMENT_SELECTED_FOR_RISK')}
                                    overlayStyle={{ maxWidth: 600 }}
                                    overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                                    className="uppercase font-bold text-white w-full"
                                >
                                    <div
                                        className="flex items-center justify-center h-[70px]"
                                        onClick={() => !isFollowupFinished && handleOnSelectAttachment(attachment.id)}
                                    >
                                        <Paperclip size={50} weight="thin" color={colors.primary[900]} />
                                    </div>
                                </Tooltip>
                                <Divider className="my-1" />
                                <div className="flex items-center justify-center p-2 text-xs">
                                    <span className="truncate">
                                        {attachment.name.length > max_attachment_characters
                                            ? `${attachment.name.slice(0, max_attachment_characters)}...`
                                            : attachment.name}
                                    </span>
                                    <div className="truncate ml-5" onClick={() => setImageVisible(attachment.id)}>
                                        <EyeOutlined />
                                    </div>
                                </div>
                            </div>
                            {imageVisible && (
                                <Image
                                    style={{
                                        display: 'none',
                                    }}
                                    src={api.attachments.imageDownload(imageVisible)}
                                    preview={{
                                        visible: imageVisible ? true : false,
                                        scaleStep: 20,
                                        src: api.attachments.imageDownload(imageVisible),
                                        onVisibleChange: value => {
                                            setImageVisible(value)
                                        },
                                    }}
                                />
                            )}
                        </>
                    ))}
                    <div
                        className={`w-[175px] h-[115px] flex justify-center items-center shadow-md hover:shadow-xl rounded bg-white`}
                        style={{ border: `3px dotted ${colors.primary[200]}` }}
                    >
                        <div className="flex items-center justify-center text-xs">
                            <Button className="text-2xl" icon={<PlusOutlined />} type="link" onClick={() => setShowAllAttachments(true)}>
                                <span className="text-3xl">{remainingAttachments}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const updateAttachmentSelected = is_attachment_selected => {
        const index = messages.findIndex(message => message.id == id)
        if (index != -1) {
            messages[index] = { ...messages[index], is_attachment_selected }
        }
        setMessages([...messages])
    }

    const verifyIfSomeAttachmentIsSelected = attachment_id => {
        const attachment_selected = Attachments.find(attachment => attachment.id == attachment_id)
        let attachment_already_selected = false
        messages.forEach(message => {
            if (message.is_attachment_selected && message.id != id) {
                attachment_already_selected = true
            }
        })

        if (attachment_already_selected) showAlert(displayMessage('ERROR_ATTACHMENT_DIFFERENT_MESSAGE'))

        return { attachment_selected, attachment_already_selected }
    }

    const handleOnSelectAttachment = attachment_id => {
        const { attachment_selected, attachment_already_selected } = verifyIfSomeAttachmentIsSelected(attachment_id)
        setIsSelectingAttachment(true)
        messages.forEach(message => {
            if (!attachment_already_selected && !message.is_attachment_selected && message.id == id) {
                updateAttachmentSelected(true)
                setAttachmentSelected(attachment_selected)
            } else if (!attachment_already_selected && !message.is_attachment_selected && message.id != id && !attachmentSelected) {
                updateAttachmentSelected(true)
                setAttachmentSelected(attachment_selected)
            } else if (message.is_attachment_selected && message.id == id && attachmentSelected && attachmentSelected.id == attachment_id) {
                setAttachmentSelected(null)
                updateAttachmentSelected(false)
            } else if (message.is_attachment_selected && message.id == id && attachmentSelected && attachmentSelected.id != attachment_id) {
                updateAttachmentSelected(true)
                setAttachmentSelected(attachment_selected)
            }
        })
    }

    return (
        <div className={`flex justify-${position} chat-${position}`} id={id}>
            <div className={`${User ? 'chat-message-system' : 'chat-message-recipient'}`}>
                <div
                    className={`${
                        User ? 'text-primary-900' : 'text-sl-gray-600'
                    } mb-2 flex justify-between gap-x-5 font-bold text-xs flex-col md:flex-row`}
                >
                    <span className="truncate">{User?.first_name ? User.first_name : ClientUser?.first_name}</span>
                    {Attachments.length > 0 && description && !isFollowupFinished && (
                        <Tooltip
                            title={displayMessage('SELECT_ATTACHMENT_AND_DESCRIPTION_TO_SAVE')}
                            placement="bottom"
                            overlayStyle={{ maxWidth: 500 }}
                            overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                        >
                            <span>
                                <Question size={20} />
                            </span>
                        </Tooltip>
                    )}
                </div>
                <span>{description}</span>
                <div className="flex flex-wrap justify-center items-center mt-2">{renderAttachments(description)}</div>
            </div>
        </div>
    )
}
